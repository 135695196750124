import React from 'react';
import { useFormik } from 'formik';
import {
  notification,
  Modal,
  Form,
  Select,
  useTable,
} from '@pankod/refine-antd';
import { AccountStore } from 'Actions/Stores/Account';
import { ConfigsStore } from 'Actions/Stores/Configs';
import { apiRequest } from 'Util/api';

interface Props {
  visible: any;
  setVisible: (val: any) => void;
  redirect?: string;
  info?: any;
  existingIngredients?: string[];
  refresh?: () => void;
  modalType?: string;
}

const IngredientToCategory = ({
  visible,
  info,
  setVisible,
  refresh,
}: Props) => {
  const { setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      ingredient_id: '',
      category_id: '',
    },
    onSubmit: async (submittedValues) => {
      const inputs = {
        ingredient_id: +info?.id,
        category_id: +submittedValues.ingredient_id,
      };
      if (Object.keys(inputs).length < 1) return;
      try {
        const data = await apiRequest<boolean>({
          url: '/ingredient-category-ingredient',
          method: 'POST',
          data: inputs,
          headers: {
            device_id: await ConfigsStore.get('device_id'),
            authorization: `Bearer ${
              (
                await AccountStore.get('account')
              )?.access_token
            }`,
          },
        });
        if (data?.data) {
          setVisible('');
          refresh && refresh();
          notification.success({
            message: 'نجاح',
            description: 'تمت الاضافة بنجاح',
          });
        }
      } catch (error: any) {
        setVisible('');
        notification.error({
          message: 'خطأ',
          description: error?.message,
        });
      }
    },
  });

  const categories = useTable({
    resource: 'ingredient-category',
    initialPageSize: 1000000000000,
  });

  const filteredIngredients = categories?.tableProps?.dataSource?.map(
    (item) => {
      return { label: item.name_ar, value: item.id };
    }
  );

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible('')}
      width={500}
      okText={'إرسال'}
      cancelText={'إلغاء'}
      onOk={() => handleSubmit()}
    >
      <Form layout="vertical" style={{ marginTop: 25 }}>
        <Form.Item label="التصنيفنات" rules={[{ required: true }]}>
          <Select
            showSearch
            filterOption={(inputValue, option) =>
              option?.label.toLowerCase().includes(inputValue.toLowerCase())
            }
            options={filteredIngredients || []}
            placeholder="اختر التصنيف"
            onChange={(val) => setFieldValue('ingredient_id', val)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default IngredientToCategory;
