export enum GenderEnum {
  male = 'male',
  female = 'female',
}

export enum OsEnum {
  ios = 0,
  android = 1,
  browser = 2,
}
export enum Language {
  ar = 0,
  en = 1,
}

export const AdminRole = ['مسؤول النظام', 'مشرف'];

export const MealType = ['فطور', 'وجبة خفيفة', 'غداء', 'العشاء'];
export const AccountType = ['مشرف', 'مستخدم', 'أخصائي تغذيه'];
export const AccountStatus = ['فعال', 'غير فعال', 'محذوف'];
export const MembershipType = ['مجاني', 'عادي', 'مميز'];
export const Membership_Duration = ['مجاني', 'شهري', 'سنوي'];
export const Profile_Gender = ['ذكر', ' أنثى'];
export const FoodSource = ['المنزل', 'الخارج'];
export const LifeStyle = ['خفيف جدا', ' خفيف', 'معتدل', 'ثقيل', 'ممتاز'];
export const AllergyType = [
  'المكسرات',
  'الفول السوداني',
  'القمح',
  'ألبان',
  'بيض',
  'أسماك',
  'المحار',
  'جوز',
  'السمسم',
  'الفاكهة',
  'الفواكه المجففة',
  'فول الصويا',
  'الذرة',
  'أخرى',
];

export const WeightEvaluationEnum = [
  ' نقص الوزن',
  ' طبيعي',
  'زيادة الوزن',
  'السمنة_1',
  'السمنة_2',
  'السمنة_3',
];
export const waterIntakenotification = [
  'متوقف',
  'كل ساعة',
  'كل ساعتين',
  'كل اربع ساعات',
];
export const IngredientsType = [
  'النشويات',
  'الكربوهيدرات',
  'الفاكهة',
  'دهون',
  'خضروات',
  'حليب',
  'بروتين متوسط الدسم',
  'بروتين بدون دسم',
  'بروتين قليل الدسم',
  'بروتين عالي الدسم',
  'غيرمعتمد',
  'مكون معقد',
];

// starches
// carbs
// fruits
// fat
// vegetables
// milk
// med_fat_protein
// very_low_fat_protein
// low_fat_protein
// high_fat_protein
// not_assigned
// complex_ingredient

export interface IAccount {
  id: string;
  email?: string;
  password?: string;
  admin?: {
    id: number;
    name: string;
    role: number;
  };
  account_type?: number;
  name?: string;
  access_token?: string;
}

export const PROMO_CODE_TYPE_ENUM = ['كود خصم أبل', 'كود خصم سليم'];

export const PROMO_CODE_DISCOUNT_TYPE_ENUM = ['نسبة', 'قيمة'];
