import React from 'react';
import {
  List,
  Table,
  TextField,
  BooleanField,
  CreateButton,
  ExportButton,
} from '@pankod/refine-antd';
import { useGetIdentity, useNavigation, useTable } from '@pankod/refine-core';
import { FoodSource, MealType } from 'Actions/types';
import { Actions } from 'components/AdminPanel/ActionButtons';
import {
  DeleteRecordAction,
  ExportList,
} from '../../../Actions/ConfigsActions';
import { Search } from 'components/AdminPanel/Search';
import { mealTypes } from './EditMeal';
import _ from 'lodash';

export const MealsListing: React.FC = () => {
  const { data: user } = useGetIdentity();
  const [searchResults, setSearchResults] = React.useState([]);
  const { show, edit } = useNavigation();
  const { tableQueryResult } = useTable({
    syncWithLocation: true,
  });

  const reOrderByDate = _.sortBy(tableQueryResult.data?.data, (item) => {
    return item.created_at;
  }).reverse();

  const handleExportList = () => {
    ExportList(
      searchResults?.length > 0 ? searchResults : reOrderByDate || [],
      'Meals'
    );
  };

  return (
    <List
      title={`${'الوجبات'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="meal"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
              data={reOrderByDate || []}
              typeItems={mealTypes}
            />
            {user?.account_type !== 2 && (
              <>
                <ExportButton
                  onClick={handleExportList}
                  style={{ marginRight: 10 }}
                >
                  {'تصدير'}
                </ExportButton>
                <CreateButton style={{ marginRight: 10 }}>
                  إنشاء وجبة
                </CreateButton>
              </>
            )}
          </div>
        ),
      }}
    >
      <Table
        dataSource={
          searchResults?.length > 0 ? searchResults : reOrderByDate || []
        }
        rowKey="id"
        style={{ cursor: 'pointer' }}
        onRow={(record) => {
          return {
            onClick: () => {
              record.id && show('Meal-cruds', record.id);
            },
          };
        }}
      >
        <Table.Column
          dataIndex="id"
          title={'معرف الوجبة'}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="name_ar"
          title={'الاسم'}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={'meal_type'}
          title={'نوع الوجبة'}
          render={(value) => <TextField value={MealType[+value]} />}
        />
        <Table.Column
          dataIndex={'food_preference'}
          title={'تفضيل الوجبات'}
          render={(value) => <TextField value={FoodSource[+value]} />}
        />
        <Table.Column
          dataIndex="description"
          title={'الوصف'}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={'is_deleted'}
          title={'محذوف'}
          align="center"
          render={(value) => <BooleanField value={value} />}
        />
        <Table.Column<any>
          title={'الإجراءات'}
          dataIndex="actions"
          align="center"
          render={(_text, record): any => {
            return (
              <Actions
                name_ar="الوجبات"
                deleteRecord={user?.account_type !== 2}
                edit
                record={record}
                onClickEdit={() => record?.id && edit('Meal-cruds', record?.id)}
                onClickDelete={() =>
                  DeleteRecordAction(
                    `Meal-cruds/update/by-id/${record?.id}`,
                    tableQueryResult?.refetch,
                    record?.is_deleted
                  )
                }
              />
            );
          }}
        />
      </Table>
    </List>
  );
};
