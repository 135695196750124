import React from 'react';
import {
  BackColorOne,
  BackgroundColorImg,
  SubContainer,
  Divborder,
  InsideSection,
  SectionFirst,
  InsideSectionRepeated,
  Heading,
  Img,
  LandingContainer,
  Paragraph,
  TextParagraph,
  SectionSecond,
  HeadingDownload,
  SectionThird,
  SubHeadingDownload,
  ImgFirst,
  ImgSecond,
  Margin,
  TermsText,
  TermsWrapper,
  Icon,
} from './styles';
import Logo from '../../images/logo.svg';
import Icons from '../../images/instagram.png';
import LogoTow from '../../images/Untitled design (2).svg';
import Phone from '../../images/iphone_12_mockup_10 (1).png';
import PhoneONE from '../../images/Group 32.png';
import PhoneTWO from '../../images/Group 53.png';
import PointsContainer from 'components/AdminPanel/landing/PointsContainer';
import { useNavigation } from '@pankod/refine-core';
import { AiFillFacebook } from 'react-icons/ai';
import { SiLinkedin } from 'react-icons/si';
import styled from 'styled-components';
import mastercard from '../../images/mastercard.png';
import visa from '../../images/visa.png';
function Landing() {
  const { push } = useNavigation();

  return (
    <>
      <TermsWrapper>
        <TermsText onClick={() => push('/privacy-policy')}>
          سياسة الخصوصية
        </TermsText>
        <TermsText onClick={() => push('/terms-and-conditions')}>
          الأحكام والشروط
        </TermsText>
      </TermsWrapper>
      <LandingContainer>
        <Divborder />
        <SubContainer>
          <SectionFirst>
            <InsideSection>
              <Img>
                <img src={Logo} alt="" />
              </Img>
              <Heading>برنامجك الغذائي من أكل البيت</Heading>
            </InsideSection>
            <BackColorOne>
              <InsideSectionRepeated>
                <BackgroundColorImg>
                  <img src={LogoTow} alt="LogoTow" />
                </BackgroundColorImg>
                <Paragraph>
                  <TextParagraph>
                    تطبيق سليم يوصلك للوزن الصحي من خلال نظام غذائي سهل ومرن
                  </TextParagraph>
                </Paragraph>
              </InsideSectionRepeated>
            </BackColorOne>
            <PointsContainer />
          </SectionFirst>
          <SectionSecond>
            <img src={Phone} alt="" />
          </SectionSecond>
          <SectionThird>
            <HeadingDownload>حمل تطبيق</HeadingDownload>
            <SubHeadingDownload>سليم الآن</SubHeadingDownload>
            <Margin>
              <ImgFirst>
                <a
                  href="https://apps.apple.com/jo/app/saleem/id1645049494?utm_source=google&utm_medium=cpc&utm_campaign=GSN-JO-AR-MD"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <img src={PhoneONE} alt="PhoneONE" />
                </a>
              </ImgFirst>
              <ImgSecond>
                <a
                  href="https://play.google.com/store/apps/details?id=dev.wtm.saleemapp&referrer=utm_source%3Dgoogle%26utm_medium%3Dcpc%26utm_campaign%3DGSN-JO-AR-MD%26anid%3Dadmob"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <img src={PhoneTWO} alt="PhoneTWO" />
                </a>
              </ImgSecond>
            </Margin>
          </SectionThird>
        </SubContainer>
        <Icon>
          <Image src={mastercard} alt="mastercard" />
          <Image src={visa} alt="visa" />
        </Icon>
        <Icon>
          <a
            href="https://www.facebook.com/Saleemlifestyle"
            target={'_blank'}
            rel="noreferrer"
          >
            <AiFillFacebook
              style={{
                marginLeft: '5',
                color: 'cornflowerblue',
                borderRadius: '50%',
              }}
              size={34}
            />
          </a>
          <a
            href="https://www.linkedin.com/company/saleemlifestyle/?viewAsMember=true"
            target={'_blank'}
            rel="noreferrer"
          >
            <SiLinkedin
              size={28}
              style={{ color: 'cadetblue', borderRadius: '16%' }}
            />
          </a>
          <a
            href="https://www.instagram.com/saleemlifestyle/"
            target={'_blank'}
            rel="noreferrer"
          >
            <img
              style={{
                maxHeight: '44px',
                paddingBottom: '6px',
                marginRight: '4px',
              }}
              src={Icons}
              alt="instagram"
            />
          </a>
        </Icon>
      </LandingContainer>
    </>
  );
}

export default Landing;

const Image = styled.img<{ background?: string; borderRadius?: string }>`
  width: 50px;
  height: auto;
  object-fit: cover;
  margin-right: 10px;
  ${(props) => props.background && `background-color: ${props.background};`}
  ${(props) => props.borderRadius && `border-radius: ${props.borderRadius}px;`}
`;
