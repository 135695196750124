import { AuthProvider } from '@pankod/refine-core';
import { LoginAction, LogoutAction } from 'Actions/AuthActions';
import { AccountStore } from 'Actions/Stores/Account';
import { ConfigsStore } from 'Actions/Stores/Configs';

const authProvider: AuthProvider = {
  login: async ({ account_type, username, password }) => {
    return LoginAction({
      email: username,
      password: password,
      account_type: account_type,
    });
  },
  logout: async () => {
    return (await ConfigsStore.get('logged_in'))
      ? LogoutAction()
      : Promise.resolve();
  },
  checkError: async (error: any) => {
    if (error?.message.includes('401') || error?.message.includes('403')) {
      LogoutAction(true);
    }
    return error;
  },
  checkAuth: async () =>
    (await ConfigsStore.get('logged_in'))
      ? Promise.resolve()
      : Promise.reject(),
  getPermissions: async () => {
    const roles = await ConfigsStore.get('adminRoles');
    return Promise.resolve(roles);
  },
  getUserIdentity: async () => {
    const currentAccount = await AccountStore.get('account');
    return Promise.resolve({
      ...((currentAccount as object) || {}),
    });
  },
};

export default authProvider;
