import React from 'react';
import { DatePicker, FilterDropdown } from '@pankod/refine-antd';
const { RangePicker } = DatePicker;

interface Props {
  dropdownProps: any;
  placeHolder?: string;
  options?: { label: string; value: string }[];
  mode?: 'multiple' | 'tags';
}
const DropDownFilter = ({ dropdownProps }: Props) => {
  return (
    <FilterDropdown
      {...dropdownProps}
      mapValue={(selectedKeys) => selectedKeys}
    >
      {/* @ts-ignore */}
      <RangePicker
        placeholder={['من تاريخ', 'إلى تاريخ']}
        style={{
          width: '100%',
          direction: 'rtl',
        }}
      />
    </FilterDropdown>
  );
};
export default DropDownFilter;
