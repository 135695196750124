import {
  IResourceComponentsProps,
  useGetIdentity,
  useTable,
} from '@pankod/refine-core';
import React, { useEffect } from 'react';
import {
  useForm,
  Form,
  Input,
  Edit,
  ListButton,
  Typography,
  ShowButton,
  Select,
  notification,
} from '@pankod/refine-antd';
import { useFormik } from 'formik';
import { EditAccountAction } from 'Actions';

import { useParams } from 'react-router-dom';
const { Text } = Typography;

interface Inputs {
  name?: string;
  email?: string;
  capacity?: number | string;
  status?: number | string;
}

export const EditNutritionist: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();
  const { saveButtonProps, queryResult } = useForm<any>();
  const formRecord = queryResult?.data?.data;
  const params = useParams();

  const admin = useTable({
    resource: 'account-cruds',
  });

  const adminRecord = admin?.tableQueryResult?.data?.data?.filter(
    (item) => item.id === params.id
  )[0];

  const record = adminRecord ? { ...adminRecord, ...formRecord } : formRecord;

  const { values, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      name: '',
      email: '',
      capacity: +'',
      status: '',
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {};
      if (submittedValues.name !== record.name) {
        inputs.name = submittedValues.name;
      }
      if (submittedValues.email !== record.email) {
        inputs.email = submittedValues.email;
      }

      if (submittedValues.status !== record.status) {
        inputs.status = submittedValues.status;
      }
      if (submittedValues.capacity !== formRecord?.capacity) {
        EditAccountAction(
          'nutritionist-cruds/update/by-id',
          record.id,
          { capacity: submittedValues.capacity },
          queryResult?.refetch,
          undefined,
          undefined,
          true
        );
        if (Object.keys(inputs)?.length === 0) {
          return notification.success({
            message: 'نجاح',
            description: 'تمت العملية بنجاح',
          });
        }
      }
      if (Object.keys(inputs)?.length > 0) {
        EditAccountAction(
          'account-cruds/update/by-id',
          record.id,
          inputs,
          queryResult?.refetch,
          undefined,
          undefined,
          true
        );
      }

      notification.success({
        message: 'نجاح',
        description: 'تمت العملية بنجاح',
      });
    },
  });

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  };

  useEffect(() => {
    setFieldValue('name', record?.name);
    setFieldValue('email', record?.email);
    setFieldValue('status', record?.status);
    setFieldValue('capacity', record?.capacity);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminRecord, setFieldValue]);

  return (
    <Edit
      saveButtonProps={buttonProps}
      title={'تعديل اخصائي'}
      pageHeaderProps={{
        extra: (
          <>
            <ListButton>
              <Text>{'اخصائي التغذية'}</Text>
            </ListButton>
            <ShowButton>
              <Text>{'التفاصيل'}</Text>
            </ShowButton>
          </>
        ),
      }}
    >
      <Form layout="vertical">
        <Form.Item label="اسم المستخدم" rules={[{ required: true }]} required>
          <Input
            name="name"
            onChange={handleChange}
            value={values.name}
            disabled={user?.account_type === 2}
          />
        </Form.Item>
        <Form.Item
          required
          label="البريد الالكتروني"
          rules={[
            {
              validator: async (_, email) => {
                const regex =
                  // eslint-disable-next-line no-useless-escape
                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                if (!regex.test(email)) {
                  return Promise.reject(
                    new Error('البريد الالكتروني غير صحيح')
                  );
                }
              },
            },
          ]}
        >
          <Input
            name="email"
            onChange={handleChange}
            value={values.email}
            disabled={user?.account_type === 2}
          />
        </Form.Item>
        <Form.Item label=" السعه" rules={[{ required: true }]} required>
          <Input
            name="capacity"
            onChange={handleChange}
            value={values?.capacity}
            type={'number'}
            disabled={user?.account_type === 2}
          />
        </Form.Item>
        <Form.Item label="الحالة">
          <Select
            options={[
              { label: 'فعال', value: 0 },
              { label: 'غير فعال', value: 1 },
              { label: 'محذوف', value: 2 },
            ]}
            value={values?.status}
            onChange={(val) => setFieldValue('status', val)}
            disabled={user?.account_type === 2}
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
