import React from 'react';
import { useFormik } from 'formik';
import { notification, Modal, Input, Form } from '@pankod/refine-antd';
import { AccountStore } from 'Actions/Stores/Account';
import { ConfigsStore } from 'Actions/Stores/Configs';
import { apiRequest } from 'Util/api';

interface Props {
  visible: any;
  setVisible: (val: any) => void;
  redirect?: string;
  info?: any;
  refresh?: (val: boolean) => void;
  modalType?: string;
}

interface Inputs {
  old_password?: string;
  password?: string;
}

const ChangePasswordModal = ({ visible, setVisible, modalType }: Props) => {
  const { handleChange, handleSubmit } = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {
        password: submittedValues.password,
      };
      if (submittedValues.oldPassword) {
        inputs.old_password = submittedValues.oldPassword;
      }

      const url = '/admin/update-password';

      try {
        const data = await apiRequest<boolean>({
          url,
          method: 'POST',
          data: inputs,
          headers: {
            device_id: await ConfigsStore.get('device_id'),
            authorization: `Bearer ${
              (
                await AccountStore.get('account')
              )?.access_token
            }`,
          },
        });

        if (data?.data) {
          setVisible('');
          notification.success({
            message: '',
            description: 'تم تغيير كلمة السر بنجاح',
          });
        }
      } catch (error) {
        setVisible('');
        notification.error({
          message: 'خطأ',
          // @ts-ignore
          description: error?.message,
        });
      }
    },
  });

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible('')}
      width={500}
      okText={'إرسال'}
      cancelText={'إلغاء'}
      onOk={() => handleSubmit()}
    >
      <Form layout="vertical" style={{ marginTop: 25 }}>
        {!modalType && (
          <Form.Item
            label={'كلمة السر القديمة'}
            rules={[
              {
                validator: async (_, oldPassword) => {
                  if (oldPassword.length < 8) {
                    return Promise.reject(
                      new Error('كلمة السر يجب أن تكون أكثر من 8 أحرف')
                    );
                  }
                },
              },
            ]}
          >
            <Input
              type="password"
              name={'oldPassword'}
              onChange={handleChange}
              placeholder={'كلمة السر القديمة'}
            />
          </Form.Item>
        )}

        <Form.Item
          label={'كلمة السر الجديدة'}
          name="password"
          rules={[
            {
              validator: async (_, password) => {
                if (password.length < 8) {
                  return Promise.reject(
                    new Error('كلمة السر يجب أن تكون أكثر من 8 أحرف')
                  );
                }
              },
            },
          ]}
        >
          <Input
            type={'password'}
            name={'password'}
            onChange={handleChange}
            placeholder={'كلمة السر الجديدة'}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
