import * as React from 'react';
import { useForm, Create, Form, Input, Select } from '@pankod/refine-antd';
import { AllergyType } from 'Actions/types';
import { errorMessage } from 'Util/ErrorMessage';
import { useFormik } from 'formik';
import { CreateAction } from 'Actions';
import * as Yup from 'yup';

import { useNavigation } from '@pankod/refine-core';

export const CreateAllergy = () => {
  const navigate = useNavigation();
  const validationSchema = Yup.object().shape({
    name_ar: Yup.string().required('مطلوب'),
    type: Yup.number().required('مطلوب'),
  });

  const { formProps, saveButtonProps, queryResult } = useForm();
  const allergyType = AllergyType?.map((item, key) => ({
    label: item,
    value: key,
  }));
  const { errors, values, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        name_ar: '',
        type: '',
      },
      validationSchema,

      onSubmit: async (submittedValues) => {
        const inputs = {
          name_ar: submittedValues.name_ar,
          name_en: submittedValues.name_ar,
          type: submittedValues.type,
        };

        if (Object.keys(inputs)?.length > 0) {
          CreateAction('Allergy-cruds', inputs, undefined, navigate.goBack);
        }
      },
    });

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  };

  return (
    <Create
      isLoading={queryResult?.isFetching}
      saveButtonProps={buttonProps}
      title={'إنشاء حساسية'}
    >
      <Form {...formProps} layout="vertical" initialValues={{ isActive: true }}>
        <Form {...formProps} layout="vertical">
          <Form.Item
            label="اسم الحساسية"
            name="name_ar"
            rules={errorMessage()}
            required
          >
            <Input
              name="name_ar"
              onChange={handleChange}
              value={values.name_ar}
            />
          </Form.Item>
          <Form.Item
            label="نوع الحساسية"
            name={'type'}
            required
            rules={[
              {
                validator: async (_, type) => {
                  if (errors.type) {
                    return Promise.reject(
                      new Error(' يرجى ادخال نوع الحساسية')
                    );
                  }
                },
              },
            ]}
          >
            <Select
              options={allergyType}
              onChange={(value) => setFieldValue('type', value)}
              value={values.type}
            />
          </Form.Item>
        </Form>
      </Form>
    </Create>
  );
};
