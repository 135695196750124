import styled from 'styled-components';
export const LandingContainer = styled.div`
  margin-top: -45px;
  background: rgb(251, 250, 250);
  background: -moz-linear-gradient(
    0deg,
    rgba(251, 250, 250, 1) 52%,
    rgba(136, 206, 191, 1) 50%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(251, 250, 250, 1) 52%,
    rgba(136, 206, 191, 1) 50%
  );
  background: linear-gradient(
    0deg,
    rgba(251, 250, 250, 1) 52%,
    rgba(136, 206, 191, 1) 50%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbfafa",endColorstr="#88cebf",GradientType=1);
  @media (min-width: 574px) and (max-width: 600px) {
    background: rgb(251, 250, 250);
    background: -moz-linear-gradient(
      0deg,
      rgba(251, 250, 250, 1) 50%,
      rgba(136, 206, 191, 1) 50%
    );
    background: -webkit-linear-gradient(
      0deg,
      rgba(251, 250, 250, 1) 50%,
      rgba(136, 206, 191, 1) 50%
    );
    background: linear-gradient(
      0deg,
      rgba(251, 250, 250, 1) 50%,
      rgba(136, 206, 191, 1) 50%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbfafa",endColorstr="#88cebf",GradientType=1);
  }
  @media (min-width: 402px) and (max-width: 573px) {
    background: rgb(251, 250, 250);
    background: -moz-linear-gradient(
      0deg,
      rgba(251, 250, 250, 1) 45%,
      rgba(136, 206, 191, 1) 44%
    );
    background: -webkit-linear-gradient(
      0deg,
      rgba(251, 250, 250, 1) 45%,
      rgba(136, 206, 191, 1) 44%
    );
    background: linear-gradient(
      0deg,
      rgba(251, 250, 250, 1) 45%,
      rgba(136, 206, 191, 1) 44%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbfafa",endColorstr="#88cebf",GradientType=1);
  }
  @media (max-width: 400px) {
    background: rgb(251, 250, 250);
    background: -moz-linear-gradient(
      0deg,
      rgba(251, 250, 250, 1) 43%,
      rgba(136, 206, 191, 1) 43%
    );
    background: -webkit-linear-gradient(
      0deg,
      rgba(251, 250, 250, 1) 43%,
      rgba(136, 206, 191, 1) 43%
    );
    background: linear-gradient(
      0deg,
      rgba(251, 250, 250, 1) 43%,
      rgba(136, 206, 191, 1) 43%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbfafa",endColorstr="#88cebf",GradientType=1);
  }
  direction: rtl;
  @media (min-width: 1166px) {
    background: rgb(251, 250, 250);
    background: linear-gradient(
      90deg,
      rgba(251, 250, 250, 1) 50%,
      rgba(136, 206, 191, 1) 50%
    );
  }
`;
export const Divborder = styled.div`
  display: none;
  @media (min-width: 1440px) and (max-width: 1499px) {
    border: 4px solid #ffffff1c;
    position: absolute;
    left: 83%;
    width: 32%;
    height: 44%;
    border-radius: 49%;
    bottom: 70%;
    display: block;
  }
  @media (min-width: 1500px) {
    border: 4px solid #ffffff1c;
    position: absolute;
    left: 83%;
    width: 30%;
    height: 50%;
    border-radius: 49%;
    bottom: 64%;
    display: block;
  }
`;
export const SubContainer = styled.div`
  padding: 70px 62px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1165.8px) {
    padding: 70px 62px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
export const SectionFirst = styled.div``;
export const InsideSection = styled.div`
  @media (max-width: 1165px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const Img = styled.div`
  padding: 10px 40px;
  background: #ffffff;
  width: fit-content;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Heading = styled.h1`
  @media (max-width: 372px) {
    text-align: center;
  }
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  margin-top: 19px;
  margin-bottom: 38px;
  font-family: 'Extra Bold';
  @media (min-width: 1001px) {
    color: #ffffff;
    font-size: 30px;
    line-height: 56px;
    font-weight: bold;
    margin-top: 19px;
    margin-bottom: 38px;
    font-family: 'Extra Bold';
  }
`;
export const BackColorOne = styled.div`
  background-color: #ffffff38;
  border-radius: 20px;
  margin-bottom: 91px;
  position: relative;
  @media (min-width: 1001px) {
    background-color: #ffffff38;
    border-radius: 20px;
    padding: 23px 30px;
    width: 527px;
    height: 115px;
    margin-bottom: 91px;
    position: relative;
  }
`;
export const InsideSectionRepeated = styled.div`
  display: flex;
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    text-align: center;
  }
`;
export const BackgroundColorImg = styled.div`
  @media (max-width: 1000px) {
    width: 62px;
    height: 61px;
  }
  background-color: #a6ddd2;
  width: 111px;
  height: 67px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 10px 6px #00000005;
`;
export const Paragraph = styled.div`
  @media (min-width: 1001px) {
    padding-right: 40px;
  }
`;
export const TextParagraph = styled.p`
  color: #eaf6f4;
  font-family: 'Medium';
  line-height: 35px;
  @media (min-width: 1001px) {
    font-size: 20px;
    color: #eaf6f4;
    font-family: 'Medium';
    line-height: 35px;
  }
`;
export const SectionSecond = styled.div`
  margin-top: 151px;
  @media (max-width: 600px) {
    margin-top: 50px;
  }
  @media (min-width: 1166.92px) and (max-width: 1439px) {
    position: absolute;
    left: 24%;
    top: 17%;
  }
  @media (min-width: 1446.08px) and (max-width: 1446.09px) {
    position: absolute;
    left: 23%;
    top: 15%;
  }
  @media (min-width: 1440px) and (max-width: 1445px) {
    position: absolute;
    left: 23%;
    top: 15%;
  }
  @media (min-width: 1512px) {
    position: absolute;
    left: 26%;
    top: 14%;
  }
  img {
    @media (min-width: 1167px) and (max-width: 1400px) {
      width: 300px;
    }
    @media (max-width: 1439px) {
      width: 380px;
    }
    @media (max-width: 573px) {
      width: 300px;
    }
  }
  @media (max-width: 1152.4px) {
    order: 3;
  }
`;
export const SectionThird = styled.div`
  @media (max-width: 1152.4px) {
    padding-top: 87px;
  }
  padding-top: 181px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1166.92px) and (max-width: 1446.8px) {
    margin-left: -3%;
  }
  @media (max-width: 1152.4px) {
    order: 2;
  }
`;
export const HeadingDownload = styled.h2`
  font-size: 30px;
  color: #07475a;
  font-family: 'Semi BOLD';
  line-height: 56px;
  text-align: right;
  @media (min-width: 1440px) {
    color: #07475a;
    font-family: 'Semi BOLD';
    line-height: 56px;
    font-size: 60px;
    text-align: right;
  }
`;
export const SubHeadingDownload = styled.h2`
  font-size: 30px;
  color: #ff9800;
  font-family: 'Semi BOLD';
  line-height: 56px;
  text-align: right;
  @media (min-width: 1440px) {
    color: #ff9800;
    font-family: 'Semi BOLD';
    line-height: 56px;
    font-size: 60px;
    text-align: right;
  }
`;
export const Margin = styled.div``;
export const ImgFirst = styled.div`
  width: 246px;
  height: 69px;
  padding-top: 30px;
  padding-bottom: 97px;
`;
export const ImgSecond = styled.div`
  width: 246px;
  height: 69px;
`;

export const TermsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 0px 0px 0px;
`;

export const TermsText = styled.h2`
  font-size: 12px;
  color: #07475a;
  font-family: 'Semi BOLD';
  cursor: pointer;
  line-height: 22px;
  z-index: 111;
  border-bottom: 1px solid #07475a;
  margin: 0px 15px 0px 0px;
  :hover {
    opacity: 0.6;
  }
`;
export const Icon = styled.div`
  display: flex;
  justify-content: end;
  padding-bottom: 11px;
  align-items: center;
  padding-left: 58px;
  & > a :hover {
    transition: 0.5s;
    opacity: 0.8;
  }
  @media (max-width: 1165px) {
    justify-content: center;
    padding-left: 0px;
  }
`;
