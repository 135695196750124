import { IResourceComponentsProps } from '@pankod/refine-core';
import React from 'react';
import {
  useForm,
  Form,
  Input,
  Edit,
  ListButton,
  Typography,
  ShowButton,
} from '@pankod/refine-antd';
import { EditAccountAction } from '../../../Actions/ConfigsActions';
import { useFormik } from 'formik';
const { Text } = Typography;

interface Inputs {
  feedback?: string;
  is_deleted?: boolean | string;
}

export const EditFeedback: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<any>();
  const record = queryResult?.data?.data;

  const { handleChange, handleSubmit } = useFormik({
    initialValues: {
      feedback: '',
      is_deleted: '',
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {};
      if (
        submittedValues.feedback &&
        submittedValues.feedback !== record.feedback
      ) {
        inputs.feedback = submittedValues.feedback;
      }
      if (
        submittedValues.is_deleted &&
        submittedValues.is_deleted !== record.is_deleted
      ) {
        inputs.is_deleted = submittedValues.is_deleted;
      }
      if (Object.keys(inputs)?.length > 0) {
        EditAccountAction(
          'feedback-cruds/update/by-id',
          record.id,
          inputs,
          queryResult?.refetch
        );
      }
    },
  });
  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  };

  return (
    <Edit
      saveButtonProps={buttonProps}
      title={'تعديل  التغذية راجعة'}
      pageHeaderProps={{
        extra: (
          <>
            <ListButton>
              <Text>{'التغذية راجعة'}</Text>
            </ListButton>
            <ShowButton>
              <Text>{'التفاصيل'}</Text>
            </ShowButton>
          </>
        ),
      }}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label=" التغدية راجعة"
          name="feedback"
          rules={[{ required: true }]}
          required
        >
          <Input name="feedback" onChange={handleChange} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
