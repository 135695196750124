import { IResourceComponentsProps, useTable } from '@pankod/refine-core';
import React, { useEffect } from 'react';
import {
  useForm,
  Form,
  Input,
  Edit,
  ListButton,
  Typography,
  ShowButton,
  Select,
  notification,
} from '@pankod/refine-antd';
import { useFormik } from 'formik';
import { EditAccountAction } from 'Actions';
import { AdminRole } from 'Actions/types';
import { useParams } from 'react-router-dom';
const { Text } = Typography;

interface Inputs {
  name?: string;
  email?: string;
  is_email_confirmed?: boolean | string;
  role?: boolean | string;
  status?: number | string;
}

export const EditAdmin: React.FC<IResourceComponentsProps> = () => {
  const { saveButtonProps, queryResult } = useForm<any>();
  const formRecord = queryResult?.data?.data;
  const params = useParams();

  const admin = useTable({
    resource: 'account-cruds',
  });

  const adminRecord = admin?.tableQueryResult?.data?.data?.filter(
    (item) => item.id === params.id
  )[0];

  const record = adminRecord ? { ...adminRecord, ...formRecord } : formRecord;

  const { values, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      name: '',
      email: '',
      role: '',
      status: '',
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {};
      if (submittedValues.name !== record.name) {
        inputs.name = submittedValues.name;
      }

      if (submittedValues.email !== record.email) {
        inputs.email = submittedValues.email;
      }
      if (submittedValues.status !== record.status) {
        inputs.status = submittedValues.status;
      }
      if (submittedValues.role !== formRecord?.role) {
        EditAccountAction(
          'admin-cruds/update/by-id',
          record.id,
          { role: submittedValues.role },
          queryResult?.refetch,
          undefined,
          undefined,
          true
        );
        if (Object.keys(inputs)?.length === 0) {
          notification.success({
            message: '',
            description: 'تمت العملية بنجاح',
          });
        }
      }

      if (Object.keys(inputs)?.length > 0) {
        EditAccountAction(
          'account-cruds/update/by-id',
          record.id,
          inputs,
          queryResult?.refetch,
          undefined,
          undefined,
          true
        );
        notification.success({
          message: '',
          description: 'تمت العملية بنجاح',
        });
      }
    },
  });

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  };

  useEffect(() => {
    setFieldValue('name', record?.name);
    setFieldValue('email', record?.email);
    setFieldValue('role', record?.role);
    setFieldValue('status', record?.status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminRecord, setFieldValue]);

  return (
    <Edit
      saveButtonProps={buttonProps}
      title={'تعديل المشرف'}
      pageHeaderProps={{
        extra: (
          <>
            <ListButton>
              <Text>{'المشرفين'}</Text>
            </ListButton>
            <ShowButton>
              <Text>{'التفاصيل'}</Text>
            </ShowButton>
          </>
        ),
      }}
    >
      <Form layout="vertical">
        <Form.Item label="اسم المستخدم" rules={[{ required: true }]}>
          <Input name="name" onChange={handleChange} value={values.name} />
        </Form.Item>
        <Form.Item
          label="البريد الالكتروني"
          rules={[
            {
              validator: async (_, email) => {
                const regex =
                  // eslint-disable-next-line no-useless-escape
                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                if (!regex.test(email)) {
                  return Promise.reject(
                    new Error('البريد الالكتروني غير صحيح')
                  );
                }
              },
            },
          ]}
        >
          <Input name="email" onChange={handleChange} value={values.email} />
        </Form.Item>
        <Form.Item label={'الدور'}>
          <Select
            placeholder={'اختر الدور...'}
            options={[
              { label: AdminRole[0], value: 0 },
              { label: AdminRole[1], value: 1 },
              // { label: ADMIN_ENUM[2], value: ADMINS_ROLES.data_entry },
            ]}
            onChange={(value) => setFieldValue('role', value)}
            value={values?.role}
          />
        </Form.Item>
        <Form.Item label="الحالة">
          <Select
            options={[
              { label: 'فعال', value: 0 },
              { label: 'غير فعال', value: 1 },
              { label: 'محذوف', value: 2 },
            ]}
            value={values?.status}
            onChange={(val) => setFieldValue('status', val)}
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
