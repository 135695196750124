import axios, { AxiosInstance } from 'axios';
import {
  QueryFilter,
  QueryFilterArr,
  // QuerySort,
  // QuerySortArr,
  // QuerySortOperator,
  CondOperator,
  ComparisonOperator,
} from '@nestjsx/crud-request';
import {
  DataProvider,
  HttpError,
  CrudFilters as RefineCrudFilter,
  CrudOperators,
  // CrudSorting,
} from '@pankod/refine-core';
import { stringify } from 'query-string';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
// type SortBy = QuerySort | QuerySortArr | Array<QuerySort | QuerySortArr>;
type CrudFilters =
  | QueryFilter
  | QueryFilterArr
  | Array<QueryFilter | QueryFilterArr>;

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  }
);

const mapOperator = (operator: CrudOperators): ComparisonOperator => {
  switch (operator) {
    case 'ne':
      return CondOperator.NOT_EQUALS;
    case 'lt':
      return CondOperator.LOWER_THAN;
    case 'gt':
      return CondOperator.GREATER_THAN;
    case 'lte':
      return CondOperator.LOWER_THAN_EQUALS;
    case 'gte':
      return CondOperator.GREATER_THAN_EQUALS;
    case 'in':
      return CondOperator.IN;
    case 'nin':
      return CondOperator.NOT_IN;
    case 'contains':
      return CondOperator.CONTAINS_LOW;
    case 'ncontains':
      return CondOperator.EXCLUDES_LOW;
    case 'containss':
      return CondOperator.CONTAINS;
    case 'ncontainss':
      return CondOperator.EXCLUDES;
    case 'null':
      return CondOperator.IS_NULL;
  }

  return CondOperator.EQUALS;
};

// const generateSort = (sort?: CrudSorting): SortBy | undefined => {
//   if (sort && sort.length > 0) {
//     const multipleSort: SortBy = [];
//     // eslint-disable-next-line array-callback-return
//     sort.map(({ field, order }) => {
//       if (field && order) {
//         multipleSort.push({
//           field: field,
//           order: order.toUpperCase() as QuerySortOperator,
//         });
//       }
//     });
//     return multipleSort;
//   }

//   return;
// };

const generateFilter = (filters?: RefineCrudFilter): CrudFilters => {
  const crudFilters: CrudFilters = [];
  if (filters) {
    // @ts-ignore
    // eslint-disable-next-line array-callback-return
    filters.map(({ field, operator, value }) => {
      crudFilters.push({
        field,
        operator: mapOperator(operator),
        value,
      });
    });
  }

  return crudFilters;
};

export const dataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance
): Omit<
  Required<DataProvider>,
  'createMany' | 'updateMany' | 'deleteMany'
> => ({
  getList: async ({
    resource,
    hasPagination = true,
    pagination = { current: 1, pageSize: 10 },
    filters,
    sort,
  }) => {
    const url = `${apiUrl}/${resource}`;

    const { current = 1, pageSize = 10 } = pagination ?? {};

    const queryFilters = generateFilter(filters);

    const query: {
      _start?: number;
      _end?: number;
      _sort?: string;
      _order?: string;
    } = hasPagination
      ? {
          _start: (current - 1) * pageSize,
          _end: current * pageSize,
        }
      : {};

    // const generatedSort = generateSort(sort);
    // if (generatedSort) {
    //   const { _sort, _order } = generatedSort;
    //   query._sort = _sort.join(',');
    //   query._order = _order.join(',');
    // }
    // @ts-ignore
    const customFilters = (queryFilters || [])?.map((filter: any) => {
      if (
        filter.field === 'created_at' &&
        filter.value?.length > 0 &&
        dayjs(filter.value[0]).format() !== 'Invalid Date' &&
        dayjs(filter.value[1]).format() !== 'Invalid Date'
      ) {
        return `filter=created_at||$gte||${dayjs(filter.value[0])
          .startOf('day')
          .utc()
          .format(
            'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
          )}&filter=created_at||$lt||${dayjs(filter.value[1])
          .endOf('day')
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`;
      }
      return [];
    });

    const { data, headers } = await httpClient.get(
      `${url}?${stringify(query)}&${
        customFilters.length > 0 ? customFilters[0] : stringify(queryFilters)
      }`
    );

    const total = +headers['x-total-count'];

    return {
      data,
      total,
    };
  },

  getMany: async ({ resource, ids }) => {
    const { data } = await httpClient.get(
      `${apiUrl}/${resource}?${stringify({ id: ids })}`
    );

    return {
      data,
    };
  },

  create: async ({ resource, variables }) => {
    const url = `${apiUrl}/${resource}`;

    const { data } = await httpClient.post(url, variables);

    return {
      data,
    };
  },

  update: async ({ resource, id, variables }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.patch(url, variables);

    return {
      data,
    };
  },

  getOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.get(url);

    return {
      data,
    };
  },

  deleteOne: async ({ resource, id, variables }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.delete(url, {
      data: variables,
    });

    return {
      data,
    };
  },

  getApiUrl: () => {
    return apiUrl;
  },

  custom: async ({ url, method, filters, sort, payload, query, headers }) => {
    let requestUrl = `${url}?`;

    // if (sort) {
    //   const generatedSort = generateSort(sort);
    //   if (generatedSort) {
    //     const { _sort, _order } = generatedSort;
    //     const sortQuery = {
    //       _sort: _sort.join(','),
    //       _order: _order.join(','),
    //     };
    //     requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
    //   }
    // }

    if (filters) {
      const filterQuery = generateFilter(filters);
      requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    if (headers) {
      httpClient.defaults.headers = {
        ...httpClient.defaults.headers,
        ...headers,
      };
    }

    let axiosResponse;
    switch (method) {
      case 'put':
      case 'post':
      case 'patch':
        axiosResponse = await httpClient[method](url, payload);
        break;
      case 'delete':
        axiosResponse = await httpClient.delete(url, {
          data: payload,
        });
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl);
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});
