import React, { useEffect, useState } from 'react';
import '../ForgotPassword//styles.css';
import logo from '../../../../images/logo.svg';
import {
  Typography,
  AntdLayout,
  Row,
  Col,
  Card,
  Input,
  Button,
  Form,
} from '@pankod/refine-antd';
import { ConfigsStore } from 'Actions/Stores/Configs';
import { colors } from 'Theme/Colors';
import { VerificationAction } from 'Actions';
import styled from 'styled-components';

const { Title } = Typography;

export const Verification: React.FC = () => {
  const [email, setEmail] = useState<string | undefined>();

  const getEmail = async () => {
    const email = await ConfigsStore.get('resetPasswordEmail');
    setEmail(email);
  };
  useEffect(() => {
    getEmail();
  }, []);

  const navigateToResetPassword = () => {
    window.location.replace('/reset-password');
  };

  const CardTitle = (
    <Title level={3} className="layout-title">
      {'رمز تأكيد الإستعادة'}
    </Title>
  );
  return (
    <AntdLayout className="antdLayout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div
            style={{
              maxWidth: '408px',
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Image src={logo} width="80" alt="logo" />

            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form
                layout="vertical"
                color={colors.primary}
                requiredMark={false}
                onFinish={(val: any) => {
                  VerificationAction(val?.code, email, navigateToResetPassword);
                }}
              >
                <Form.Item
                  name="code"
                  label={'رمز التأكيد'}
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="●●●●●" />
                </Form.Item>

                <Button
                  type="default"
                  size="large"
                  htmlType="submit"
                  block
                  color={colors.primary}
                  style={{ background: colors.primary }}
                >
                  <p
                    style={{
                      color: '#fff',
                      fontSize: 17,
                      borderColor: colors.primary,
                    }}
                  >
                    {'إرسال'}
                  </p>
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};

export const Image = styled.img`
  margin-bottom: 26px;
  align-self: center;
`;
