import { IResourceComponentsProps } from '@pankod/refine-core';
import React from 'react';
import {
  useForm,
  Form,
  Input,
  Edit,
  Select,
  ListButton,
  Typography,
  ShowButton,
} from '@pankod/refine-antd';
import { IngredientsType } from 'Actions/types';
import { useFormik } from 'formik';
import { EditAccountAction } from '../../../Actions/ConfigsActions';
const { Text } = Typography;

interface Inputs {
  name_ar?: string;
  name_en?: string;
  type?: number | string;
  protein?: string;
  carbohydrates?: string;
  calories?: string;
  fat?: string;
  is_deleted?: boolean | string;
}

export const ingredientsType = IngredientsType?.map((item, key) => ({
  label: item,
  value: key,
}));
export const EditIngredients: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<any>();
  const record = queryResult?.data?.data;

  const { handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      name_ar: '',
      name_en: '',
      type: '',
      protein: '',
      carbohydrates: '',
      calories: '',
      fat: '',
      is_deleted: '',
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {};

      if (
        submittedValues.name_ar &&
        submittedValues.name_ar !== record.name_ar
      ) {
        inputs.name_ar = submittedValues.name_ar;
        inputs.name_en = submittedValues.name_ar;
      }
      if (submittedValues.type && submittedValues.type !== record.type) {
        inputs.type = submittedValues.type;
      }

      if (
        submittedValues.carbohydrates &&
        submittedValues.carbohydrates !== record.carbohydrates
      ) {
        inputs.carbohydrates = submittedValues.carbohydrates;
      }
      if (
        submittedValues.calories &&
        submittedValues.calories !== record.calories
      ) {
        inputs.calories = submittedValues.calories;
      }
      if (submittedValues.fat && submittedValues.fat !== record.fat) {
        inputs.fat = submittedValues.fat;
      }
      if (
        submittedValues.protein &&
        submittedValues.protein !== record.protein
      ) {
        inputs.protein = submittedValues.protein;
      }

      if (submittedValues.type && submittedValues.type !== record.type) {
        inputs.type = submittedValues.type;
      }

      if (Object.keys(inputs)?.length > 0) {
        EditAccountAction(
          'ingredients-cruds/update/by-id',
          record.id,
          inputs,
          queryResult?.refetch
        );
      }
    },
  });
  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  };

  return (
    <Edit
      saveButtonProps={buttonProps}
      title={'تعديل المكون'}
      pageHeaderProps={{
        extra: (
          <>
            <ListButton>
              <Text>{'المكونات'}</Text>
            </ListButton>
            <ShowButton>
              <Text>{'التفاصيل'}</Text>
            </ShowButton>
          </>
        ),
      }}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="اسم المكون"
          name="name_ar"
          rules={[{ required: true }]}
        >
          <Input name="name_ar" onChange={handleChange} />
        </Form.Item>
        <Form.Item
          label="نوع المكون"
          name={'type'}
          rules={[{ required: true }]}
        >
          <Select
            defaultValue={record?.type}
            options={ingredientsType}
            onChange={(val) => setFieldValue('type', val)}
          />
        </Form.Item>
        <Form.Item label=" بروتين" name="protein" rules={[{ required: true }]}>
          <Input name="protein" onChange={handleChange} />
        </Form.Item>
        <Form.Item
          label=" الكربوهيدرات"
          name="carbohydrates"
          rules={[{ required: true }]}
          required
        >
          <Input name="carbohydrates" onChange={handleChange} />
        </Form.Item>
        <Form.Item
          label="سعرات حراريه"
          name="calories"
          rules={[{ required: true }]}
          required
        >
          <Input name="calories" onChange={handleChange} />
        </Form.Item>
        <Form.Item
          label=" دهون"
          name="fat"
          rules={[{ required: true }]}
          required
        >
          <Input name="fat" onChange={handleChange} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
