import React from 'react';
import {
  List,
  Table,
  TextField,
  BooleanField,
  useTable,
} from '@pankod/refine-antd';
import { useNavigation } from '@pankod/refine-core';
import { Actions } from 'components/AdminPanel/ActionButtons';

export const SystemConfigsListing: React.FC = () => {
  const { edit } = useNavigation();

  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  return (
    <List title={`${'الإعدادات العامة'}`}>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="basic_monthly_price"
          title={'سعر الاشتراك العادي الشهري'}
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex="premium_monthly_price"
          title={'سعر الاشتراك المميز الشهري'}
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex="basic_yearly_price"
          title={'سعر الاشتراك العادي السنوي قبل الخصم'}
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex="basic_yearly_discount"
          title={'قيمة خصم الاشتراك العادي السنوي'}
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex="premium_yearly_price"
          title={'سعر الاشتراك المميز السنوي قبل الخصم'}
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex="premium_yearly_discount"
          title={'قيمة خصم الاشتراك المميز السنوي'}
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex={'free_trial_period'}
          title={'فترة التجربة المجانية'}
          align="center"
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex={'activate_meal_location_filtering'}
          title={'تفعيل تصفية موقع الوجبة'}
          align="center"
          render={(value) => <BooleanField value={value} />}
        />
        <Table.Column<any>
          title={'الإجراءات'}
          dataIndex="actions"
          align="center"
          render={(_text, record): any => {
            return (
              <Actions
                name_ar="الإعدادات العامة"
                edit
                record={record}
                onClickEdit={() =>
                  record?.id && edit('system-config-cruds', record?.id)
                }
              />
            );
          }}
        />
      </Table>
    </List>
  );
};
