import {
  AntdLayout,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from '@pankod/refine-antd';
import { useLogin } from '@pankod/refine-core';
import { ConfigsStore } from 'Actions/Stores/Configs';
import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from 'Theme/Colors';
import logo from '../../../images/logo.svg';
import './style.css';
const { Title } = Typography;

export interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
}

const Login = () => {
  const [form] = Form.useForm<ILoginForm>();
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const { mutate: login } = useLogin<ILoginForm>();

  const CardTitle = (
    <Title level={3} className="layout-title">
      {'تسجيل الدخول إلى حسابك'}
    </Title>
  );

  return (
    <AntdLayout className="antdLayout">
      <Row justify="center" align="middle" style={{ height: '100vh' }}>
        <Col xs={22}>
          <Container>
            <Image src={logo} width="80" alt="logo" />
            <Card
              title={CardTitle}
              headStyle={{ borderBottom: 0 }}
              style={{
                boxShadow: 'rgb(193 193 193) 0px 0px 1px 1px',
              }}
            >
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                requiredMark
                onFinish={(values) => {
                  login(values);
                }}
              >
                <Form.Item
                  name="account_type"
                  label={'نوع الحساب'}
                  rules={[{ required: true }]}
                  style={{ marginBottom: 10 }}
                >
                  <Select
                    options={[
                      { label: 'مشرف', value: 0 },
                      { label: 'اخصائي التغذية', value: 2 },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  name="username"
                  label={'البريد الإلكتروني'}
                  rules={[{ required: true }]}
                  style={{ marginBottom: 10 }}
                >
                  <Input size="large" placeholder=" sample@sample.com" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={'كلمة السر'}
                  rules={[{ required: true }]}
                  style={{ marginBottom: '12px' }}
                >
                  <Input type="password" placeholder="●●●●●●●●" size="large" />
                </Form.Item>
                <RememberContainer>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox
                      style={{ fontSize: '12px' }}
                      onClick={() => setRememberMe(!rememberMe)}
                    >
                      {'تذكرني'}
                    </Checkbox>
                  </Form.Item>
                  <a style={{ fontSize: '12px' }} href="/forgot-password">
                    {'هل نسيت كلمة السر؟'}
                  </a>
                </RememberContainer>
                <Button
                  type="default"
                  size="large"
                  htmlType="submit"
                  block
                  color={'#6CCEF4'}
                  // style={{ background: Colors.gradientFromLeft }}
                  onClick={() => {
                    if (rememberMe) {
                      ConfigsStore.set('rememberMe', true);
                    } else {
                      sessionStorage.setItem('logoutOnEndSession', 'true');
                    }
                  }}
                >
                  <LoginText>{'تسجيل الدخول'}</LoginText>
                </Button>
              </Form>
            </Card>
          </Container>
        </Col>
      </Row>
    </AntdLayout>
  );
};

export default Login;

const Container = styled.div`
  max-width: 408px;
  margin: auto;
  display: flex;
  flex-direction: column;
`;

export const Image = styled.img`
  margin-bottom: 26px;
  align-self: center;
`;

const RememberContainer = styled.div`
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
`;

const LoginText = styled.p`
  color: ${colors.primary};
  font-size: 17;
  border-color: #6ccef4;
`;
