import { IResourceComponentsProps } from '@pankod/refine-core';
import React, { useEffect } from 'react';
import {
  useForm,
  Form,
  Input,
  Edit,
  ListButton,
  Typography,
  ShowButton,
} from '@pankod/refine-antd';
import { IngredientsType } from 'Actions/types';
import { useFormik } from 'formik';
import { EditAccountAction } from '../../../Actions/ConfigsActions';
const { Text } = Typography;

interface Inputs {
  id: number;
  name_ar?: string;
  name_en?: string;
}

export const ingredientsType = IngredientsType?.map((item, key) => ({
  label: item,
  value: key,
}));
export const EditIngredientsCategory: React.FC<
  IResourceComponentsProps
> = () => {
  const { saveButtonProps, queryResult } = useForm<any>();
  const record = queryResult?.data?.data[0];

  const { values, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      name_ar: '',
      name_en: '',
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {
        id: record?.id,
      };

      if (
        submittedValues.name_ar &&
        submittedValues.name_ar !== record.name_ar
      ) {
        inputs.name_ar = submittedValues.name_ar;
        inputs.name_en = submittedValues.name_ar;
      }

      if (Object.keys(inputs)?.length > 0) {
        EditAccountAction(
          'ingredient-category/update',
          '',
          inputs,
          queryResult?.refetch
        );
      }
    },
  });
  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  };

  useEffect(() => {
    if (record?.name_ar) {
      setFieldValue('name_ar', record?.name_ar);
    }
  }, [record, setFieldValue]);

  return (
    <Edit
      saveButtonProps={buttonProps}
      title={'تعديل التصنيف'}
      pageHeaderProps={{
        extra: (
          <>
            <ListButton>
              <Text>{'تصنيف المكونات'}</Text>
            </ListButton>
            <ShowButton>
              <Text>{'التفاصيل'}</Text>
            </ShowButton>
          </>
        ),
      }}
    >
      <Form layout="vertical">
        <Form.Item label="اسم التصنيف" rules={[{ required: true }]}>
          <Input
            name="name_ar"
            onChange={handleChange}
            value={values.name_ar}
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
