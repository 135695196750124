import React from 'react';
import {
  List,
  Table,
  TextField,
  CreateButton,
  ExportButton,
} from '@pankod/refine-antd';
import { useGetIdentity, useNavigation, useTable } from '@pankod/refine-core';
import { Actions } from 'components/AdminPanel/ActionButtons';
import { ExportList } from '../../../Actions/ConfigsActions';
import { Search } from 'components/AdminPanel/Search';

export const CategoryIngredientsListing: React.FC = () => {
  const { data: user } = useGetIdentity();
  const [searchResults, setSearchResults] = React.useState([]);
  const { show, edit } = useNavigation();
  const { tableQueryResult } = useTable({
    syncWithLocation: true,
  });

  const handleExportList = () => {
    ExportList(
      searchResults?.length > 0
        ? searchResults
        : tableQueryResult.data?.data || [],
      'Ingredient Category'
    );
  };

  return (
    <List
      title={`${'تصنيف المكونات'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="ingredient-category"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
              data={tableQueryResult.data?.data || []}
            />
            {user?.account_type !== 2 && (
              <>
                <ExportButton
                  onClick={handleExportList}
                  style={{ marginRight: 10 }}
                >
                  {'تصدير'}
                </ExportButton>
                <CreateButton style={{ marginRight: 10 }}>
                  إنشاء تصنيف
                </CreateButton>
              </>
            )}
          </div>
        ),
      }}
    >
      <Table
        dataSource={
          searchResults?.length > 0
            ? searchResults
            : tableQueryResult.data?.data || []
        }
        rowKey="id"
        style={{ cursor: 'pointer' }}
        onRow={(record) => {
          return {
            onClick: () => {
              record.id && show('ingredient-category', record.id);
            },
          };
        }}
      >
        <Table.Column
          dataIndex="id"
          title={'معرف التصنيف'}
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex={['name_ar']}
          title={'اسم التصنيف'}
          render={(value) => <TextField value={value} />}
        />

        {user?.account_type !== 2 && (
          <Table.Column<any>
            title={'الإجراءات'}
            dataIndex="actions"
            align="center"
            render={(_text, record): any => {
              return (
                <Actions
                  name_ar="المكونات"
                  edit
                  record={record}
                  onClickEdit={() =>
                    record?.id && edit('ingredient-category', record?.id)
                  }
                />
              );
            }}
          />
        )}
      </Table>
    </List>
  );
};
