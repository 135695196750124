import { IResourceComponentsProps } from '@pankod/refine-core';
import React, { useEffect } from 'react';
import {
  useForm,
  Form,
  Input,
  Edit,
  ListButton,
  Typography,
  ShowButton,
  Select,
} from '@pankod/refine-antd';
import { EditAccountAction } from '../../../Actions/ConfigsActions';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
const { Text } = Typography;

interface Inputs {
  start_date?: string;
  end_date?: string;
  code?: string;
  apple_id?: string;
  max_usage?: number;
  discount_type?: number;
  code_type?: number;
  discount?: number;
  membership_type?: number;
  membership_duration?: string;
  is_deleted?: boolean | string;
}

export const EditPromo: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<any>();
  const record = queryResult?.data?.data;

  const { values, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      start_date: '',
      end_date: '',
      code: '',
      apple_id: '',
      max_usage: '',
      discount_type: '',
      membership_type: '',
      membership_duration: '',
      code_type: '',
      discount: '',
      is_deleted: '',
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {};
      if (submittedValues?.start_date !== record?.start_date) {
        inputs.start_date = submittedValues?.start_date;
      }
      if (submittedValues?.end_date !== record?.end_date) {
        inputs.end_date = submittedValues?.end_date;
      }
      if (submittedValues?.code !== record?.code) {
        inputs.code = submittedValues?.code;
      }
      if (submittedValues?.membership_type !== record?.membership_type) {
        inputs.membership_type = +submittedValues?.membership_type;
      }

      if (submittedValues?.max_usage !== record?.max_usage) {
        inputs.max_usage = +submittedValues?.max_usage;
      }
      if (submittedValues?.discount_type !== record?.discount_type) {
        inputs.discount_type = +submittedValues?.discount_type;
      }

      if (submittedValues?.discount !== record?.discount) {
        inputs.discount = +submittedValues?.discount;
      }
      if (
        submittedValues?.membership_duration !== record?.membership_duration
      ) {
        inputs.membership_duration = submittedValues?.membership_duration;
      }

      if (Object.keys(inputs)?.length > 0) {
        EditAccountAction(
          'promo-code-cruds/update/by-id',
          record.id,
          inputs,
          queryResult?.refetch
        );
      }
    },
  });
  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  };

  useEffect(() => {
    if (record) {
      setFieldValue('code', record.code);
      setFieldValue('discount_type', record.discount_type);
      setFieldValue('max_usage', record.max_usage);
      setFieldValue('discount', record.discount);
      setFieldValue('membership_type', record.membership_type);
      setFieldValue('membership_duration', record.membership_duration);
      setFieldValue('start_date', record.start_date);
      setFieldValue('end_date', record.end_date);
    }
  }, [record, setFieldValue]);

  return (
    <Edit
      saveButtonProps={buttonProps}
      title={'تعديل الرمز الترويجي '}
      pageHeaderProps={{
        extra: (
          <>
            <ListButton>
              <Text>{'الرموز'}</Text>
            </ListButton>
            <ShowButton>
              <Text>{'التفاصيل'}</Text>
            </ShowButton>
          </>
        ),
      }}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="الرمز"
          name="code"
          rules={[{ required: true }]}
          required
        >
          <Input name="code" onChange={handleChange} />
        </Form.Item>

        <Form.Item
          label="الحد الاقصى للاستخدام"
          name="max_usage"
          rules={[{ required: true }]}
          required
        >
          <Input type="number" name="max_usage" onChange={handleChange} />
        </Form.Item>

        <Form.Item
          label="قيمة الخصم"
          name="discount"
          rules={[{ required: true }]}
          required
        >
          <Input type="number" name="discount" onChange={handleChange} />
        </Form.Item>

        <Form.Item
          label="مدة الاشتراك"
          name="membership_duration"
          rules={[{ required: true }]}
          required
        >
          <Select
            options={[
              { label: 'شهري', value: 1 },
              { label: 'سنوي', value: 2 },
            ]}
            onChange={(val) => setFieldValue('membership_duration', val)}
          />
        </Form.Item>

        <Form.Item
          label="نوع الخصم"
          name="discount_type"
          rules={[{ required: true }]}
          required
        >
          <Select
            options={[
              { label: 'نسبة', value: 0 },
              { label: 'قيمة', value: 1 },
            ]}
            onChange={(val) => setFieldValue('discount_type', val)}
          />
        </Form.Item>

        <Form.Item
          label="نوع الاشتراك"
          name="membership_type"
          rules={[{ required: true }]}
          required
        >
          <Select
            options={[
              { label: 'عادي', value: 1 },
              { label: 'مميز', value: 2 },
            ]}
            onChange={(val) => setFieldValue('membership_type', val)}
          />
        </Form.Item>

        <Form.Item
          label="تاريخ البدء"
          name="start_date"
          rules={[{ required: true }]}
          required
        >
          <input
            type="date"
            name="start_date"
            onChange={handleChange}
            value={dayjs(values?.start_date).format('YYYY-MM-DD')}
          />
          <input type="date" value="2013-01-08" style={{ display: 'none' }} />
        </Form.Item>

        <Form.Item
          label="تاريخ الانتهاء"
          name="end_date"
          rules={[{ required: true }]}
          required
        >
          <input
            type="date"
            name="end_date"
            onChange={handleChange}
            value={dayjs(values?.end_date).format('YYYY-MM-DD')}
          />
          <input type="date" value="2013-01-08" style={{ display: 'none' }} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
