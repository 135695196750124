import React, { useState } from 'react';
import './styles.css';
import logo from '../../../../images/logo.svg';
import {
  AntdLayout,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Typography,
} from '@pankod/refine-antd';
import { colors } from 'Theme/Colors';
import { ForgotPasswordAction } from 'Actions';
import styled from 'styled-components';

const { Title } = Typography;

export const ForgotPassword: React.FC = () => {
  const [Disabled, setDisabled] = useState(false);
  const navigateToVerification = (email: string) => {
    window.location.replace('/verification');
  };
  const CardTitle = (
    <Title level={3} className="layout-title">
      {'إستعادة كلمة السر'}
    </Title>
  );

  const handleDisableButton = (value: boolean) => {
    setDisabled(value);
  };
  return (
    <AntdLayout className="antdLayout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div
            style={{
              maxWidth: '408px',
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Image src={logo} width="80" alt="logo" />

            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form
                layout="vertical"
                color={colors.primary}
                onFinish={(values) => {
                  ForgotPasswordAction(
                    values,
                    navigateToVerification,
                    handleDisableButton
                  );
                }}
                requiredMark={false}
              >
                <Form.Item
                  name="email"
                  label={'البريد الإلكتروني'}
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="sample@smaple.com" />
                </Form.Item>

                <Button
                  type="default"
                  size="large"
                  htmlType="submit"
                  block
                  color={colors.primary}
                  style={{ background: colors.primary }}
                  disabled={Disabled}
                >
                  <p
                    style={{
                      color: '#fff',
                      fontSize: 17,
                      borderColor: colors.primary,
                    }}
                  >
                    {'إرسال'}
                  </p>
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};

export const Image = styled.img`
  margin-bottom: 26px;
  align-self: center;
`;
