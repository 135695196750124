import React from 'react';
import {
  List,
  Table,
  TextField,
  CreateButton,
  ExportButton,
} from '@pankod/refine-antd';
import { useNavigation, useTable } from '@pankod/refine-core';
import { Actions } from 'components/AdminPanel/ActionButtons';
import { ExportList } from '../../../Actions/ConfigsActions';
import { AccountStatus } from 'Actions/types';
import { Search } from 'components/AdminPanel/Search';
import _ from 'lodash';

export const NutritionistListing: React.FC = () => {
  const { show, edit } = useNavigation();
  const [searchResults, setSearchResults] = React.useState([]);

  const { tableQueryResult } = useTable({
    resource: 'account-cruds',
    initialPageSize: 1000000,
  });

  const nutritionists = useTable({
    resource: 'nutritionist-cruds',
    syncWithLocation: true,
    initialPageSize: 1000000,
  });

  const filteredUsers = tableQueryResult?.data?.data?.filter((item) => {
    return item?.account_type === 2;
  });

  const joinedUsers = filteredUsers
    ?.filter((item) => {
      nutritionists?.tableQueryResult?.data?.data
        .map((nutritionist) => nutritionist?.account_id)
        .includes(item?.id);
      return item;
    })
    .map((item) => {
      return {
        ...item,
        capacity: nutritionists?.tableQueryResult?.data?.data.find(
          (nutritionist) => nutritionist?.account_id === item?.id
        )?.capacity,
      };
    })
    .filter((item) => item.capacity !== undefined);

  const reOrderByDate = _.sortBy(joinedUsers, (item: any) => {
    return item?.created_at;
  }).reverse();

  const handleExportList = () => {
    ExportList(
      searchResults?.length > 0 ? searchResults : reOrderByDate || [],
      'Nutritionists'
    );
  };

  return (
    <List
      title={`${'اخصائي تغذية'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="account"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
              data={filteredUsers || []}
            />
            <ExportButton
              onClick={handleExportList}
              style={{ marginRight: 10 }}
            >
              {'تصدير'}
            </ExportButton>
            <CreateButton style={{ marginRight: 10 }}>
              اضافة اخصائي تغذية
            </CreateButton>
          </div>
        ),
      }}
    >
      <Table
        dataSource={
          searchResults?.length > 0 ? searchResults : reOrderByDate || []
        }
        rowKey="id"
        style={{ cursor: 'pointer' }}
        onRow={(record) => {
          return {
            onClick: () => {
              record.id && show('nutritionist-cruds', record.id);
            },
          };
        }}
      >
        <Table.Column
          dataIndex={'id'}
          title={'معرف اخصائي التغذية'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'name'}
          title={'الأسم'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'email'}
          title={'البريد الالكتروني'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex="capacity"
          title={'السعة'}
          render={(value) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={'status'}
          title={'الحالة'}
          render={(value) => <TextField value={AccountStatus[+value] || '-'} />}
        />
        <Table.Column<any>
          title={'الإجراءات'}
          dataIndex="actions"
          align="center"
          render={(_text, record): any => {
            return (
              <Actions
                name_ar="اخصائي تغذيه"
                edit
                record={record}
                onClickEdit={() =>
                  record?.id && edit('nutritionist-cruds', record?.id)
                }
              />
            );
          }}
        />
      </Table>
    </List>
  );
};
