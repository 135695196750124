import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import {
  notification,
  Modal,
  Form,
  Select,
  useTable,
  Typography,
} from '@pankod/refine-antd';
import { AccountStore } from 'Actions/Stores/Account';
import { ConfigsStore } from 'Actions/Stores/Configs';
import { apiRequest } from 'Util/api';

interface Props {
  visible: any;
  setVisible: (val: any) => void;
  redirect?: string;
  info?: any;
  refresh?: (val: boolean) => void;
  modalType?: string;
}

const AddToMeal = ({ visible, setVisible, modalType }: Props) => {
  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      meal_id: '',
      ingredient_id: [],
      allergy_id: [],
      meal_ingredient_id: '',
    },
    onSubmit: async (submittedValues) => {
      let inputs: any = {};

      if (submittedValues.ingredient_id && modalType === 'ingredient') {
        const ingArr: { ingredient_id: number; meal_id: string }[] = [];
        // eslint-disable-next-line array-callback-return
        submittedValues.ingredient_id?.map((item: string | number) => {
          ingArr.push({
            ingredient_id: +item,
            meal_id: submittedValues.meal_id,
          });
        });

        inputs = { bulk: ingArr };
      }
      if (submittedValues.allergy_id && modalType === 'allergy') {
        const ingArr: { allergy_id: number; meal_id: string }[] = [];
        // eslint-disable-next-line array-callback-return
        submittedValues.allergy_id?.map((item: string | number) => {
          ingArr.push({
            allergy_id: +item,
            meal_id: submittedValues.meal_id,
          });
        });

        inputs = { bulk: ingArr };
      }

      if (modalType === 'substitute') {
        if (submittedValues.ingredient_id) {
          inputs.ingredient_id = submittedValues.ingredient_id;
        }
        if (submittedValues.meal_id) {
          inputs.meal_id = submittedValues.meal_id;
        }
      }

      const url =
        modalType === 'ingredient'
          ? '/Meal_ingredients-cruds/bulk'
          : modalType === 'substitute'
          ? `/Meal_ingredients-cruds/update/by-id/${submittedValues.meal_ingredient_id}`
          : '/meal_allergy-cruds/bulk';

      if (Object.keys(inputs).length < 1) return;

      try {
        const data = await apiRequest<boolean>({
          url,
          method: modalType === 'substitute' ? 'PATCH' : 'POST',
          data: inputs,
          headers: {
            device_id: await ConfigsStore.get('device_id'),
            authorization: `Bearer ${
              (
                await AccountStore.get('account')
              )?.access_token
            }`,
          },
        });

        if (data?.data) {
          setVisible('');
          notification.success({
            message: 'نجاح',
            description: 'تمت الاضافة بنجاح',
          });
        }
      } catch (error: any) {
        setVisible('');
        notification.error({
          message: 'خطأ',
          description: error?.message,
        });
      }
    },
  });

  const ingredients = useTable({
    resource: 'ingredients-cruds',
    initialPageSize: 1000000,
  });
  const allergies = useTable({
    resource: 'Allergy-cruds',
    initialPageSize: 100000,
  });
  const mealIngredients = useTable({
    resource: 'Meal_ingredients-cruds',
    initialPageSize: 100000,
  });
  const substitutes = useTable({
    resource: 'Ingredient-Substitutes-cruds',
    initialPageSize: 100000,
  });

  const filteredIngredients = ingredients?.tableProps?.dataSource?.map(
    (item) => {
      return { label: item.name_ar, value: item.id };
    }
  );
  const filteredAllergies = allergies?.tableProps?.dataSource?.map((item) => {
    return { label: item.name_ar, value: item.id };
  });

  const filteredMealIngredients =
    mealIngredients?.tableProps?.dataSource?.filter((item) => {
      return item?.meal_id === visible?.id;
    });

  const joinedIngredients = filteredMealIngredients?.map((item) => {
    const findIngredients = ingredients?.tableProps?.dataSource?.find(
      (i) => item.ingredient_id === i.id
    );
    return { label: findIngredients?.name_ar, value: item.id };
  });

  const joinedSubIngredients = substitutes?.tableProps?.dataSource
    // eslint-disable-next-line array-callback-return
    ?.filter((item) => {
      if (item?.meal_ingredient_id === values.meal_ingredient_id) {
        return item;
      }
    })
    ?.map((item) => {
      const findIngredients = ingredients?.tableProps?.dataSource?.find(
        (i) => item.ingredient_id === i.id
      );
      return { label: findIngredients?.name_ar, value: item.ingredient_id };
    });

  useEffect(() => {
    if (visible?.id) {
      setFieldValue('meal_id', visible?.id);
    }
  }, [setFieldValue, visible]);

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible('')}
      width={500}
      okText={'إرسال'}
      cancelText={'إلغاء'}
      onOk={() => handleSubmit()}
    >
      <Form layout="vertical" style={{ marginTop: 25 }}>
        {modalType === 'ingredient' ? (
          <Form.Item
            label="المكونات"
            name={'ingredient_id'}
            rules={[{ required: true }]}
          >
            <Select
              mode="multiple"
              options={filteredIngredients || []}
              onChange={(val) => setFieldValue('ingredient_id', val)}
            />
          </Form.Item>
        ) : modalType === 'substitute' ? (
          <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography.Text strong>
                {'اختر المكون المراد استبداله و بديله'}
              </Typography.Text>
            </div>
            <Form.Item
              label="مكونات الوجبة"
              name={'meal_ingredient_id'}
              rules={[{ required: true }]}
            >
              <Select
                placeholder="اختر المكون المراد استبداله"
                options={joinedIngredients || []}
                onChange={(val) => setFieldValue('meal_ingredient_id', val)}
              />
            </Form.Item>
            <Form.Item
              label="المكونات البديلة"
              name={'ingredient_id'}
              rules={[{ required: true }]}
            >
              <Select
                placeholder="اختر البديل"
                options={joinedSubIngredients || []}
                onChange={(val) => setFieldValue('ingredient_id', val)}
              />
            </Form.Item>
          </>
        ) : (
          <Form.Item
            label="الحساسيات"
            name={'allergy_id'}
            rules={[{ required: true }]}
          >
            <Select
              mode="multiple"
              placeholder="اختر الحساسية"
              options={filteredAllergies || []}
              onChange={(val) => setFieldValue('allergy_id', val)}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default AddToMeal;
