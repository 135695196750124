import React from 'react';
import {
  List,
  Table,
  TextField,
  BooleanField,
  CreateButton,
  ExportButton,
} from '@pankod/refine-antd';
import { useGetIdentity, useNavigation, useTable } from '@pankod/refine-core';
import { IngredientsType } from 'Actions/types';
import { Actions } from 'components/AdminPanel/ActionButtons';
import {
  DeleteRecordAction,
  ExportList,
} from '../../../Actions/ConfigsActions';
import { Search } from 'components/AdminPanel/Search';
import { ingredientsType } from './EditIngredients';

export const IngredientsListing: React.FC = () => {
  const { data: user } = useGetIdentity();
  const [searchResults, setSearchResults] = React.useState([]);
  const { show, edit } = useNavigation();
  const { tableQueryResult } = useTable({
    syncWithLocation: true,
  });

  const handleExportList = () => {
    ExportList(
      searchResults?.length > 0
        ? searchResults
        : tableQueryResult.data?.data || [],
      'Ingredients'
    );
  };

  return (
    <List
      title={`${'المكونات'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="ingredient"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
              data={tableQueryResult.data?.data || []}
              typeItems={ingredientsType}
            />
            {user?.account_type !== 2 && (
              <>
                <ExportButton
                  onClick={handleExportList}
                  style={{ marginRight: 10 }}
                >
                  {'تصدير'}
                </ExportButton>
                <CreateButton style={{ marginRight: 10 }}>
                  إنشاء مكون
                </CreateButton>
              </>
            )}
          </div>
        ),
      }}
    >
      <Table
        dataSource={
          searchResults?.length > 0
            ? searchResults
            : tableQueryResult.data?.data || []
        }
        rowKey="id"
        style={{ cursor: 'pointer' }}
        onRow={(record) => {
          return {
            onClick: () => {
              record.id && show('ingredients-cruds', record.id);
            },
          };
        }}
      >
        <Table.Column
          dataIndex="id"
          title={' معرف المكون'}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="name_ar"
          title={'اسم المكون'}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="type"
          title={'نوع المكون'}
          render={(value) => <TextField value={IngredientsType[+value]} />}
        />
        <Table.Column
          dataIndex={'protein'}
          title={' بروتين'}
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex={'carbohydrates'}
          title={'الكربوهيدرات'}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={'calories'}
          title={'السعرات الحرارية'}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={'fat'}
          title={'دهون'}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={'is_deleted'}
          title={'محذوف'}
          align="center"
          render={(value) => <BooleanField value={value} />}
        />
        {user?.account_type !== 2 && (
          <Table.Column<any>
            title={'الإجراءات'}
            dataIndex="actions"
            align="center"
            render={(_text, record): any => {
              return (
                <Actions
                  name_ar="المكونات"
                  deleteRecord
                  edit
                  record={record}
                  onClickEdit={() =>
                    record?.id && edit('ingredients-cruds', record?.id)
                  }
                  onClickDelete={() =>
                    DeleteRecordAction(
                      `ingredients-cruds/update/by-id/${record?.id}`,
                      tableQueryResult?.refetch,
                      record?.is_deleted
                    )
                  }
                />
              );
            }}
          />
        )}
      </Table>
    </List>
  );
};
