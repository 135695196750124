import { createGlobalStyle } from 'styled-components';
import ExtraBold from './NotoKufiArabic-ExtraBold.ttf';
import Medium from './NotoKufiArabic-Medium.ttf';
import Regular from './NotoKufiArabic-Regular.ttf';
import SemiBOLD from './NotoKufiArabic-SemiBold.ttf';

export default createGlobalStyle`
    @font-face {
        font-family: 'Extra Bold';
        src: local('NotoKufiArabic'), local('NotoKufiArabic'),
        url(${ExtraBold}) format('truetype');
        
    }
     @font-face {
        font-family: 'Medium';
        src: local('NotoKufiArabic'), local('NotoKufiArabic'),
        url(${Medium}) format('truetype');
       
    }
     @font-face {
        font-family: 'Regular';
        src: local('NotoKufiArabic'), local('NotoKufiArabic'),
        url(${Regular}) format('truetype');
     
    }
    @font-face {
        font-family: 'Semi BOLD';
        src: local('NotoKufiArabic'), local('NotoKufiArabic'),
        url(${SemiBOLD}) format('truetype');
       
    }
    body{
        overflow-x: hidden;
    }
`;
