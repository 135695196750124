import React from 'react';
import {
  BooleanField,
  Grid,
  List,
  ListButton,
  Show,
  Space,
  Table,
  TextField,
  Typography,
} from '@pankod/refine-antd';
import {
  useGetIdentity,
  useNavigation,
  useShow,
  useTable,
} from '@pankod/refine-core';
import { Actions } from 'components/AdminPanel/ActionButtons';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { DeleteRecordAction } from '../../../Actions/ConfigsActions';
import { FoodSource, MealType } from 'Actions/types';

const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

function ShowMeals() {
  const { data: user } = useGetIdentity();
  const screens = useBreakpoint();
  const { edit, show } = useNavigation();
  const { queryResult } = useShow({});
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;
  const mealIngredients = useTable({
    resource: 'Meal_ingredients-cruds',
    initialPageSize: 100000,
  });
  const filteredIngredients =
    mealIngredients?.tableQueryResult?.data?.data?.filter((item) => {
      return item?.meal_id === record?.id;
    });

  const mealallergy = useTable({
    resource: 'meal_allergy-cruds',
    initialPageSize: 100000,
  });

  const filteredmealallergy = mealallergy?.tableQueryResult?.data?.data?.filter(
    (item) => {
      return item?.meal_id === record?.id;
    }
  );

  return (
    <>
      <Show
        isLoading={isLoading}
        title={'تفاصيل الوجبة'}
        pageHeaderProps={{
          extra: (
            <Space>
              <ListButton>
                <Text>{'الوجبات'}</Text>
              </ListButton>
              <Actions
                name_ar="الوجبة"
                deleteRecord={user?.account_type !== 2}
                edit
                record={record}
                onClickDelete={() =>
                  DeleteRecordAction(
                    `Meal-cruds/update/by-id/${record?.id}`,
                    refetch,
                    record?.is_deleted
                  )
                }
                onClickEdit={() => record?.id && edit('Meal-cruds', record?.id)}
              />
            </Space>
          ),
        }}
      >
        <div
          className="detailsWrapper"
          style={{ justifyContent: screens.md ? '' : 'space-between' }}
        >
          <Break breakPoint={!!screens.md}>
            <Title level={5}>{' معرف الوجبة'}</Title>
            <Text>{record?.id}</Text>
            <Title level={5}>{'اسم الوجبة'}</Title>
            <Text>{record?.name_ar}</Text>

            <Title level={5}>{'أنشأ في'}</Title>
            <Text>{dayjs(record?.created_at).format('YYYY-MM-DD, HH:mm')}</Text>
          </Break>

          <Break breakPoint={!!screens.md}>
            <Title level={5}>{'نوع الوجبة'}</Title>
            <Text>{MealType[+record?.meal_type]}</Text>

            <Title level={5}>{'تفضيل الوجبات'}</Title>
            <Text>{FoodSource[+record?.food_preference]}</Text>

            {record?.description && (
              <>
                <Title level={5}>{'الوصف'}</Title>
                <DescriptionWrapper
                  breakPoint={record?.description?.length > 50}
                >
                  <Text style={{ whiteSpace: 'pre-line' }}>
                    {record?.description}
                  </Text>
                </DescriptionWrapper>
              </>
            )}
          </Break>
          <Break breakPoint={!!screens.md}>
            <Title level={5}>{'محذوف'}</Title>
            <BooleanField value={record?.is_deleted} />
          </Break>
        </div>
      </Show>
      <div style={{ marginTop: 20 }} />
      {(filteredIngredients || [])?.length > 0 && (
        <>
          <div style={{ marginTop: 20 }} />
          <List
            title={`${'مكونات الوجبة'}`}
            pageHeaderProps={{
              extra: <></>,
            }}
          >
            <Table
              {...mealIngredients}
              dataSource={filteredIngredients}
              rowKey="id"
              style={{ cursor: 'pointer' }}
              onRow={(doc) => {
                return {
                  onClick: () => {
                    doc?.id && show('ingredients-cruds', doc?.ingredient_id);
                  },
                };
              }}
            >
              <Table.Column
                dataIndex="ingredient_id"
                title={'معرف المكون'}
                render={(value) => <TextField value={value || 'No Data'} />}
              />
              <Table.Column
                dataIndex="created_at"
                title={'أنشئت في'}
                render={(value) => (
                  <TextField value={dayjs(value).format('YYYY-MM-DD, HH:mm')} />
                )}
              />
              <Table.Column
                dataIndex={'deleted'}
                title={'محذوف'}
                align="center"
                render={(value) => <BooleanField value={value} />}
              />
            </Table>
          </List>
        </>
      )}
      <div style={{ marginTop: 20 }} />
      {(filteredmealallergy || [])?.length > 0 && (
        <>
          <div style={{ marginTop: 20 }} />
          <List
            title={`${'حساسية الطعام'}`}
            pageHeaderProps={{
              extra: <></>,
            }}
          >
            <Table
              {...mealallergy}
              dataSource={filteredmealallergy}
              rowKey="id"
              style={{ cursor: 'pointer' }}
              onRow={(doc) => {
                return {
                  onClick: () => {
                    doc?.allergy_id && show('Allergy-cruds', doc?.allergy_id);
                  },
                };
              }}
            >
              <Table.Column
                dataIndex="allergy_id"
                title={'معرف الحساسية'}
                render={(value) => <TextField value={value || ' No Data '} />}
              />
              <Table.Column
                dataIndex="created_at"
                render={(value) => (
                  <TextField value={dayjs(value).format('YYYY-MM-DD, HH:mm')} />
                )}
              />
              <Table.Column
                dataIndex={'is_deleted'}
                title={'محذوف'}
                align="center"
                render={(value) => <BooleanField value={value} />}
              />
            </Table>
          </List>
        </>
      )}
    </>
  );
}

export default ShowMeals;

const Break = styled.div<{ breakPoint?: boolean }>`
  margin: ${(props) => (props.breakPoint ? '' : '20px')};
`;
const DescriptionWrapper = styled.div<{ breakPoint?: boolean }>`
  width: ${(props) => (props.breakPoint ? '400px' : '200px')};
`;
