import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Modal, Form, Select } from '@pankod/refine-antd';
import { GetRecordsAction, ReassignNutritionistAction } from 'Actions';
import Swal from 'sweetalert2';

interface Props {
  visible: any;
  setVisible: (val: any) => void;
  redirect?: string;
  thread_id: string;
  refresh?: () => void;
}

const ReassignNutritionistModal = ({
  visible,
  setVisible,
  thread_id,
  refresh,
}: Props) => {
  const [nutritionists, setNutritionists] = React.useState<any[]>([]);

  const { setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      new_nutritionist_id: '',
    },
    onSubmit: async (submittedValues) => {
      if (!submittedValues.new_nutritionist_id) return;
      Swal.fire({
        title: 'هل أنت متأكد أنك تريد الاستمرار؟',
        showCancelButton: true,
        confirmButtonColor: '#66ce78',
        confirmButtonText: 'إرسال',
        cancelButtonText: 'إلغاء',
      }).then(async (result) => {
        if (result.isConfirmed) {
          ReassignNutritionistAction(
            {
              thread_id,
              new_nutritionist_id: submittedValues.new_nutritionist_id,
            },
            refresh
          );
          setVisible('');
        }
      });
    },
  });

  useEffect(() => {
    GetRecordsAction(
      'nutritionist/nutritionist-accounts-threads?is_available=true',
      setNutritionists
    );
  }, []);

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible('')}
      width={500}
      okText={'إرسال'}
      cancelText={'إلغاء'}
      onOk={() => handleSubmit()}
    >
      <Form layout="vertical" style={{ marginTop: 25 }}>
        <Form.Item label="الأخصائيون البدلاء">
          <Select
            // @ts-ignore
            options={nutritionists
              .map((nutritionist) => {
                if (nutritionist.account_id === visible?.nutritionist_id)
                  return null;
                return {
                  label: `${nutritionist.account.name} (السعة المتاحة: ${
                    nutritionist.capacity - nutritionist.thread?.length
                  } من أصل ${nutritionist.capacity})`,
                  value: nutritionist.account_id,
                };
              })
              .filter(Boolean)}
            showSearch
            onChange={(val: unknown) =>
              setFieldValue('new_nutritionist_id', val)
            }
            placeholder={'اختر الأخصائي البديل'}
            filterOption={(input, option) =>
              String(option?.label)
                ?.toLowerCase()
                ?.indexOf(input.toLowerCase()) >= 0
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReassignNutritionistModal;
