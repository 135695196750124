import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  direction: rtl;
  flex-direction: column;
  background: rgb(251, 250, 250);
  background: -moz-linear-gradient(
    0deg,
    rgba(251, 250, 250, 1) 0%,
    rgba(136, 206, 191, 1) 0%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(251, 250, 250, 1) 0%,
    rgba(136, 206, 191, 1) 0%
  );
  background: linear-gradient(
    0deg,
    rgba(251, 250, 250, 1) 0%,
    rgba(136, 206, 191, 1) 0%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbfafa",endColorstr="#88cebf",GradientType=1);
  /* @media (min-width: 700px) { */
  /* height: 100vh; */
  /* } */
`;

export const PrivacyContainer = styled.div`
  display: flex;
  direction: rtl;
  flex-direction: column;
  background: rgb(251, 250, 250);
  background: -moz-linear-gradient(
    0deg,
    rgba(251, 250, 250, 1) 0%,
    rgba(136, 206, 191, 1) 0%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(251, 250, 250, 1) 0%,
    rgba(136, 206, 191, 1) 0%
  );
  background: linear-gradient(
    0deg,
    rgba(251, 250, 250, 1) 0%,
    rgba(136, 206, 191, 1) 0%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbfafa",endColorstr="#88cebf",GradientType=1);
  @media (min-width: 700px) {
    height: 100vh;
  }
`;
export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 20px 0px;
`;

export const OuterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: baseline;
  align-items: flex-start;
  flex-direction: column;
  width: 80%;
`;

export const PageTitle = styled.h1`
  margin-top: 30px;
  font-size: 35px;
  color: #fff;
  font-family: bold;
  line-height: 22px;
  font-family: sans-serif;
  @media (max-width: 415px) {
    font-size: 25px;
  }
`;

export const TermsTitleText = styled.h2<{ marginTop?: boolean }>`
  font-size: 23px;
  color: #fff;
  font-family: bold;
  line-height: 22px;
  ${(props) => props.marginTop && `margin-top: 25px`};
  font-family: sans-serif;
  @media (max-width: 415px) {
    font-size: 20px;
  }
`;

export const TermsText = styled.h2`
  font-size: 18px;
  color: #fff;
  font-family: 'Semi BOLD';
  line-height: 22px;
  margin-top: 20px;
  font-family: sans-serif;
`;

export const Img = styled.div`
  background: #ffffff;
  width: fit-content;
  border-radius: 15px;
`;

export const HomeButton = styled.h2`
  font-size: 18px;
  color: #fff;
  font-family: 'Semi BOLD';
  line-height: 22px;
  margin-top: 20px;
  margin-right: 35px;
  margin-left: 35px;
  font-family: sans-serif;
  border-bottom: 1px solid #fff;
  cursor: pointer;
  line-height: 22px;
  :hover {
    opacity: 0.6;
  }
`;
