import { IResourceComponentsProps, useGetIdentity } from '@pankod/refine-core';
import React, { useEffect, useState } from 'react';
import {
  useForm,
  Form,
  Input,
  Edit,
  Select,
  ListButton,
  Typography,
  ShowButton,
  Button,
  notification,
  Upload,
  Avatar,
  Space,
} from '@pankod/refine-antd';
import { FoodSource, MealType } from 'Actions/types';
import { useFormik } from 'formik';
import { EditAccountAction } from '../../../Actions/ConfigsActions';
import AddToMeal from 'components/AdminPanel/AddToMeal';
import { MdOutlineSick, MdSetMeal } from 'react-icons/md';
import { TbReplace } from 'react-icons/tb';
import { API_KEY, GET_UPLOAD_URI, POST_UPLOAD_URI } from 'Configs';
import { AccountStore } from 'Actions/Stores/Account';
import { ConfigsStore } from 'Actions/Stores/Configs';
import noPhoto from '../../../images/noPhoto.png';

const { Text } = Typography;

interface Inputs {
  name_ar?: string;
  name_en?: string;
  description?: string;
  meal_type?: number | string;
  food_preference?: number | string;
  is_deleted?: boolean | string;
  upload_id?: number;
}

export const mealTypes = MealType?.map((item, key) => ({
  label: item,
  value: key,
}));
export const foodPreferences = FoodSource?.map((item, key) => ({
  label: item,
  value: key,
}));
export const EditMeal: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();
  const [visible, setVisible] = useState();
  const [modalType, setModalType] = useState('');
  const [getAccount, setAccount] = useState<any>();
  const [device_id, setDeviceId] = useState<any>();
  const { formProps, saveButtonProps, queryResult } = useForm<any>();
  const [photoName, setPhotoName] = useState<string>();
  const record = queryResult?.data?.data;

  const handleGetToken = async () => {
    const account = await AccountStore.get('account');
    const deviceId = await ConfigsStore.get('device_id');
    setDeviceId(deviceId);
    setAccount(account);
  };

  useEffect(() => {
    handleGetToken();
  }, []);

  const { values, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      name_ar: '',
      name_en: '',
      description: '',
      meal_type: '',
      food_preference: '',
      is_deleted: '',
      upload_id: '',
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {};
      if (
        submittedValues.name_ar &&
        submittedValues.name_ar !== record.name_ar
      ) {
        inputs.name_ar = submittedValues.name_ar;
        inputs.name_en = submittedValues.name_ar;
      }
      if (
        submittedValues.description &&
        submittedValues.description !== record.description
      ) {
        inputs.description = submittedValues.description;
      }
      if (
        submittedValues.meal_type !== '' &&
        submittedValues.meal_type !== record.meal_type
      ) {
        inputs.meal_type = submittedValues.meal_type;
      }
      if (
        submittedValues.is_deleted &&
        submittedValues.is_deleted !== record.is_deleted
      ) {
        inputs.is_deleted = submittedValues.is_deleted;
      }
      if (
        submittedValues.upload_id &&
        submittedValues.upload_id !== record.upload_id
      ) {
        inputs.upload_id = +submittedValues.upload_id;
      }
      if (
        submittedValues.food_preference !== '' &&
        submittedValues.food_preference !== record.food_preference
      ) {
        inputs.food_preference = submittedValues.food_preference;
      }

      if (Object.keys(inputs)?.length > 0) {
        EditAccountAction(
          'Meal-cruds/update/by-id',
          record.id,
          inputs,
          queryResult?.refetch
        );
      }
    },
  });
  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  };

  useEffect(() => {
    if (values.upload_id) {
      notification.success({
        message: 'نحاح',
        description: 'تم التحميل بنجاح',
      });
    }
  }, [values.upload_id]);

  return (
    <Edit
      saveButtonProps={buttonProps}
      title={'تعديل الوجبة'}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Button
              icon={<MdSetMeal style={{ margin: '3px 0px -2px 7px' }} />}
              style={{ margin: '3px 0px' }}
              onClick={() => {
                setModalType('ingredient');
                setVisible(record);
              }}
            >
              أضف مكون إلى الوجبة
            </Button>
            <Button
              icon={<TbReplace style={{ margin: '3px 0px -2px 7px' }} />}
              style={{ margin: '3px 5px' }}
              onClick={() => {
                setModalType('substitute');
                setVisible(record);
              }}
            >
              أضف بديل لمكون
            </Button>
            <Button
              icon={<MdOutlineSick style={{ margin: '3px 0px -2px 7px' }} />}
              style={{ margin: '3px 0px' }}
              onClick={() => {
                setModalType('allergy');
                setVisible(record);
              }}
            >
              أضف حساسية إلى الوجبة
            </Button>
            <ListButton style={{ margin: '3px 5px' }}>
              <Text>{'الوجبات'}</Text>
            </ListButton>
            <ShowButton style={{ margin: '3px 0px' }}>
              <Text>{'التفاصيل'}</Text>
            </ShowButton>
          </div>
        ),
      }}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="اسم الوجبة"
          name="name_ar"
          rules={[{ required: true }]}
        >
          <Input
            name="name_ar"
            onChange={handleChange}
            disabled={user?.account_type === 2}
          />
        </Form.Item>
        <Form.Item label="الوصف" name="description">
          <Input.TextArea
            name="description"
            onChange={handleChange}
            disabled={user?.account_type === 2}
          />
        </Form.Item>
        <Form.Item
          label="نوع الوجبة"
          name={'meal_type'}
          rules={[{ required: true }]}
        >
          <Select
            defaultValue={record?.meal_type}
            options={mealTypes}
            onChange={(val) => setFieldValue('meal_type', val)}
            disabled={user?.account_type === 2}
          />
        </Form.Item>
        <Form.Item label="تفضيل الوجبات" name={'food_preference'}>
          <Select
            defaultValue={record?.food_preference}
            options={foodPreferences}
            onChange={(val) => setFieldValue('food_preference', val)}
            disabled={user?.account_type === 2}
          />
        </Form.Item>
      </Form>
      <Form.Item noStyle>
        <Upload.Dragger
          disabled={user?.account_type === 2}
          name="file"
          action={POST_UPLOAD_URI}
          listType="picture"
          maxCount={1}
          onChange={(file) => {
            setFieldValue('upload_id', file?.file?.response?.id);
            setPhotoName(file?.file?.response?.id);
          }}
          headers={{
            authorization: `Bearer ${getAccount?.access_token}`,
            device_id,
            api_key: API_KEY,
          }}
        >
          <Space direction="vertical" size={2}>
            <Avatar
              style={{
                width: '100px',
                height: '100px',
                borderRadius: 5,
              }}
              src={
                photoName
                  ? `${GET_UPLOAD_URI}/${photoName}`
                  : record?.upload_id
                  ? `${GET_UPLOAD_URI}/${record?.upload_id}`
                  : noPhoto
              }
              alt="Banner"
            />
          </Space>
        </Upload.Dragger>
      </Form.Item>
      {visible && (
        <AddToMeal
          visible={visible}
          setVisible={setVisible}
          modalType={modalType}
        />
      )}
    </Edit>
  );
};
