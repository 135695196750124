import { Input, Modal, Spin, Typography } from '@pankod/refine-antd';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { FiSend } from 'react-icons/fi';
import {
  GetThreadAction,
  MarkMessageAsAction,
  SendMessageAction,
} from 'Actions/ConfigsActions';
import io from 'socket.io-client';
import { LoadingOutlined } from '@ant-design/icons';
import { colors } from 'Theme/Colors';
import { SOCKET_URI } from 'Configs';
const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;

interface Props {
  visible: any;
  setVisible: (val: any) => void;
  redirect?: string;
  info?: any;
  nutritionistId: string;
  nutritionistName: string;
  threadId: number;
  refresh?: () => void;
}
export default function ChatModal({
  visible,
  nutritionistId,
  threadId,
  setVisible,
  info,
  nutritionistName,
}: Props) {
  const socket = io(SOCKET_URI);

  const bottom = useRef<null | HTMLDivElement>(null);

  const [isConnected, setIsConnected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputText, setInputText] = useState<string>();
  const [messages, setMessages] = useState<any>([]);

  const getMessages = (value: any) => setMessages(value?.reverse());

  const handleGetThread = useCallback(
    (noLoading?: boolean) => {
      GetThreadAction(info?.id, getMessages, setLoading, noLoading);
    },
    [info?.id]
  );

  const addNewMessage = (message: ChangeEvent<HTMLInputElement>) => {
    setInputText(message?.target?.value);
  };

  const handleSubmit = () => {
    if (inputText) {
      SendMessageAction(
        {
          message: inputText,
          sender_id: +nutritionistId,
          thread_id: threadId,
        },
        handleGetThread
      );
      setInputText('');
      scrollToBottom();
    }
  };

  const scrollToBottom = () => {
    bottom?.current?.scrollIntoView();
  };

  useEffect(() => {
    isConnected && scrollToBottom();
  }, [isConnected, messages]);

  useEffect(() => {
    if (messages?.length > 0) {
      for (let i = 0; i < messages?.length; i++) {
        if (messages[i]?.is_read === false) {
          MarkMessageAsAction(messages[i]?.id, true);
        }
      }
    }
  }, [messages]);

  useEffect(() => {
    handleGetThread();
  }, [handleGetThread]);

  useEffect(() => {
    socket.on('connect', () => {
      setIsConnected(true);
      socket.on(`${threadId}-${nutritionistId}`, (data) => {
        handleGetThread();
      });
    });

    return () => {
      socket.off('connect');
    };
  }, [handleGetThread, nutritionistId, socket, threadId]);

  const handleCloseModal = () => {
    socket.on('disconnect', () => {
      setIsConnected(false);
    });
    socket.off('disconnect');
    setVisible('');
  };

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCloseModal}
      width={650}
      footer={null}
      cancelText={'إلغاء'}
      okText={'حسنا'}
    >
      <Container>
        {isConnected ? (
          <ChatContainer>
            <MessagesWrapper className="messages">
              {messages?.length === 0 && !loading && (
                <NoMessages>{'لا يوجد رسائل'}</NoMessages>
              )}
              {messages?.length === 0 && loading && (
                <SpinWrapper>
                  <Spin indicator={antIcon} />
                </SpinWrapper>
              )}
              {messages?.map((item: any) => (
                <>
                  {info?.profile_id === item?.sender_id ? (
                    <RightContainer>
                      <div />
                      <InnerMessageWrapper>
                        <Typography.Text style={{ fontWeight: 'bold' }}>
                          {info?.name}
                        </Typography.Text>
                        <RightBubble>{item?.message}</RightBubble>
                      </InnerMessageWrapper>
                    </RightContainer>
                  ) : (
                    <LeftContainer>
                      <InnerMessageWrapper>
                        <Typography.Text style={{ fontWeight: 'bold' }}>
                          {nutritionistName}
                        </Typography.Text>
                        <LeftBubble>{item?.message}</LeftBubble>
                      </InnerMessageWrapper>
                    </LeftContainer>
                  )}
                </>
              ))}
              <div ref={bottom} />
            </MessagesWrapper>
            <InputContainer onSubmit={onFormSubmit}>
              <SendButton>
                <FiSend size={20} color={'#fff'} onClick={handleSubmit} />
              </SendButton>
              <Input
                placeholder="اكتب رسالة..."
                style={{ width: '88%' }}
                value={inputText}
                onChange={addNewMessage}
              />
            </InputContainer>
          </ChatContainer>
        ) : (
          <SpinWrapper>
            <Spin indicator={antIcon} />
          </SpinWrapper>
        )}
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
  margin-top: 20px;
`;
const NoMessages = styled.div`
  position: absolute;
  top: 40%;
  left: 47%;
  font-weight: bold;
  size: 35px;
`;

const ChatContainer = styled.div`
  display: flex;
  flex: 1;
  border: 1px gray solid;
  border-radius: 5px;
  height: 530px;
  flex-direction: column;
  padding: 25px;
  padding-bottom: 15px;
`;

const MessagesWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: scroll;
`;

const LeftContainer = styled.div`
  display: flex;
  justify-content: baseline;
  justify-items: flex-start;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  justify-items: baseline;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const LeftBubble = styled.div`
  display: flex;
  min-width: 75px;
  max-width: 240px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 4px;
  min-height: 50px;
  border-radius: 5px;
  background-color: ${colors.blue200};
  font-size: 14px;
  color: ${colors.white};
  padding: 10px 15px;
  font-weight: 500;
`;

const RightBubble = styled.div`
  display: flex;
  min-width: 75px;
  max-width: 240px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-top: 4px;
  min-height: 50px;
  border-radius: 5px;
  background-color: ${colors.neutral1000};
  font-size: 14px;
  color: ${colors.darkGrey};
  padding: 10px 15px;
  font-weight: 500;
`;

const InputContainer = styled.form`
  margin-top: 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
`;

const SendButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 100px;
  padding: 10px;
  background-color: #00a8ff;
  border: 2px #faeeee solid;
  cursor: pointer;
`;

const InnerMessageWrapper = styled.div<{ end?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: baseline;
  ${(props) => props.end && 'align-items: flex-end;'}
  ${(props) => props.end && 'justify-content: flex-end;'}
`;

const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
`;
