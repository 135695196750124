import * as React from 'react';
import { useForm, Create, Form, Input, Select } from '@pankod/refine-antd';

import { useFormik } from 'formik';
import { CreateAction } from 'Actions';
import { useNavigation } from '@pankod/refine-core';

export const CreatePromo = () => {
  const navigate = useNavigation();
  const { formProps, saveButtonProps, queryResult } = useForm();
  const { setFieldValue, handleChange, handleSubmit } = useFormik({
    initialValues: {
      start_date: '',
      end_date: '',
      code: '',
      apple_id: '',
      max_usage: '',
      discount_type: '',
      membership_type: '',
      membership_duration: '',
      code_type: '',
      discount: '',
      is_deleted: '',
    },
    onSubmit: async (submittedValues) => {
      const inputs = {
        start_date: submittedValues?.start_date,
        end_date: submittedValues?.end_date,
        code: submittedValues?.code,
        max_usage: +submittedValues?.max_usage,
        discount_type: +submittedValues?.discount_type,
        code_type: 1,
        membership_type: +submittedValues?.membership_type,
        discount: +submittedValues?.discount,
        membership_duration: +submittedValues?.membership_duration,
      };
      if (Object.keys(inputs)?.length > 0) {
        CreateAction('promo-code-cruds', inputs, undefined, navigate.goBack);
      }
    },
  });

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  };

  return (
    <Create
      isLoading={queryResult?.isFetching}
      saveButtonProps={buttonProps}
      title={' أنشاء كود خصم '}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="الرمز"
          name="code"
          rules={[{ required: true }]}
          required
        >
          <Input name="code" onChange={handleChange} />
        </Form.Item>

        <Form.Item
          label="الحد الاقصى للاستخدام"
          name="max_usage"
          rules={[{ required: true }]}
          required
        >
          <Input type="number" name="max_usage" onChange={handleChange} />
        </Form.Item>

        <Form.Item
          label="قيمة الخصم"
          name="discount"
          rules={[{ required: true }]}
          required
        >
          <Input type="number" name="discount" onChange={handleChange} />
        </Form.Item>

        <Form.Item
          label="نوع الخصم"
          name="discount_type"
          rules={[{ required: true }]}
          required
        >
          <Select
            options={[
              { label: 'نسبة', value: 0 },
              { label: 'قيمة', value: 1 },
            ]}
            onChange={(val) => setFieldValue('discount_type', val)}
          />
        </Form.Item>

        <Form.Item
          label="مدة الاشتراك"
          name="membership_duration"
          rules={[{ required: true }]}
          required
        >
          <Select
            options={[
              { label: 'شهري', value: 1 },
              { label: 'سنوي', value: 2 },
            ]}
            onChange={(val) => setFieldValue('membership_duration', val)}
          />
        </Form.Item>

        <Form.Item
          label="نوع الاشتراك"
          name="membership_type"
          rules={[{ required: true }]}
          required
        >
          <Select
            options={[
              { label: 'عادي', value: 1 },
              { label: 'مميز', value: 2 },
            ]}
            onChange={(val) => setFieldValue('membership_type', val)}
          />
        </Form.Item>

        <Form.Item
          label="تاريخ البدء"
          name="start_date"
          rules={[{ required: true }]}
          required
        >
          <input type="date" name="start_date" onChange={handleChange} />
          <input type="date" value="2013-01-08" style={{ display: 'none' }} />
        </Form.Item>

        <Form.Item
          label="تاريخ الانتهاء"
          name="end_date"
          rules={[{ required: true }]}
          required
        >
          <input type="date" name="end_date" onChange={handleChange} />
          <input type="date" value="2013-01-08" style={{ display: 'none' }} />
        </Form.Item>
      </Form>
    </Create>
  );
};
