import React from 'react';
import {
  List,
  Table,
  TextField,
  BooleanField,
  ExportButton,
} from '@pankod/refine-antd';
import { useNavigation, useTable } from '@pankod/refine-core';
import { Actions } from 'components/AdminPanel/ActionButtons';
import {
  DeleteRecordAction,
  ExportList,
} from '../../../Actions/ConfigsActions';
import { Search } from 'components/AdminPanel/Search';
import _ from 'lodash';
import dayjs from 'dayjs';

export const FeedbackListing: React.FC = () => {
  const [searchResults, setSearchResults] = React.useState([]);
  const { show, edit } = useNavigation();
  const { tableQueryResult } = useTable({
    syncWithLocation: true,
  });

  const reOrderByDate = _.sortBy(tableQueryResult.data?.data, (item) => {
    return item.created_at;
  }).reverse();

  const handleExportList = () => {
    ExportList(
      searchResults?.length > 0 ? searchResults : reOrderByDate || [],
      'Feedback'
    );
  };
  return (
    <List
      title={`${'التغذية راجعة'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="feedback"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
              data={reOrderByDate || []}
            />
            <ExportButton
              onClick={handleExportList}
              style={{ marginRight: 10 }}
            >
              {'تصدير'}
            </ExportButton>
          </div>
        ),
      }}
    >
      <Table
        dataSource={
          searchResults?.length > 0 ? searchResults : reOrderByDate || []
        }
        rowKey="id"
        style={{ cursor: 'pointer' }}
        onRow={(record) => {
          return {
            onClick: () => {
              record.id && show('feedback-cruds', record.id);
            },
          };
        }}
      >
        <Table.Column
          dataIndex={'id'}
          title={' معرف التغذية الراجعة'}
          align="center"
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex={'account_id'}
          title={'المستخدم'}
          align="center"
          render={(value) => (
            <TextField
              style={{ color: '#1890ff', cursor: 'pointer' }}
              className="icons"
              onClick={(e) => {
                e?.stopPropagation();
                show('account-cruds', value);
              }}
              value={value ? `المستخدم #${value}` : '-'}
            />
          )}
        />

        <Table.Column
          dataIndex={'feedback'}
          title={'التغذية راجعة'}
          align="center"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="created_at"
          title={'أنشئت في'}
          render={(value) => (
            <TextField value={dayjs(value).format('YYYY-MM-DD')} />
          )}
        />
        <Table.Column
          dataIndex={'is_deleted'}
          title={'محذوف'}
          align="center"
          render={(value) => <BooleanField value={value} />}
        />
        <Table.Column<any>
          title={'الإجراءات'}
          dataIndex="actions"
          align="center"
          render={(_text, record): any => {
            return (
              <Actions
                name_ar="التغذية راجعة"
                deleteRecord
                edit
                record={record}
                onClickEdit={() =>
                  record?.id && edit('feedback-cruds', record?.id)
                }
                onClickDelete={() =>
                  DeleteRecordAction(
                    `feedback-cruds/update/by-id/${record?.id}`,
                    tableQueryResult?.refetch,
                    record?.is_deleted
                  )
                }
              />
            );
          }}
        />
      </Table>
    </List>
  );
};
