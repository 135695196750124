import React from 'react';
import {
  Grid,
  ListButton,
  Show,
  Space,
  Typography,
  useTable,
} from '@pankod/refine-antd';
import { useNavigation, useShow } from '@pankod/refine-core';
import { Actions } from 'components/AdminPanel/ActionButtons';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { AccountStatus, AdminRole } from 'Actions/types';
import { useParams } from 'react-router-dom';

const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

function ShowAccountAdmin() {
  const screens = useBreakpoint();
  const { edit } = useNavigation();
  const params = useParams();

  const { queryResult } = useShow({
    resource: 'admin-cruds',
  });
  const admin = useTable({
    resource: 'account-cruds',
  });

  const adminRecord = admin?.tableQueryResult?.data?.data?.filter(
    (item) => item.id === params.id
  )[0];

  const { data, isLoading } = queryResult;

  const record = adminRecord ? { ...adminRecord, ...data?.data } : data?.data;

  return (
    <>
      <Show
        isLoading={isLoading}
        title={'تفاصيل المشرف '}
        pageHeaderProps={{
          extra: (
            <Space>
              <ListButton>
                <Text>{'المشرفين'}</Text>
              </ListButton>
              <Actions
                name_ar="المشرف"
                record={record}
                edit
                onClickEdit={() =>
                  record?.id && edit('admin-cruds', record?.account_id)
                }
              />
            </Space>
          ),
        }}
      >
        <div
          className="detailsWrapper"
          style={{ justifyContent: screens.md ? '' : 'space-between' }}
        >
          <Break breakPoint={!!screens.md}>
            <Title level={5}>{' معرف الحساب'}</Title>
            <Text>{record?.account_id}</Text>
            <Title level={5}>{'اسم المستخدم'}</Title>
            <Text>{record?.name}</Text>
            {/* 
            <Title level={5}>{'نوعية الحساب'}</Title>
            <Text>{AccountType[+record?.account_type]}</Text> */}

            {(record?.role === 0 || record?.role > 0) && (
              <>
                <Title level={5}>{'الدور'}</Title>
                <Text>{AdminRole[+record?.role]}</Text>
              </>
            )}

            <Title level={5}>{'البريد الالكتروني'}</Title>
            <Text>{record?.email || '-'}</Text>

            <Title level={5}>{'الحالة'}</Title>
            <Text>{AccountStatus[+record?.status]}</Text>
          </Break>

          <Break breakPoint={!!screens.md}>
            {record?.apple_id && (
              <>
                <Title level={5}>{'حساب ابل'}</Title>
                <Text>{record?.apple_id || '-'}</Text>
              </>
            )}

            {record?.facebook_id && (
              <>
                <Title level={5}>{'حساب فيس بوك'}</Title>
                <Text>{record?.facebook_id || '-'}</Text>
              </>
            )}

            {record?.google_id && (
              <>
                <Title level={5}>{'حساب جوجل'}</Title>
                <Text>{record?.google_id || '-'}</Text>
              </>
            )}

            {record?.twitter_id && (
              <>
                <Title level={5}>{'حساب تويتر'}</Title>
                <Text>{record?.twitter_id || '-'}</Text>
              </>
            )}

            {record?.last_login && (
              <>
                <Title level={5}>{'تاريخ آخر تسجيل دخول'}</Title>
                <Text>
                  {dayjs(record?.last_login).format('YYYY-MM-DD, HH:mm') || '-'}
                </Text>
              </>
            )}

            {record?.deletion_requested_at && (
              <>
                <Title level={5}>{'تم طلب الحذف في'}</Title>
                <Text>
                  {dayjs(record?.deletion_requested_at).format(
                    'YYYY-MM-DD, HH:mm'
                  ) || '-'}
                </Text>
              </>
            )}
            {record?.description && (
              <>
                <Title level={5}>{'الوصف'}</Title>
                <DescriptionWrapper
                  breakPoint={record?.description?.length > 50}
                >
                  <Text>{record?.description}</Text>
                </DescriptionWrapper>
              </>
            )}
          </Break>
          <Break breakPoint={!!screens.md}>
            <Title level={5}>{'أنشأ في'}</Title>
            <Text>{dayjs(record?.created_at).format('YYYY-MM-DD, HH:mm')}</Text>
          </Break>
        </div>
      </Show>
    </>
  );
}

export default ShowAccountAdmin;

const Break = styled.div<{ breakPoint?: boolean }>`
  margin: ${(props) => (props.breakPoint ? '' : '20px')};
`;
const DescriptionWrapper = styled.div<{ breakPoint?: boolean }>`
  width: ${(props) => (props.breakPoint ? '400px' : '200px')};
`;
