import React, { useEffect } from 'react';
import {
  CreateButton,
  ExportButton,
  List,
  Table,
  TextField,
  // CreateButton,
} from '@pankod/refine-antd';
import { useNavigation, useTable } from '@pankod/refine-core';
import { Actions } from 'components/AdminPanel/ActionButtons';
import { ExportList } from '../../../Actions/ConfigsActions';
import { AccountStatus, AdminRole } from 'Actions/types';
import { Search } from 'components/AdminPanel/Search';
import _ from 'lodash';

export const AdminListing: React.FC = () => {
  const { show, edit } = useNavigation();
  const [searchResults, setSearchResults] = React.useState([]);
  const [mergedAdmins, setMergedAdmins] = React.useState<any[]>([]);
  const { tableQueryResult } = useTable({
    syncWithLocation: true,
    initialPageSize: 1000000,
  });
  const admins = useTable({
    resource: 'account-cruds',
    initialPageSize: 1000000,
  });
  useEffect(() => {
    if (tableQueryResult.data?.data && admins?.tableQueryResult?.data?.data) {
      const merged: React.SetStateAction<any[]> = [];
      admins?.tableQueryResult?.data?.data?.filter((item) => {
        return tableQueryResult?.data?.data?.filter((admin) => {
          if (item.id === admin.account_id) {
            return merged.push({
              ...item,
              ...admin,
            });
          } else {
            return null;
          }
        });
      });
      const reOrderByDate = _.sortBy(merged, (item) => {
        return item.created_at;
      }).reverse();
      return setMergedAdmins(reOrderByDate);
    }
  }, [admins?.tableQueryResult?.data?.data, tableQueryResult.data?.data]);

  const handleExportList = () => {
    ExportList(
      searchResults?.length > 0 ? searchResults : mergedAdmins || [],
      'Admins'
    );
  };

  return (
    <List
      title={`${'المشرفين'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="account"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
              data={mergedAdmins || []}
            />
            <ExportButton
              onClick={handleExportList}
              style={{ marginRight: 10 }}
            >
              {'تصدير'}
            </ExportButton>
            <CreateButton style={{ marginRight: 10 }}>إنشاء</CreateButton>
          </div>
        ),
      }}
    >
      <Table
        dataSource={
          searchResults?.length > 0 ? searchResults : mergedAdmins || []
        }
        rowKey="id"
        style={{ cursor: 'pointer' }}
        onRow={(record) => {
          return {
            onClick: () => {
              record.id && show('admin-cruds', record.id);
            },
          };
        }}
      >
        <Table.Column
          dataIndex="account_id"
          title={'معرف الحساب'}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="name"
          title={'الاسم'}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={'email'}
          title={'البريد الالكتروني'}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={'status'}
          title={'الحالة'}
          render={(value) => <TextField value={AccountStatus[+value]} />}
        />
        <Table.Column
          dataIndex={'role'}
          title={'الدور'}
          render={(value) => <TextField value={AdminRole[+value]} />}
        />
        <Table.Column<any>
          title={'الإجراءات'}
          dataIndex="actions"
          align="center"
          render={(_text, record): any => {
            return (
              <Actions
                name_ar="المشرف"
                edit
                record={record}
                onClickEdit={() =>
                  record?.id && edit('admin-cruds', record?.id)
                }
              />
            );
          }}
        />
      </Table>
    </List>
  );
};
