import React from 'react';
import {
  List,
  Table,
  TextField,
  ExportButton,
  CreateButton,
  BooleanField,
} from '@pankod/refine-antd';
import { useNavigation, useTable } from '@pankod/refine-core';
import { Actions } from 'components/AdminPanel/ActionButtons';
import {
  MembershipType,
  Membership_Duration,
  PROMO_CODE_DISCOUNT_TYPE_ENUM,
} from 'Actions/types';
import dayjs from 'dayjs';
import { DeleteRecordAction, ExportList } from 'Actions/ConfigsActions';
import { Search } from 'components/AdminPanel/Search';
import _ from 'lodash';

export const PromoListing: React.FC = () => {
  const [searchResults, setSearchResults] = React.useState([]);
  const { show, edit } = useNavigation();
  const { tableQueryResult } = useTable({
    syncWithLocation: true,
  });

  const reOrderByDate = _.sortBy(tableQueryResult.data?.data, (item) => {
    return item.created_at;
  }).reverse();

  const handleExportList = () => {
    ExportList(
      searchResults?.length > 0 ? searchResults : reOrderByDate || [],
      'Promo Codes'
    );
  };

  return (
    <List
      title={`${'الرموز الترويجية'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="promo-code"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
              data={reOrderByDate || []}
            />
            <ExportButton
              onClick={handleExportList}
              style={{ marginRight: 10 }}
            >
              {'تصدير'}
            </ExportButton>
            <CreateButton style={{ marginRight: 10 }}>
              {'إضافة رمز جديد'}
            </CreateButton>
          </div>
        ),
      }}
    >
      <Table
        {...tableQueryResult}
        dataSource={
          searchResults?.length > 0 ? searchResults : reOrderByDate || []
        }
        rowKey="id"
        style={{ cursor: 'pointer' }}
        onRow={(record) => {
          return {
            onClick: () => {
              record.id && show('promo-code-cruds', record.id);
            },
          };
        }}
      >
        <Table.Column
          dataIndex={'code'}
          title={'الرمز'}
          render={(value) => <TextField value={value || '-'} />}
        />
        {/* <Table.Column
          dataIndex={'code_type'}
          title={'نوع الرمز'}
          render={(value) => (
            <TextField value={PROMO_CODE_TYPE_ENUM[+value] || '-'} />
          )}
        /> */}
        <Table.Column
          dataIndex="discount"
          title={'الخصم'}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={'discount_type'}
          title={'نوع الخصم'}
          align="center"
          render={(value) => (
            <TextField value={PROMO_CODE_DISCOUNT_TYPE_ENUM[+value] || '-'} />
          )}
        />
        <Table.Column
          dataIndex="max_usage"
          title={'الحد الاقصى للاستخدام'}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={'usage'}
          title={'الاستخدام'}
          align="center"
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex={'membership_duration'}
          title={'مدة الاشتراك'}
          align="center"
          render={(value) => <TextField value={Membership_Duration[+value]} />}
        />

        <Table.Column
          dataIndex={'membership_type'}
          title={'نوع الاشتراك'}
          align="center"
          render={(value) => <TextField value={MembershipType[+value]} />}
        />
        <Table.Column
          dataIndex={'start_date'}
          title={'تاريخ البدء'}
          render={(value) => (
            <TextField value={dayjs(value).format('YYYY-MM-DD, HH:mm')} />
          )}
        />
        <Table.Column
          dataIndex={'end_date'}
          title={'تاريخ الانتهاء'}
          render={(value) => (
            <TextField value={dayjs(value).format('YYYY-MM-DD, HH:mm')} />
          )}
        />
        <Table.Column
          dataIndex={'is_deleted'}
          title={'الحالة'}
          render={(value) => <BooleanField value={value} />}
        />
        <Table.Column<any>
          title={'الإجراءات'}
          dataIndex="actions"
          align="center"
          render={(_text, record): any => {
            return (
              <Actions
                name_ar="الحساب"
                deleteRecord
                edit
                record={record}
                onClickEdit={() =>
                  record?.id && edit('promo-code-cruds', record?.id)
                }
                onClickDelete={() =>
                  DeleteRecordAction(
                    `promo-code-cruds/update/by-id/${record?.id}`,
                    tableQueryResult?.refetch,
                    record?.is_deleted,
                    { is_deleted: !record?.is_deleted }
                  )
                }
              />
            );
          }}
        />
      </Table>
    </List>
  );
};
