import React from 'react';
import '../ForgotPassword//styles.css';
import logo from '../../../../images/logo.svg';
import {
  Typography,
  AntdLayout,
  Row,
  Col,
  Card,
  Input,
  Button,
  Form,
} from '@pankod/refine-antd';
import { colors } from 'Theme/Colors';
import { ResetPasswordAction } from 'Actions';
import styled from 'styled-components';

const { Title } = Typography;

export const ResetPassword: React.FC = () => {
  const navigateToLogin = () => {
    window.location.replace('/login');
  };

  const CardTitle = (
    <Title level={3} className="layout-title">
      {'إعادة تعيين كلمة السر'}
    </Title>
  );
  return (
    <AntdLayout className="antdLayout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div
            style={{
              maxWidth: '408px',
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Image src={logo} width="80" alt="logo" />

            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form
                layout="vertical"
                color={colors.primary}
                onFinish={(val) => {
                  ResetPasswordAction(val?.code, navigateToLogin);
                }}
                requiredMark={false}
              >
                <Form.Item
                  name="code"
                  label={'كلمة السر الجديدة'}
                  rules={[
                    {
                      validator: async (_, password) => {
                        if (password.length < 8) {
                          return Promise.reject(
                            new Error('كلمة السر يجب أن تكون أكثر من 8 أحرف')
                          );
                        }
                      },
                    },
                  ]}
                >
                  <Input type="password" size="large" placeholder="●●●●●" />
                </Form.Item>

                <Button
                  type="default"
                  size="large"
                  htmlType="submit"
                  block
                  color={colors.primary}
                  style={{ background: colors.primary }}
                >
                  <p
                    style={{
                      color: '#fff',
                      fontSize: 17,
                      borderColor: colors.primary,
                    }}
                  >
                    {'إرسال'}
                  </p>
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};

export const Image = styled.img`
  margin-bottom: 26px;
  align-self: center;
`;
