import { IResourceComponentsProps, useNavigation } from '@pankod/refine-core';
import React from 'react';
import {
  Form,
  Input,
  Create,
  ListButton,
  Typography,
  Select,
  notification,
} from '@pankod/refine-antd';
import { AdminRole } from 'Actions/types';
import { AccountStore } from 'Actions/Stores/Account';
import { ConfigsStore } from 'Actions/Stores/Configs';
import { apiRequest } from 'Util/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
const { Text } = Typography;

export const CreateAdmin: React.FC<IResourceComponentsProps> = () => {
  const { goBack } = useNavigation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('مطلوب'),
    email: Yup.string().required('يرجى ادخال بريد الكتروني صحيح'),
    role: Yup.number().required('يرجى اختيار دور'),
    password: Yup.string().required('يرجى ادخال كلمة سر صحيحة'),
  });

  const { setFieldValue, handleChange, handleSubmit } = useFormik({
    initialValues: {
      account_type: 0,
      name: '',
      email: '',
      photo: '',
      password: '',
      role: +'',
    },
    validationSchema,
    onSubmit: async (submittedValues) => {
      const inputs: {
        account_type?: number;
        password?: string;
        name?: string;
        email?: string;
        photo?: string;
        role?: number | string;
      } = {
        account_type: 0,
        name: submittedValues.name,
        email: submittedValues.email,
        password: submittedValues.password,
        role: submittedValues.role || 0,
      };

      if (submittedValues.photo) {
        inputs.photo = submittedValues.photo;
      }

      if (Object.keys(inputs)?.length > 0) {
        try {
          await apiRequest<boolean>({
            url: `/admin/create`,
            method: 'POST',
            data: inputs,
            headers: {
              device_id: await ConfigsStore.get('device_id'),
              authorization: `Bearer ${
                (
                  await AccountStore.get('account')
                )?.access_token
              }`,
            },
          });
          submittedValues.photo && window.location.reload();
          goBack();
          notification.success({
            message: 'تم بنجاح',
            description: 'تم إنشاء المشرف بنجاح',
          });
        } catch (error: any) {
          // if (error?.message.includes('401')) {
          //   RefreshToken();
          // } else {
          notification.error({
            message: 'خطأ',
            description: 'فشل انشاء المشرف',
          });
        }
      }
    },
  });

  const buttonProps = {
    disables: false,
    loading: false,
    onClick: () => handleSubmit(),
  };

  return (
    <Create
      saveButtonProps={buttonProps}
      title={'إنشاء'}
      pageHeaderProps={{
        extra: (
          <>
            <ListButton>
              <Text>{'المشرفين'}</Text>
            </ListButton>
          </>
        ),
      }}
    >
      <Form layout="vertical">
        <Form.Item label="اسم المشرف" name="name" rules={[{ required: true }]}>
          <Input
            name="name"
            required
            onChange={handleChange}
            placeholder={' اسم المشرف'}
          />
        </Form.Item>
        <Form.Item
          label="البريد الالكتروني"
          name="email"
          rules={[
            {
              required: true,
              validator: async (_, email) => {
                const regex =
                  // eslint-disable-next-line no-useless-escape
                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                if (!regex.test(email)) {
                  return Promise.reject(
                    new Error('البريد الالكتروني غير صحيح')
                  );
                }
              },
            },
          ]}
        >
          <Input
            name="email"
            onChange={handleChange}
            required
            placeholder={' ادخل البريد الالكتروني'}
          />
        </Form.Item>
        <Form.Item
          label={'الدور'}
          name={'role'}
          required
          rules={[
            {
              validator: async (_, type) => {
                if (isNaN(type)) {
                  return Promise.reject(new Error('يرجى اختيار  الدور'));
                }
              },
            },
          ]}
        >
          <Select
            placeholder={'اختر الدور...'}
            options={[
              { label: AdminRole[0], value: 0 },
              { label: AdminRole[1], value: 1 },
              // { label: ADMIN_ENUM[2], value: ADMINS_ROLES.data_entry },
            ]}
            defaultValue={0}
            onChange={(value) => setFieldValue('role', value)}
          />
        </Form.Item>
        <Form.Item
          label={'كلمة السر'}
          name="password"
          required
          rules={[
            {
              validator: async (_, password) => {
                if (password.length < 8) {
                  return Promise.reject(
                    new Error('يجب أن تتكون كلمة السر من 8 أحرف على الأقل')
                  );
                }
              },
            },
          ]}
        >
          <Input
            type="password"
            placeholder="●●●●●●●●"
            name="password"
            required
            onChange={handleChange}
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
