import React from 'react';
import { useFormik } from 'formik';
import { Modal, Form, Select, useSelect } from '@pankod/refine-antd';
import { EditAccountAction } from 'Actions';

interface Props {
  visible: any;
  setVisible: (val: any) => void;
  redirect?: string;
  info?: any;
  refresh?: () => void;
}

const MealSubstitutesModal = ({
  visible,
  setVisible,
  info,
  refresh,
}: Props) => {
  const { selectProps } = useSelect({
    resource: `profile_meal/available-substitutes/${info?.id}`,
    optionLabel: 'name_ar',
    optionValue: 'id',
  });

  const { setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      profile_meal_id: '',
      meal_id: '',
    },
    onSubmit: async (submittedValues) => {
      EditAccountAction(
        `profile_meal/update/by-id`,
        info?.id,
        {
          meal_id: submittedValues.meal_id,
        },
        refresh
      );
      setVisible('');
    },
  });

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible('')}
      width={500}
      okText={'إرسال'}
      cancelText={'إلغاء'}
      onOk={() => handleSubmit()}
    >
      <Form layout="vertical" style={{ marginTop: 25 }}>
        <Form.Item label="الوجبات البديلة" rules={[{ required: true }]}>
          <Select
            placeholder="اختر الوجبة البديلة"
            {...selectProps}
            onChange={(val) => setFieldValue('meal_id', val)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MealSubstitutesModal;
