import * as React from 'react';
import { useForm, Create, Form, Input, Select } from '@pankod/refine-antd';
import { IngredientsType } from 'Actions/types';
import { errorMessage } from 'Util/ErrorMessage';
import { useFormik } from 'formik';
import { CreateAction } from 'Actions';
import { useNavigation } from '@pankod/refine-core';
import * as Yup from 'yup';

export const CreateIngredients = () => {
  const navigate = useNavigation();
  const validationSchema = Yup.object().shape({
    name_ar: Yup.string().required('مطلوب'),
    type: Yup.number().required('يرجى ادخال نوع الوجبه'),
  });
  const { formProps, saveButtonProps, queryResult } = useForm();
  const ingredientTypes = IngredientsType?.map((item, key) => ({
    label: item,
    value: key,
  }));

  const { errors, values, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        name_ar: '',
        type: '',
        calories: 0,
        protein: 0,
        carbohydrates: 0,
        fat: 0,
      },
      validationSchema,

      onSubmit: async (submittedValues) => {
        const inputs = {
          name_ar: submittedValues.name_ar,
          type: submittedValues.type,
          calories: +submittedValues.calories,
          protein: +submittedValues.protein,
          carbohydrates: +submittedValues.carbohydrates,
          fat: +submittedValues.fat,
          name_en: submittedValues.name_ar,
        };
        if (Object.keys(inputs)?.length > 0) {
          CreateAction('ingredients-cruds', inputs, undefined, navigate.goBack);
        }
      },
    });

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  };

  return (
    <Create
      isLoading={queryResult?.isFetching}
      saveButtonProps={buttonProps}
      title={'إنشاء مكون'}
    >
      <Form {...formProps} layout="vertical" initialValues={{ isActive: true }}>
        <Form {...formProps} layout="vertical">
          <Form.Item
            label=" اسم المكون"
            name="name_ar"
            rules={errorMessage()}
            required
          >
            <Input
              name="name_ar"
              onChange={handleChange}
              value={values.name_ar}
            />
          </Form.Item>

          <Form.Item
            label="نوع المكون"
            name={'type'}
            required
            rules={[
              {
                validator: async (_, type) => {
                  if (errors.type) {
                    return Promise.reject(new Error(' يرجى ادخال نوع المكون'));
                  }
                },
              },
            ]}
          >
            <Select
              options={ingredientTypes}
              onChange={(value) => setFieldValue('type', value)}
              value={values.type}
            />
          </Form.Item>

          <Form.Item
            label="سعرات حراريه"
            name="calories"
            rules={errorMessage()}
            required
          >
            <Input
              name="calories"
              onChange={handleChange}
              value={values.calories}
              type={'number'}
            />
          </Form.Item>
          <Form.Item
            label=" بروتين"
            name="protein"
            rules={errorMessage()}
            required
          >
            <Input
              name="protein"
              onChange={handleChange}
              value={values.protein}
              type={'number'}
            />
          </Form.Item>
          <Form.Item
            label=" الكربوهيدرات"
            name="carbohydrates"
            rules={errorMessage()}
            required
          >
            <Input
              name="carbohydrates"
              onChange={handleChange}
              value={values.carbohydrates}
              type={'number'}
            />
          </Form.Item>
          <Form.Item label="دهون" name="fat" rules={errorMessage()} required>
            <Input
              name="fat"
              onChange={handleChange}
              value={values.fat}
              type={'number'}
            />
          </Form.Item>
        </Form>
      </Form>
    </Create>
  );
};
