import { IResourceComponentsProps, useGetIdentity } from '@pankod/refine-core';
import React, { useEffect } from 'react';
import {
  useForm,
  Form,
  Input,
  Edit,
  ListButton,
  Typography,
  ShowButton,
  Select,
  notification,
  useTable,
} from '@pankod/refine-antd';
import { useFormik } from 'formik';
import { EditAccountAction } from 'Actions';
import { Profile_Gender } from 'Actions/types';
import { useParams } from '@pankod/refine-react-router-v6';
const { Text } = Typography;

interface Inputs {
  name?: string;
  email?: string;
  is_email_confirmed?: boolean | string;
  status?: number | string;

  weight?: string | number;
  height?: string | number;
  gender?: string | number;
}

export const EditAccount: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();
  const params = useParams();
  const { formProps, saveButtonProps, queryResult } = useForm<any>();
  const profileCruds = useTable({
    resource: 'profile-cruds',
    initialPageSize: 100000000,
  });

  const record = queryResult?.data?.data;

  const profileRecord = profileCruds?.tableQueryResult?.data?.data?.filter(
    (item) => {
      return item?.account_id === params?.id;
    }
  )[0];

  const { values, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      name: '',
      email: '',
      is_email_confirmed: '',
      status: +'',

      weight: '',
      height: '',
      gender: '',
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {};
      const profileInputs: Inputs = {};

      if (submittedValues.name && submittedValues.name !== record.name) {
        inputs.name = submittedValues.name;
      }
      if (submittedValues.email && submittedValues.email !== record.email) {
        inputs.email = submittedValues.email;
      }

      if (
        // @ts-ignore
        submittedValues.status !== '' &&
        submittedValues.status !== record.status
      ) {
        inputs.status = submittedValues.status;
      }

      if (
        submittedValues.height !== '' &&
        submittedValues.height !== record.height
      ) {
        profileInputs.height = +submittedValues.height;
      }
      if (
        submittedValues.weight !== '' &&
        submittedValues.weight !== record.weight
      ) {
        profileInputs.weight = +submittedValues.weight;
      }
      if (
        submittedValues.gender !== '' &&
        submittedValues.gender !== record.gender
      ) {
        profileInputs.gender = +submittedValues.gender;
      }

      if (Object.keys(inputs)?.length > 0) {
        EditAccountAction(
          'account-cruds/update/by-id',
          record.id,
          inputs,
          queryResult?.refetch,
          false,
          undefined,
          true
        );
      }

      if (Object.keys(profileInputs)?.length > 0) {
        EditAccountAction(
          'profile-cruds/update/by-id',
          record.id,
          profileInputs,
          queryResult?.refetch,
          false,
          undefined,
          true
        );
      }

      notification.success({
        message: 'تم بنجاح',
        description: 'تمت العملية بنجاح',
      });
    },
  });

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  };

  const Profile_gender = Profile_Gender?.map((item, key) => ({
    label: item,
    value: key,
  }));

  useEffect(() => {
    if (record) {
      setFieldValue('name', record?.name || '');
      setFieldValue('email', record?.email || '');
      setFieldValue('status', record?.status || '');
    }
    if (profileRecord) {
      setFieldValue('height', profileRecord?.height || '');
      setFieldValue('weight', profileRecord?.weight || '');
      setFieldValue('gender', profileRecord?.gender || '');
    }
  }, [profileRecord, record, setFieldValue]);

  return (
    <Edit
      saveButtonProps={buttonProps}
      title={'تعديل المستخدم'}
      pageHeaderProps={{
        extra: (
          <>
            <ListButton>
              <Text>{'المستخدمين'}</Text>
            </ListButton>
            <ShowButton>
              <Text>{'التفاصيل'}</Text>
            </ShowButton>
          </>
        ),
      }}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="اسم المستخدم"
          name="name"
          rules={[{ required: true }]}
        >
          <Input
            name="name"
            onChange={handleChange}
            disabled={user?.account_type === 2}
          />
        </Form.Item>
        <Form.Item
          label="البريد الالكتروني"
          name="email"
          rules={[
            {
              validator: async (_, email) => {
                const regex =
                  // eslint-disable-next-line no-useless-escape
                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                if (!regex.test(email)) {
                  return Promise.reject(
                    new Error('البريد الالكتروني غير صحيح')
                  );
                }
              },
            },
          ]}
        >
          <Input
            name="email"
            onChange={handleChange}
            disabled={user?.account_type === 2}
          />
        </Form.Item>
        <Form.Item label="الحالة" name="status">
          <Select
            options={[
              { label: 'فعال', value: 0 },
              { label: 'غير فعال', value: 1 },
              { label: 'محذوف', value: 2 },
            ]}
            defaultValue={record?.status}
            disabled={user?.account_type === 2}
            onChange={(val) => setFieldValue('status', val)}
          />
        </Form.Item>
      </Form>
      <Form layout="vertical">
        <Form.Item label="الوزن" rules={[{ required: true }]} required>
          <Input
            name="weight"
            onChange={handleChange}
            disabled={user?.account_type === 2 || values?.weight === ''}
            value={values?.weight}
          />
        </Form.Item>
        <Form.Item label="الطول" rules={[{ required: true }]} required>
          <Input
            name="height"
            onChange={handleChange}
            disabled={user?.account_type === 2 || values?.height === ''}
            value={values?.height}
          />
        </Form.Item>
        <Form.Item label=" الجنس " rules={[{ required: true }]} required>
          <Select
            value={values?.gender}
            options={Profile_gender}
            disabled={user?.account_type === 2 || values?.gender === ''}
            onChange={(val) => setFieldValue('gender', val)}
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
