import React from 'react';
import {
  Container,
  HomeButton,
  Img,
  LogoContainer,
  OuterWrapper,
  PageTitle,
  TermsText,
  TermsTitleText,
  Wrapper,
} from './styles';
import Logo from '../images/logo.svg';
import { useNavigation, useTranslate } from '@pankod/refine-core';

export default function Terms() {
  const { push } = useNavigation();
  const t = useTranslate();

  return (
    <>
      <Container>
        <OuterWrapper>
          <Wrapper>
            <LogoContainer>
              <Img>
                <img src={Logo} alt="" height={70} width={70} />
              </Img>
              <HomeButton onClick={() => push('/')}>
                {t('Terms.Home')}
              </HomeButton>
            </LogoContainer>
            <PageTitle>{t('Terms.Terms and conditions')}</PageTitle>
            <TermsTitleText marginTop>
              {t('Terms.- Who can use Saleem?')}
            </TermsTitleText>
            <TermsText>
              {t(
                'Terms.To the best of our knowledge and ability, we believe that Saleem application uses the safest and best evidence-based approach for weight management which is the balanced diet plan”.'
              )}
            </TermsText>
            <TermsText>
              {t(
                'Terms.Saleem application is designed for people aged 18 years old and older. The application is not designed for those who have chronic diseases such as diabetes, hypertension, and kidney diseases.Those people need to consult their medical doctor.'
              )}
            </TermsText>
            <TermsText>
              {t(
                'Terms.However, we strongly advise getting a consultation from a medical doctor if their suspected symptoms such as general weakness,tiredness, and headache.'
              )}
            </TermsText>
            <TermsText>
              {t(
                'Terms.People who have certain medical conditions such as diabetes, hypertension, and kidney diseases will be advised to leave the application and contact their medical doctors.'
              )}
            </TermsText>
            <TermsText>
              {t(
                'Moreover, our diet plan will contain all necessary information which includes: ingredients, quantities, cooking methods, and how many calories it contains. Also, users can contact an expert from our team if they need extra help.'
              )}
            </TermsText>
            <TermsText>
              {t(
                'Terms.Therefore, we are not responsible for any complication that happens to the user.'
              )}
            </TermsText>

            <TermsTitleText marginTop>
              {t('كيفية حذف حساب سليم الخاص')}
            </TermsTitleText>

            <TermsText>
              {t(
                'يستطيع المستخدمون التحكم في بياناتهم حيث يسمح تطبيق سليم الخاص بخاصية حذف حساب المستخدم من خلال معلومات واضحة وخيارات متعددة لحذف الحساب، إما من خلال إعدادات التطبيق أو دعم العملاء. عند تأكيد المستخدم، نقوم بحذف جميع البيانات الشخصية المرتبطة بالحساب بشكل آمن ونسخ البيانات احتياطيًا بانتظام لفترة احتفاظ محددة. نقوم بتحديث سياسة الخصوصية الخاصة بنا بشكل دوري ونوفر خدمة دعم العملاء للاستعلام عن حذف الحساب.'
              )}
            </TermsText>
            <TermsText>
              <a
                href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
                target="_blank"
                rel="noreferrer"
                style={{ color: '#fff', textDecoration: 'underline' }}
              >
                شروط الاستخدام (EULA)
              </a>
            </TermsText>
          </Wrapper>
        </OuterWrapper>
      </Container>
    </>
  );
}
