import React from 'react';
import {
  PrivacyContainer,
  HomeButton,
  Img,
  LogoContainer,
  OuterWrapper,
  PageTitle,
  TermsText,
  TermsTitleText,
  Wrapper,
} from './styles';
import Logo from '../images/logo.svg';
import { useNavigation, useTranslate } from '@pankod/refine-core';

export default function Privacy() {
  const { push } = useNavigation();
  const t = useTranslate();
  return (
    <PrivacyContainer>
      <OuterWrapper>
        <Wrapper>
          <LogoContainer>
            <Img>
              <img src={Logo} alt="" height={70} width={70} />
            </Img>
            <HomeButton onClick={() => push('/')}>
              {t('Privacy.Home')}
            </HomeButton>
          </LogoContainer>
          <PageTitle>{t('Privacy.Privacy Policy')}</PageTitle>
          <TermsText>
            {t(
              'Privacy.The information we collect from you such as height, weight, and age will be used to give you the best diet plan and service. We will do our best to protect your personal and credit card information. We will use your data to give you the right messages that help you to reach your goal.'
            )}
          </TermsText>

          <TermsTitleText marginTop>
            {t('Privacy.- Refund policy')}
          </TermsTitleText>

          <TermsText>
            {t(
              'Privacy.As we provide you with a 2-week free trial, we believe that any user who subscribes after that are satisfied with the service. Therefore, there is no refund policy. You can contact us through our support line for any issue.'
            )}
          </TermsText>

          <TermsTitleText marginTop>
            {t('Privacy.- Payment and Cancellation policy:')}
          </TermsTitleText>

          <TermsText>
            {t(
              'Privacy.After you finish the free trial, the application will ask you to pay for either a monthly or yearly membership by providing your card information. The application will notify you when the membership ends. However, the payment will be automatically renewed after your membership finishes.'
            )}
          </TermsText>
          <TermsText>
            {t(
              'Privacy.You can cancel your membership anytime. Once you cancel it, you will not be charged again after finishing your current membership.'
            )}
          </TermsText>
        </Wrapper>
      </OuterWrapper>
    </PrivacyContainer>
  );
}
