import styled from 'styled-components';
export const ContentWrapper = styled.div``;
export const Title = styled.h1`
  color: #ffffff;
  font-family: 'Extra Bold';
  line-height: 34px;
  font-size: 18px;
  margin-bottom: 37px;
`;
export const ContentInside = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  transition: 0.5s;
  @media (max-width: 675px) {
    display: flex;
    align-items: baseline;
  }
  @media (max-width: 1165px) {
    max-width: 460px;
  }
  &:hover {
    div > div {
      background-color: #ffffff;
      padding: 7px;
      transition: 0.2s;
    }
    p {
      font-size: 15px;
    }
  }
  div > div {
    background-color: ${(props) => (props.active ? '#ffffff' : '#bfe5dc')};
    padding: ${(props) => (props.active ? '7px' : '5px')};
    transition: 0.2s;
  }
  p {
    font-size: ${(props) => (props.active ? '15px' : '14px')};
  }
`;

export const Circle = styled.div`
  background-color: #99d6c8;
  padding: 4px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CircleInside = styled.div`
  background-color: #bfe5dc;
  padding: 5px;
  border-radius: 50%;
`;
export const Description = styled.p`
  color: #eaf6f4;
  font-family: 'Regular';
  line-height: 27px;
  font-size: 14px;
  padding-right: 17px;
  padding-top: 10px;
`;
