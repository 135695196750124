import React from 'react';
import {
  CreateButton,
  Grid,
  List,
  ListButton,
  Show,
  ShowButton,
  Space,
  Table,
  TextField,
  Typography,
} from '@pankod/refine-antd';
import { useGetIdentity, useNavigation, useShow } from '@pankod/refine-core';
import { Actions } from 'components/AdminPanel/ActionButtons';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { IngredientsType } from 'Actions/types';
import AddToCategory from 'components/AdminPanel/AddToCategory';
import { BsTrash } from 'react-icons/bs';
import { RemoveRecordAction } from 'Actions';

const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

function ShowCategoryIngredients() {
  const [addIngredientModal, setAddIngredientModal] = React.useState<any>();
  const { data: user } = useGetIdentity();
  const screens = useBreakpoint();
  const { edit, show } = useNavigation();

  const { queryResult } = useShow({});
  const { data, isLoading, refetch } = queryResult;

  const record = data?.data[0];

  return (
    <>
      <Show
        isLoading={isLoading}
        title={'تفاصيل التصنيف'}
        pageHeaderProps={{
          extra: (
            <Space>
              <ListButton>
                <Text>{'تصنيف المكونات'}</Text>
              </ListButton>
              {user?.account_type !== 2 && (
                <Actions
                  name_ar="المكون"
                  edit
                  record={record}
                  onClickEdit={() =>
                    record?.id && edit('ingredient-category', record?.id)
                  }
                />
              )}
            </Space>
          ),
        }}
      >
        <div
          className="detailsWrapper"
          style={{ justifyContent: screens.md ? '' : 'space-between' }}
        >
          <Break breakPoint={!!screens.md}>
            <Title level={5}>{'معرف التصنيف'}</Title>
            <Text>{record?.id}</Text>
            <Title level={5}>{'اسم التصنيف'}</Title>
            <Text>{record?.name_ar}</Text>
          </Break>
          <Break breakPoint={!!screens.md}>
            <Title level={5}>{'أنشأ في'}</Title>
            <Text>{dayjs(record?.created_at).format('YYYY-MM-DD, HH:mm')}</Text>
          </Break>
        </div>
      </Show>

      <div style={{ margin: '20px' }} />

      <List
        title={`${'المكونات'}`}
        pageHeaderProps={{
          extra: (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <CreateButton onClick={() => setAddIngredientModal(record)}>
                <Text>{'إضافة مكون'}</Text>
              </CreateButton>
            </div>
          ),
        }}
      >
        <Table dataSource={record?.ingredient_category_ingredient} rowKey="id">
          <Table.Column
            dataIndex={['ingredient', 'id']}
            title={' معرف المكون'}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex={['ingredient', 'name_ar']}
            title={'اسم المكون'}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex={['ingredient', 'type']}
            title={'نوع المكون'}
            render={(value) => <TextField value={IngredientsType[+value]} />}
          />
          <Table.Column
            dataIndex={['ingredient', 'protein']}
            title={' بروتين'}
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            dataIndex={['ingredient', 'carbohydrates']}
            title={'الكربوهيدرات'}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex={['ingredient', 'calories']}
            title={'السعرات الحرارية'}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex={['ingredient', 'fat']}
            title={'دهون'}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column<any>
            title={'الإجراءات'}
            dataIndex="actions"
            align="center"
            render={(_text, rec): any => {
              return (
                <Space>
                  <ShowButton
                    hideText
                    onClick={() => show('ingredients-cruds', rec.id)}
                  />
                  <BsTrash
                    style={{ marginLeft: 3, marginTop: 5 }}
                    size={18}
                    color={'red'}
                    className="icons"
                    onClick={() => {
                      RemoveRecordAction(
                        'ingredient-category-ingredient',
                        rec.id,
                        refetch
                      );
                    }}
                  />
                </Space>
              );
            }}
          />
        </Table>
      </List>
      {addIngredientModal && (
        <AddToCategory
          visible={addIngredientModal}
          setVisible={setAddIngredientModal}
          info={addIngredientModal}
          existingIngredients={record?.ingredient_category_ingredient.map(
            (item: any) => item.ingredient_id
          )}
          refresh={refetch}
        />
      )}
    </>
  );
}

export default ShowCategoryIngredients;

const Break = styled.div<{ breakPoint?: boolean }>`
  margin: ${(props) => (props.breakPoint ? '' : '20px')};
`;
