export const domain = (() => {
  return window.location.host.includes('localhost:3000') ||
    window.location.host.includes('saleem.staging.wtmsrv.com')
    ? 'https://saleem.io:6500'
    : `https://saleem.io:6500`;
})();

export const API_URL = `${domain}/api`;
export const API_KEY = 'U0FMRUVNQVBJS0VZR1VBUkQyMDIy';
export const SOCKET_URI =
  window.location.host.includes('localhost:3000') ||
  window.location.host.includes('saleem.staging.wtmsrv.com')
    ? 'wss://api.saleem-ws.staging.wtmsrv.com/messaging'
    : 'wss://ws.saleem.io/messaging';

export const POST_UPLOAD_URI = `${API_URL}/upload`;
export const GET_UPLOAD_URI = `${POST_UPLOAD_URI}/image-by-id`;
