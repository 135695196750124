import React, { useEffect } from 'react';
import {
  ExportButton,
  List,
  Table,
  TextField,
  useTable,
  // CreateButton,
} from '@pankod/refine-antd';
import {
  CrudFilters,
  useGetIdentity,
  useNavigation,
} from '@pankod/refine-core';
import { Actions } from 'components/AdminPanel/ActionButtons';
import { ExportList } from '../../../Actions/ConfigsActions';
import { Search } from 'components/AdminPanel/Search';
import _ from 'lodash';
import { MembershipType, Membership_Duration } from 'Actions/types';
import { ConfigsStore } from 'Actions/Stores/Configs';
import dayjs from 'dayjs';
import DropDownFilter from 'components/DropDownFilter';

export const AccountListing: React.FC = () => {
  const [searchResults, setSearchResults] = React.useState([]);
  const [memberships, setMemberships] = React.useState<any>([]);

  const { show, edit } = useNavigation();
  const { data: user } = useGetIdentity();
  const { tableQueryResult, tableProps } = useTable({
    syncWithLocation: true,
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      return filters;
    },
  });
  const membership = async () => {
    return setMemberships(await ConfigsStore.get('memberships'));
  };

  const reOrderByDate = _.sortBy(tableQueryResult.data?.data, (item) => {
    return item.created_at;
  })
    .filter((item) => item?.account_type === 1)
    .reverse();

  const handleExportList = () => {
    ExportList(
      searchResults?.length > 0
        ? searchResults?.filter((item: any) => item?.account_type === 1)
        : reOrderByDate || [],
      'Account'
    );
  };

  useEffect(() => {
    membership();
  }, []);

  if (tableProps.dataSource) {
    tableProps.dataSource =
      searchResults?.length > 0 ? searchResults : reOrderByDate || [];
  }

  return (
    <List
      title={`${'المستخدمين'}`}
      pageHeaderProps={{
        extra: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Search
              path="account"
              setSearchResults={setSearchResults}
              searchResults={searchResults}
              data={reOrderByDate || []}
            />
            {user?.account_type !== 2 && (
              <ExportButton
                onClick={handleExportList}
                style={{ marginRight: 10 }}
              >
                {'تصدير'}
              </ExportButton>
            )}
          </div>
        ),
      }}
    >
      <Table
        {...tableProps}
        rowKey="id"
        style={{ cursor: 'pointer' }}
        onRow={(record) => {
          return {
            onClick: () => {
              record.id && show('account-cruds', record.id);
            },
          };
        }}
      >
        <Table.Column
          dataIndex="id"
          title={'معرف الحساب'}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="name"
          title={'الاسم'}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={'email'}
          title={'البريد الالكتروني'}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={'membership_id'}
          title={'نوع العضوية'}
          align="center"
          render={(value, record: any) => {
            const currentMembership = memberships?.find(
              (item: any) => item?.id === value
            );
            const isFreeTrialExpired = record?.free_trial_expiry
              ? dayjs().isAfter(record?.free_trial_expiry)
              : true;
            const inFreeTrial =
              !isFreeTrialExpired &&
              currentMembership?.membership_duration === 0;
            const inMembership = currentMembership?.membership_duration !== 0;
            const isFree = currentMembership?.membership_type === 0;

            return (
              <TextField
                value={
                  !currentMembership?.id
                    ? '-'
                    : isFree
                    ? MembershipType[0]
                    : inFreeTrial
                    ? 'تجربة مجانية'
                    : inMembership
                    ? MembershipType[currentMembership?.membership_type]
                    : '-'
                }
              />
            );
          }}
        />
        <Table.Column
          dataIndex={'membership_duration'}
          title={'مدة العضوية'}
          align="center"
          render={(value) => <TextField value={Membership_Duration[value]} />}
        />

        <Table.Column
          dataIndex={'created_at'}
          title={'تاريخ التسجيل'}
          filterDropdown={(props) => <DropDownFilter dropdownProps={props} />}
          render={(value) => (
            <TextField value={dayjs(value).format('YYYY-MM-DD, HH:mm')} />
          )}
        />

        {user?.account_type !== 2 && (
          <Table.Column<any>
            title={'الإجراءات'}
            dataIndex="actions"
            align="center"
            render={(_text, record): any => {
              return (
                <Actions
                  name_ar="المستخدم"
                  // deleteRecord
                  edit
                  record={record}
                  onClickEdit={() =>
                    record?.id && edit('account-cruds', record?.id)
                  }
                  // onClickDelete={() =>
                  //   DeleteRecordAction(
                  //     `account-cruds/update/by-id/${record?.id}`,
                  //     tableQueryResult?.refetch,
                  //     record?.is_deleted,
                  //     { status: 2 }
                  //   )
                  // }
                />
              );
            }}
          />
        )}
      </Table>
    </List>
  );
};
