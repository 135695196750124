import { notification } from '@pankod/refine-antd';
import Swal from 'sweetalert2';
import { apiRequest } from 'Util/api';
import { AccountStore } from './Stores/Account';
import { ConfigsStore } from './Stores/Configs';
import { Language, OsEnum } from './types';
import * as XLSX from 'xlsx';

export const CreateDeviceAction = async () => {
  const currentDevice = await ConfigsStore.get('device_id');
  if (currentDevice) return;

  try {
    const { data: deviceData } = await apiRequest<{ id: string }>({
      url: 'device-cruds',
      method: 'POST',
      data: {
        os: OsEnum.browser,
        language: Language.ar,
      },
    });
    if (!deviceData) throw new Error('Something went wrong!');
    await ConfigsStore.set('device_id', deviceData?.id);
  } catch (error: any) {
    throw new Error('Error');
  }
};

export const CreateAction = async (
  url: string,
  data: any,
  navigateToEdit?: (url: string, id?: string) => void,
  goBack?: () => void
) => {
  try {
    const { data: res } = await apiRequest<{ id: any }>({
      url,
      method: 'POST',
      data,
      headers: {
        headers: {
          device_id: await ConfigsStore.get('device_id'),
          authorization: `Bearer ${
            (
              await AccountStore.get('account')
            )?.access_token
          }`,
        },
      },
    });
    if (!res) throw new Error('حدث خطأ ما');
    if (res) {
      goBack && goBack();
      navigateToEdit && navigateToEdit(url, res?.id);
      notification.success({
        message: 'تم بنجاح',
        description: 'تم الإنشاء بنجاح',
      });
    }
  } catch (error) {
    notification.error({
      message: 'خطأ',
      description: 'حدث خطأ ما',
    });
  }
};

export const DeleteRecordAction = (
  url: string,
  refetch?: () => void,
  archived?: boolean,
  data?: any
) => {
  Swal.fire({
    title: 'هل أنت متأكد أنك تريد الاستمرار؟',
    showCancelButton: true,
    confirmButtonColor: '#db2828',
    confirmButtonText: 'إرسال',
    cancelButtonText: 'إلغاء',
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const { data: deleteData } = await apiRequest<any>({
          url,
          method: 'PATCH',
          data: data ? data : { is_deleted: !archived },
        });
        if (!deleteData) throw new Error('حدث خطأ أثناء الحذف');
        refetch && refetch();
        notification.success({
          message: 'تم بنجاح ',
          description: 'تمت العملية بنجاح',
        });
      } catch (error: any) {
        throw new Error('Error');
      }
    }
  });
};

export const EditAccountAction = async (
  type: string,
  id: any,
  data: any,
  refetch?: () => void,
  withRefresh?: boolean,
  resetForm?: () => void,
  withoutAlert?: boolean
) => {
  try {
    await apiRequest<boolean>({
      url: `/${type}/${id}`,
      method: 'PATCH',
      data,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.access_token
        }`,
      },
    });
    resetForm && resetForm();
    refetch && refetch();
    !withoutAlert &&
      notification.success({
        message: 'تم بنجاح',
        description: 'تمت العملية بنجاح',
      });
    withRefresh && window.location.reload();
  } catch (error: any) {
    notification.error({
      message: 'error',
      description: error?.message,
    });
  }
};

export const GetThreadAction = async (
  id: string,
  handleThreads: (val: any) => void,
  setLoading: (val: boolean) => void,
  noLoading?: boolean
) => {
  !noLoading && setLoading(true);
  const { data: threads } = await apiRequest<any>({
    url: `Message-cruds/thread-messages/${id}`,
    method: 'GET',
  });
  handleThreads(threads);
  !noLoading && setLoading(false);
};

export const SendMessageAction = async (
  data: { sender_id: number; thread_id: number; message: string },
  refetch?: (val: boolean) => void
) => {
  try {
    await apiRequest<boolean>({
      url: '/Message-cruds/send-message',
      method: 'POST',
      data,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.access_token
        }`,
      },
    });
    refetch && refetch(false);
  } catch (error: any) {
    notification.error({
      message: 'error',
      description: error?.message,
    });
  }
};

export const SearchAction = async (
  key: string,
  value: string,
  data: any[],
  setRecord: (data: any) => void
) => {
  let filtered = [];

  if (value.includes('-')) {
    const key1 = value.split('-')[0];
    filtered = data.filter((item) => item[key] === +key1);
  } else {
    filtered = await (data || [])?.filter((doc) =>
      // @ts-ignore
      isNaN(value)
        ? doc[key]?.toLowerCase()?.includes(value?.toLowerCase())
        : +doc[key] === +value
    );
    filtered?.length === 0 &&
      notification.info({
        message: 'لا يوجد نتائج',
      });
  }
  setRecord(filtered);
};

export const ExportList = (data: any[], fileName: string) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(data || []);
  XLSX.utils.book_append_sheet(wb, ws, `${fileName} List`);
  XLSX.writeFile(wb, `${fileName}List.xlsx`);
};

export const ProfilePossibleMealsAction = async (
  account_id: string,
  handleGetMeals: (data: any) => void,
  meal_type?: number
) => {
  try {
    const { data: mealsData } = await apiRequest<any>({
      url: `profile-cruds/profile-possible-meals/${account_id}`,
      method: 'POST',
      data: { meal_type },
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.access_token
        }`,
      },
    });
    const filteredIngredients = mealsData?.map(
      (item: { name_ar: string; id: string }) => {
        return { label: item.name_ar, value: item.id };
      }
    );
    handleGetMeals(filteredIngredients);
  } catch (error: any) {
    notification.error({
      message: 'error',
      description: error?.message,
    });
  }
};

export const GetRecordsAction = async (
  url: string,
  handleSetRecords?: (data: any) => void,
  filterKey?: any,
  filter?: string
) => {
  try {
    const { data: records } = await apiRequest<any>({
      url,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.access_token
        }`,
      },
    });
    if (url === 'Membership-cruds') {
      await ConfigsStore.set('memberships', records);
    }
    if (filter) {
      const filtered = records?.filter(
        // @ts-ignore
        (item: { id: string }) => item[filterKey] === filter
      );
      handleSetRecords && handleSetRecords(filtered[0]);
    } else {
      handleSetRecords && handleSetRecords(records);
    }
  } catch (error: any) {
    return error;
  }
};

export const GetRecordByIdAction = async (
  url: string,
  id: string,
  handleSetRecord: (data: any) => void
) => {
  try {
    const { data: records } = await apiRequest<any>({
      url: `${url}/${id}`,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.access_token
        }`,
      },
    });

    handleSetRecord(records);
  } catch (error: any) {
    return error;
  }
};

export const AvailableMealSubstitutesAction = async (
  profile_meal_id: string,
  handleGetMeals: (data: any) => void
) => {
  try {
    const { data: mealsData } = await apiRequest<any>({
      url: `profile-meal/available-substitutes/${profile_meal_id}`,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.access_token
        }`,
      },
    });
    handleGetMeals(mealsData);
  } catch (error: any) {
    notification.error({
      message: 'error',
      description: error?.message,
    });
  }
};

export const MarkMessageAsAction = async (id: string, is_read: boolean) => {
  try {
    await apiRequest<boolean>({
      url: `/Message-cruds/update/by-id/${id}`,
      method: 'PATCH',
      data: { is_read },
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.access_token
        }`,
      },
    });
  } catch (error: any) {
    return error;
  }
};

export const CancelMembershipAction = async (
  id: string,
  refetch?: () => void
) => {
  try {
    await apiRequest<boolean>({
      url: `/account-cruds/force-downgrade-account/${id}`,
      method: 'GET',
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.access_token
        }`,
      },
    });
    refetch && refetch();
    notification.success({
      message: 'نجاح',
      description: 'تم إلغاء الاشتراك بنجاح',
    });
  } catch (error: any) {
    notification.error({
      message: 'error',
      description: error?.message,
    });
  }
};

export const ReassignNutritionistAction = async (
  data: { thread_id: string; new_nutritionist_id: string },
  refetch?: () => void
) => {
  try {
    await apiRequest<boolean>({
      url: '/thread/reassign-nutritionist',
      method: 'POST',
      data,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.access_token
        }`,
      },
    });
    refetch && refetch();
    notification.success({
      message: 'نجاح',
      description: 'تم إعادة تعيين أخصائي التغذية بنجاح',
    });
  } catch (error: any) {
    notification.error({
      message: 'error',
      description: error?.message,
    });
  }
};

export const ReportsAction = async (
  data: {
    profile_id: string;
    time_from: string;
    time_to: string;
    email: string;
    report_type: string;
  },
  refetch?: () => void
) => {
  try {
    await apiRequest<boolean>({
      url: '/profile-history/report',
      method: 'POST',
      data,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.access_token
        }`,
      },
    });
    refetch && refetch();
    notification.success({
      message: 'نجاح',
      description: 'تم إرسال التقرير على البريد الإلكتروني بنجاح',
    });
  } catch (error: any) {
    notification.error({
      message: 'error',
      description: error?.message,
    });
  }
};
export const RemoveRecordAction = async (
  url: string,
  id: string,
  refetch: () => void
) => {
  Swal.fire({
    title: 'هل أنت متأكد أنك تريد الاستمرار؟',
    showCancelButton: true,
    confirmButtonColor: '#db2828',
    confirmButtonText: 'إرسال',
    cancelButtonText: 'إلغاء',
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        await apiRequest<boolean>({
          url: `${url}/${id}`,
          method: 'DELETE',
          headers: {
            device_id: await ConfigsStore.get('device_id'),
            authorization: `Bearer ${
              (
                await AccountStore.get('account')
              )?.access_token
            }`,
          },
        });
        refetch();
        notification.success({
          message: 'نجاح',
          description: 'تم حذف العنصر بنجاح',
        });
      } catch (error: any) {
        return error;
      }
    }
  });
};
