import React, { useEffect, useState } from 'react';
import { Refine } from '@pankod/refine-core';
import GlobalFonts from './Font/font.js';
import { GiMeal } from 'react-icons/gi';
import { TbDiscount2 } from 'react-icons/tb';
import {
  MdSetMeal,
  MdFeedback,
  // MdCardMembership,
  MdOutlineSick,
  MdCategory,
} from 'react-icons/md';
import { FaNutritionix } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';

import './index.css';
import {
  Layout,
  ReadyPage,
  notificationProvider,
  ConfigProvider,
} from '@pankod/refine-antd';
import routerProvider from '@pankod/refine-react-router-v6';
import '@pankod/refine-antd/dist/styles.min.css';
import Landing from 'pages/Landing/Landing';
import authProvider from './Providers/AuthProvider';
import { dataProvider } from './Providers/DataProvider';
import { API_URL } from 'Configs';
import { axiosInstance, LogoutAction } from 'Actions/AuthActions';
import { CreateDeviceAction, GetRecordsAction } from 'Actions/ConfigsActions';
import { MealsListing } from 'pages/AdminPanel/Meals/MealsListing';
import ShowMeals from 'pages/AdminPanel/Meals/ShowMeals';
import { EditMeal } from 'pages/AdminPanel/Meals/EditMeal';
import { CreateMeal } from 'pages/AdminPanel/Meals/CreateMeal';
import { AccountListing } from 'pages/AdminPanel/Accounts/AccountListing';
import ShowAccount from 'pages/AdminPanel/Accounts/ShowAccount';
import { EditAccount } from 'pages/AdminPanel/Accounts/EditAccount';
import { FaUsers } from 'react-icons/fa';
import { AllergyListing } from 'pages/AdminPanel/Allergy/AllergyListing';
import ShowAllergy from 'pages/AdminPanel/Allergy/ShowAllergy';
import { EditAllergy } from 'pages/AdminPanel/Allergy/EditAllergy';
import { CreateAllergy } from 'pages/AdminPanel/Allergy/CreateAllergy';
import { IngredientsListing } from 'pages/AdminPanel/Ingredients/IngredientsListing';
import ShowIngredients from 'pages/AdminPanel/Ingredients/ShowIngredients';
import { EditIngredients } from 'pages/AdminPanel/Ingredients/EditIngredients';
import { CreateIngredients } from 'pages/AdminPanel/Ingredients/CreateIngredients';
import { FeedbackListing } from 'pages/AdminPanel/Feedback/FeedbackListing';
import { EditFeedback } from 'pages/AdminPanel/Feedback/EditFeedback';
import ShowFeedback from 'pages/AdminPanel/Feedback/ShowFeedback';
import { NutritionistListing } from 'pages/AdminPanel/nutritionist/NutritionistListing';
import ShowNutritionist from 'pages/AdminPanel/nutritionist/ShowNutritionist';
import { EditNutritionist } from 'pages/AdminPanel/nutritionist/EditNutritionist';
import { CreateNutritionist } from 'pages/AdminPanel/nutritionist/CreateNutritionist';
import { AdminListing } from 'pages/AdminPanel/Admin/AdminListing';
// import { MembershipListing } from 'pages/AdminPanel/Membership/MembershipListing';
// import ShowMembership from 'pages/AdminPanel/Membership/ShowMembership';
// import { EditMembership } from 'pages/AdminPanel/Membership/EditMembership';
import Login from 'pages/AdminPanel/Login/Login';
import { ConfigsStore } from 'Actions/Stores/Configs';
import dayjs from 'dayjs';
import { Verification } from 'pages/AdminPanel/AuthPages/Verification/index';
import { ResetPassword } from 'pages/AdminPanel/AuthPages/ResetPassword/index';
import { ForgotPassword } from 'pages/AdminPanel/AuthPages/ForgotPassword/index';
import { CustomHeader } from 'components/AdminPanel/ActionButtons/Header/header';
import { CreateAdmin } from 'pages/AdminPanel/Admin/CreateAdmin';
import logo from './images/logo.svg';
import styled from 'styled-components';
import { RiAdminFill } from 'react-icons/ri';
import ShowAccountAdmin from 'pages/AdminPanel/Admin/ShowAccountAdmin';
import arEG from 'antd/lib/locale/ar_EG';
import { useTranslation } from 'react-i18next';
import { EditAdmin } from 'pages/AdminPanel/Admin/EditAdmin';
import Terms from 'Terms&Privacy/Terms';
import Privacy from 'Terms&Privacy/Privacy';
import { PromoListing } from 'pages/AdminPanel/PromoCodes/PromoListing';
import ShowPromo from 'pages/AdminPanel/PromoCodes/ShowPromo';
import { EditPromo } from 'pages/AdminPanel/PromoCodes/EditPromo';
import { CreatePromo } from 'pages/AdminPanel/PromoCodes/CreatePromo';
import { SystemConfigsListing } from 'pages/AdminPanel/SystemConfigs/SystemConfigsListing';
import { EditSystemConfig } from 'pages/AdminPanel/SystemConfigs/EditSystemConfig';
import { ErrorComponent } from 'pages/AdminPanel/ErrorComponent/index';
import * as Sentry from '@sentry/react';
import { CategoryIngredientsListing } from 'pages/AdminPanel/IngredientCategory/CategoryIngredientsListing';
import ShowCategoryIngredients from 'pages/AdminPanel/IngredientCategory/ShowCategoryIngredients';
import { EditIngredientsCategory } from 'pages/AdminPanel/IngredientCategory/EditIngredientsCategory';
import { CreateCategroyIngredients } from 'pages/AdminPanel/IngredientCategory/CreateCategroyIngredients';

const { RouterComponent } = routerProvider;

const CustomRouterComponent = () => <RouterComponent basename="/" />;

const App: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [loggedIn, setLoggedIn] = useState<boolean>();
  const [roles, setRoles] = useState<number>();
  const [checkCookie, setCheckCookie] = useState<any>();
  const [checkSessionStorage, setSessionStorage] = useState<any>();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => 'ar',
  };

  useEffect(() => {
    if (checkCookie?.value) {
      if (dayjs(checkCookie?.expiry).format() <= dayjs(new Date()).format()) {
        LogoutAction(true);
      }
    } else {
      if (checkSessionStorage !== 'true' && loggedIn) {
        LogoutAction(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkCookie, checkCookie?.value, checkSessionStorage]);

  const routing = {
    ...routerProvider,
    RouterComponent: CustomRouterComponent,
    routes: [
      {
        exact: true,
        element: <Landing />,
        path: '/',
      },
      {
        exact: true,
        element: loggedIn ? <ErrorComponent /> : <ForgotPassword />,
        path: '/forgot-password',
      },
      {
        exact: true,
        element: loggedIn ? <ErrorComponent /> : <Verification />,
        path: '/verification',
      },
      {
        exact: true,
        element: loggedIn ? <ErrorComponent /> : <ResetPassword />,
        path: '/reset-password',
      },
      {
        exact: true,
        element: <Terms />,
        path: '/terms-and-conditions',
      },
      {
        exact: true,
        element: <Privacy />,
        path: '/privacy-policy',
      },
    ],
  };

  const getCookie = async () => {
    setCheckCookie(await ConfigsStore.get('cookieExpiration'));
    setLoggedIn(await ConfigsStore.get('logged_in'));
    setRoles(await ConfigsStore.get('adminRoles'));
    setSessionStorage(await sessionStorage.getItem('logoutOnEndSession'));
  };

  useEffect(() => {
    getCookie();
    CreateDeviceAction();
    GetRecordsAction('Membership-cruds');
  }, []);

  const CustomLoginPage = () => <Login />;

  let resources =
    roles !== 2
      ? // eslint-disable-next-line array-callback-return
        allResources?.filter((resource) => {
          if (roles === 1) {
            if (resource.name !== 'admin-cruds') {
              return resource;
            }
          } else {
            return resource;
          }
        }) || []
      : nutritionistResources || [];

  return (
    <>
      <GlobalFonts />
      <ConfigProvider direction={'rtl'} locale={arEG}>
        <Refine
          Title={({ collapsed }) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 15,
                marginBottom: 10,
              }}
            >
              {!collapsed && (
                <BackgroundColorImg>
                  <img width="100" height={100} src={logo} alt="logo" />
                </BackgroundColorImg>
              )}
              {collapsed && <img width="50" src={logo} alt="logo" />}
            </div>
          )}
          routerProvider={routing}
          // @ts-ignore
          dataProvider={dataProvider(API_URL, axiosInstance)}
          Layout={Layout}
          Header={CustomHeader}
          i18nProvider={i18nProvider}
          ReadyPage={ReadyPage}
          LoginPage={CustomLoginPage}
          authProvider={authProvider}
          notificationProvider={notificationProvider}
          catchAll={
            <ErrorComponent
              // @ts-ignore
              resources={allResources}
              nutritionistResources={nutritionistResources}
            />
          }
          // @ts-ignore
          resources={resources?.length > 0 ? resources : [{ name: 'error' }]}
        />
      </ConfigProvider>
    </>
  );
};

export default Sentry.withProfiler(App);

export const BackgroundColorImg = styled.div`
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 10px 6px #00000005;
`;

export const allResources = [
  {
    name: 'admin-cruds',
    options: { label: `${'المشرفين'}` },
    list: AdminListing,
    show: ShowAccountAdmin,
    edit: EditAdmin,
    create: CreateAdmin,
    icon: <RiAdminFill style={{ marginLeft: 10 }} size={20} />,
  },
  {
    name: 'account-cruds',
    options: { label: `${'المستخدمين'}` },
    list: AccountListing,
    show: ShowAccount,
    edit: EditAccount,
    icon: <FaUsers style={{ marginLeft: 10 }} size={20} />,
  },
  {
    name: 'Meal-cruds',
    options: { label: `${'الوجبات'}` },
    list: MealsListing,
    show: ShowMeals,
    edit: EditMeal,
    create: CreateMeal,
    icon: <GiMeal style={{ marginLeft: 10 }} size={20} />,
  },
  {
    name: 'ingredients-cruds',
    options: { label: `${'المكونات'}` },
    list: IngredientsListing,
    show: ShowIngredients,
    edit: EditIngredients,
    create: CreateIngredients,
    icon: <MdSetMeal style={{ marginLeft: 10 }} size={20} />,
  },
  {
    name: 'ingredient-category',
    options: { label: `${'تصنيف المكونات'}` },
    list: CategoryIngredientsListing,
    show: ShowCategoryIngredients,
    edit: EditIngredientsCategory,
    create: CreateCategroyIngredients,
    icon: <MdCategory style={{ marginLeft: 10 }} size={20} />,
  },
  {
    name: 'Allergy-cruds',
    options: { label: `${'الحساسية'}` },
    list: AllergyListing,
    show: ShowAllergy,
    edit: EditAllergy,
    create: CreateAllergy,
    icon: <MdOutlineSick style={{ marginLeft: 10 }} size={20} />,
  },
  {
    name: 'feedback-cruds',
    options: { label: `${'تغذية راجعة'}` },
    list: FeedbackListing,
    show: ShowFeedback,
    edit: EditFeedback,
    // create: CreateFeedback,
    icon: <MdFeedback style={{ marginLeft: 10 }} size={20} />,
  },
  {
    name: 'nutritionist-cruds',
    options: { label: `${'اخصائي التغذية'}` },
    list: NutritionistListing,
    show: ShowNutritionist,
    edit: EditNutritionist,
    create: CreateNutritionist,
    icon: <FaNutritionix style={{ marginLeft: 10 }} size={20} />,
  },
  // {
  //   name: 'Membership-cruds',
  //   options: { label: `${' العضويات'}` },
  //   list: MembershipListing,
  //   show: ShowMembership,
  //   edit: EditMembership,
  //   icon: <MdCardMembership style={{ marginLeft: 10 }} size={20} />,
  // },
  {
    name: 'promo-code-cruds',
    options: { label: `${'الرموز الترويجية'}` },
    list: PromoListing,
    show: ShowPromo,
    edit: EditPromo,
    create: CreatePromo,
    icon: <TbDiscount2 style={{ marginLeft: 10 }} size={20} />,
  },
  {
    name: 'system-config-cruds',
    options: { label: `${'الإعدادات العامة'}` },
    list: SystemConfigsListing,
    edit: EditSystemConfig,
    icon: <FiSettings style={{ marginLeft: 10 }} size={20} />,
  },
];

export const nutritionistResources = [
  {
    name: 'nutritionist-cruds',
    options: { label: `${'اخصائي التغذية'}` },
    list: null,
    show: ShowNutritionist,
    edit: EditNutritionist,
    icon: <FaNutritionix style={{ marginLeft: 10 }} size={20} />,
  },
  {
    name: 'account-cruds',
    options: { label: `${'المستخدمين'}` },
    list: AccountListing,
    show: ShowAccount,
    edit: EditAccount,
    icon: <FaUsers style={{ marginLeft: 10 }} size={20} />,
  },
  {
    name: 'Meal-cruds',
    options: { label: `${'الوجبات'}` },
    list: MealsListing,
    show: ShowMeals,
    edit: EditMeal,
    create: CreateMeal,
    icon: <GiMeal style={{ marginLeft: 10 }} size={20} />,
  },
  {
    name: 'ingredients-cruds',
    options: { label: `${'المكونات'}` },
    list: IngredientsListing,
    show: ShowIngredients,
    edit: EditIngredients,
    create: CreateIngredients,
    icon: <MdSetMeal style={{ marginLeft: 10 }} size={20} />,
  },
  {
    name: 'ingredient-category',
    options: { label: `${'تصنيف المكونات'}` },
    list: CategoryIngredientsListing,
    show: ShowCategoryIngredients,
    edit: EditIngredientsCategory,
    create: CreateCategroyIngredients,
    icon: <MdCategory style={{ marginLeft: 10 }} size={20} />,
  },
  {
    name: 'Allergy-cruds',
    options: { label: `${'الحساسية'}` },
    list: AllergyListing,
    show: ShowAllergy,
    edit: EditAllergy,
    create: CreateAllergy,
    icon: <MdOutlineSick style={{ marginLeft: 10 }} size={20} />,
  },
];
