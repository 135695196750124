import React from 'react';
import {
  BooleanField,
  Grid,
  ListButton,
  Show,
  Space,
  Typography,
} from '@pankod/refine-antd';
import { useNavigation, useShow } from '@pankod/refine-core';
import { Actions } from 'components/AdminPanel/ActionButtons';
import styled from 'styled-components';
import { DeleteRecordAction } from '../../../Actions/ConfigsActions';
import {
  MembershipType,
  Membership_Duration,
  PROMO_CODE_DISCOUNT_TYPE_ENUM,
} from 'Actions/types';
import dayjs from 'dayjs';

const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

function ShowPromo() {
  const screens = useBreakpoint();
  const { edit } = useNavigation();

  const { queryResult } = useShow({});
  const { data, isLoading, refetch } = queryResult;

  const record = data?.data;

  return (
    <>
      <Show
        isLoading={isLoading}
        title={'تفاصيل الرمز الترويجي'}
        pageHeaderProps={{
          extra: (
            <Space>
              <ListButton>
                <Text>{'الرموز'}</Text>
              </ListButton>
              <Actions
                name_ar="الرموز"
                deleteRecord
                edit
                record={record}
                onClickDelete={() =>
                  DeleteRecordAction(
                    `promo-code-cruds/update/by-id/${record?.id}`,
                    refetch,
                    record?.is_deleted
                  )
                }
                onClickEdit={() =>
                  record?.id && edit('promo-code-cruds', record?.id)
                }
              />
            </Space>
          ),
        }}
      >
        <div
          className="detailsWrapper"
          style={{ justifyContent: screens.md ? '' : 'space-between' }}
        >
          <Break breakPoint={!!screens.md}>
            <Title level={5}>{'معرّف الرمز الترويجي'}</Title>
            <Text>{record?.id}</Text>

            <Title level={5}>{'الرمز الترويجي'}</Title>
            <Text>{record?.code}</Text>

            {/* <Title level={5}>{'نوع الرمز'}</Title>
            <Text>{PROMO_CODE_TYPE_ENUM[+record?.code_type]}</Text> */}

            <Title level={5}>{'الخصم'}</Title>
            <Text>{record?.discount}</Text>

            <Title level={5}>{'نوع الخصم'}</Title>
            <Text>{PROMO_CODE_DISCOUNT_TYPE_ENUM[+record?.discount_type]}</Text>

            <Title level={5}>{'الحد الاقصى للاستخدام'}</Title>
            <Text>{record?.max_usage}</Text>
            <Title level={5}>{'الاستخدام'}</Title>
            <Text>{record?.usage}</Text>

            <Title level={5}>{'مدة الاشتراك'}</Title>
            <Text>{Membership_Duration[+record?.membership_duration]}</Text>

            <Title level={5}>{'نوع الاشتراك'}</Title>
            <Text>{MembershipType[+record?.membership_type]}</Text>

            {/* <Title level={5}>{'معرف Apple'}</Title>
            <Text>{record?.apple_id || '-'}</Text> */}
          </Break>
          <Break breakPoint={!!screens.md}>
            <Title level={5}>{'تاريخ البدء'}</Title>
            <Text>{dayjs(record?.start_date).format('YYYY-MM-DD, HH:mm')}</Text>
            <Title level={5}>{'تاريخ الانتهاء'}</Title>
            <Text>{dayjs(record?.end_date).format('YYYY-MM-DD, HH:mm')}</Text>
            <Title level={5}>{'أنشئت في'}</Title>
            <Text>{dayjs(record?.created_at).format('YYYY-MM-DD, HH:mm')}</Text>

            <Title level={5}>{'محذوف'}</Title>
            <BooleanField value={record?.is_deleted} />
          </Break>
        </div>
      </Show>
    </>
  );
}

export default ShowPromo;

const Break = styled.div<{ breakPoint?: boolean }>`
  margin: ${(props) => (props.breakPoint ? '' : '20px')};
`;
