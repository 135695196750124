import * as React from 'react';
import { useForm, Create, Form, Input } from '@pankod/refine-antd';
import { errorMessage } from 'Util/ErrorMessage';
import { useFormik } from 'formik';
import { CreateAction } from 'Actions';
import { useNavigation } from '@pankod/refine-core';
import * as Yup from 'yup';

export const CreateCategroyIngredients = () => {
  const navigate = useNavigation();
  const validationSchema = Yup.object().shape({
    name_ar: Yup.string().required('مطلوب'),
  });
  const { formProps, saveButtonProps, queryResult } = useForm();

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      name_ar: '',
    },
    validationSchema,

    onSubmit: async (submittedValues) => {
      const inputs = {
        name_ar: submittedValues.name_ar,
        name_en: submittedValues.name_ar,
      };
      if (Object.keys(inputs)?.length > 0) {
        CreateAction('ingredient-category', inputs, undefined, navigate.goBack);
      }
    },
  });

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  };

  return (
    <Create
      isLoading={queryResult?.isFetching}
      saveButtonProps={buttonProps}
      title={'إنشاء تصنيف'}
    >
      <Form {...formProps} layout="vertical" initialValues={{ isActive: true }}>
        <Form {...formProps} layout="vertical">
          <Form.Item
            label="اسم التصنيف"
            name="name_ar"
            rules={errorMessage()}
            required
          >
            <Input
              name="name_ar"
              onChange={handleChange}
              value={values.name_ar}
            />
          </Form.Item>
        </Form>
      </Form>
    </Create>
  );
};
