import React from 'react';
import {
  BooleanField,
  Grid,
  ListButton,
  Show,
  Space,
  Typography,
} from '@pankod/refine-antd';
import { useGetIdentity, useNavigation, useShow } from '@pankod/refine-core';
import { Actions } from 'components/AdminPanel/ActionButtons';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { DeleteRecordAction } from '../../../Actions/ConfigsActions';
import { AllergyType } from 'Actions/types';

const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

function ShowAllergy() {
  const { data: user } = useGetIdentity();
  const screens = useBreakpoint();
  const { edit } = useNavigation();

  const { queryResult } = useShow({});
  const { data, isLoading, refetch } = queryResult;

  const record = data?.data;
  return (
    <>
      <Show
        isLoading={isLoading}
        title={'تفاصيل الحساسية'}
        pageHeaderProps={{
          extra: (
            <Space>
              <ListButton>
                <Text>{'الحساسيات'}</Text>
              </ListButton>
              {user?.account_type !== 2 && (
                <Actions
                  name_ar="الحساسيه"
                  deleteRecord
                  edit
                  record={record}
                  onClickDelete={() =>
                    DeleteRecordAction(
                      `Allergy-cruds/update/by-id/${record?.id}`,
                      refetch,
                      record?.is_deleted
                    )
                  }
                  onClickEdit={() =>
                    record?.id && edit('Allergy-cruds', record?.id)
                  }
                />
              )}
            </Space>
          ),
        }}
      >
        <div
          className="detailsWrapper"
          style={{ justifyContent: screens.md ? '' : 'space-between' }}
        >
          <Break breakPoint={!!screens.md}>
            <Title level={5}>{'معرف الحساسية'}</Title>
            <Text>{record?.id}</Text>
            <Title level={5}>{'اسم الحساسية'}</Title>
            <Text>{record?.name_ar}</Text>

            <Title level={5}>{'نوعية الحساسية'}</Title>
            <Text>{AllergyType[+record?.type]}</Text>
          </Break>
          <Break breakPoint={!!screens.md}>
            <Title level={5}>{'أنشأ في'}</Title>
            <Text>{dayjs(record?.created_at).format('YYYY-MM-DD, HH:mm')}</Text>
            <Title level={5}>{'محذوف'}</Title>
            <BooleanField value={record?.is_deleted} />
          </Break>
        </div>
      </Show>
    </>
  );
}

export default ShowAllergy;

const Break = styled.div<{ breakPoint?: boolean }>`
  margin: ${(props) => (props.breakPoint ? '' : '20px')};
`;
