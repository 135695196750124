import React, { useEffect, useState } from 'react';
import {
  BooleanField,
  Button,
  Grid,
  List,
  ListButton,
  Show,
  Space,
  Table,
  TextField,
  Typography,
} from '@pankod/refine-antd';
import { useGetIdentity, useNavigation, useShow } from '@pankod/refine-core';
import { Actions } from 'components/AdminPanel/ActionButtons';
import dayjs from 'dayjs';
import styled from 'styled-components';
import {
  AccountStatus,
  AdminRole,
  AllergyType,
  FoodSource,
  LifeStyle,
  MealType,
  MembershipType,
  Membership_Duration,
  Profile_Gender,
  waterIntakenotification,
} from 'Actions/types';
import { useParams } from 'react-router-dom';
import countries from '../../../ JsonAssets/countries.json';
import {
  CancelMembershipAction,
  GetRecordByIdAction,
  GetRecordsAction,
} from 'Actions';
import MealSubstitutesModal from 'components/AdminPanel/MealSubstitutes';
import ReassignMeal from 'components/ReassignMeal';
import Swal from 'sweetalert2';
import ReportsModal from 'components/ReportsModal';

const { useBreakpoint } = Grid;
const { Title, Text } = Typography;
function ShowAccount() {
  const { data: user } = useGetIdentity();
  const screens = useBreakpoint();
  const { edit, show } = useNavigation();
  const params = useParams();
  const { queryResult } = useShow({});
  const { data, isLoading } = queryResult;
  const [profileCruds, setProfileCruds] = useState<any>([]);
  const [currentMembership, setCurrentMembership] = useState<any>();
  const [mealSubstituteModal, setMealSubstituteModal] = React.useState<any>();
  const [reassignModal, setReassignModal] = React.useState(false);
  const [reportsModal, setReportsModal] = React.useState<any>();
  const [filteredmealcruds, setMeals] = React.useState<any>([]);
  const [filteredAllergyCruds, setAllergyCruds] = React.useState<any>([]);
  const [filteredIngredientsCruds, setIngredientsCruds] = React.useState<any>(
    []
  );

  const record = data?.data;

  const country = countries?.find(
    (element: any) => element?.alpha2 === record?.country
  )?.name;

  const cancelMembership = () => {
    Swal.fire({
      title: 'هل أنت متأكد أنك تريد الاستمرار؟',
      showCancelButton: true,
      confirmButtonColor: '#db2828',
      confirmButtonText: 'إرسال',
      cancelButtonText: 'إلغاء',
    }).then(async (result) => {
      if (result.isConfirmed) {
        params?.id && CancelMembershipAction(params?.id, refetch);
      }
    });
  };

  const refetch = () => {
    GetRecordsAction(
      `profile_allergy-cruds?filter={"profile_id": ${params.id}}`,
      setAllergyCruds
    );
    GetRecordsAction(
      `profile_meal?filter={"profile_id": ${params.id}}`,
      setMeals
    );
    GetRecordsAction(
      `profile_excluded_ingredients-cruds?filter={"profile_id": ${params.id}}`,
      setAllergyCruds
    );
    GetRecordsAction(
      `profile_excluded_ingredients-cruds?filter={"profile_id": ${params.id}}`,
      setIngredientsCruds
    );
    queryResult?.refetch();
  };

  useEffect(() => {
    if (record?.email) {
      GetRecordsAction(
        'profile-cruds',
        setProfileCruds,
        'account_id',
        params.id
      );
      GetRecordsAction(
        `profile_meal?filter={"profile_id": ${params.id}}`,
        setMeals
      );
      GetRecordsAction(
        `profile_allergy-cruds?filter={"profile_id": ${params.id}}`,
        setAllergyCruds
      );
      GetRecordsAction(
        `profile_excluded_ingredients-cruds?filter={"profile_id": ${params.id}}`,
        setIngredientsCruds
      );
    }
  }, [params.id, record?.email]);

  useEffect(() => {
    if (record?.membership_id) {
      GetRecordByIdAction(
        'Membership-cruds',
        record?.membership_id,
        setCurrentMembership
      );
    }
  }, [record?.membership_id]);

  const isFreeTrialExpired = record?.free_trial_expiry
    ? dayjs().isAfter(record?.free_trial_expiry)
    : true;

  const inFreeTrial =
    !isFreeTrialExpired && currentMembership?.membership_duration === 0;
  const inMembership = currentMembership?.membership_duration !== 0;
  const isFree = currentMembership?.membership_type === 0;

  return (
    <>
      <Show
        isLoading={isLoading}
        title={'تفاصيل المستخدمين'}
        pageHeaderProps={{
          extra: (
            <Space>
              <ListButton>
                <Text>{'المستخدمين'}</Text>
              </ListButton>
              {user?.account_type !== 2 &&
                currentMembership &&
                currentMembership?.membership_type !== 0 && (
                  <Button
                    onClick={cancelMembership}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Text>{'إلغاء الاشتراك'}</Text>
                  </Button>
                )}

              <Button
                onClick={() => setReportsModal(record)}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                التقارير
              </Button>

              <Button onClick={() => setReassignModal(profileCruds)}>
                إعادة تعيين الوجبات
              </Button>

              {user?.account_type !== 2 && (
                <Actions
                  name_ar="المستخدم"
                  record={record}
                  edit
                  onClickEdit={() =>
                    record?.id && edit('account-cruds', record?.id)
                  }
                />
              )}
            </Space>
          ),
        }}
      >
        <div
          className="detailsWrapper"
          style={{ justifyContent: screens.md ? '' : 'space-between' }}
        >
          <Break breakPoint={!!screens.md}>
            <Title level={5}>{' معرف الحساب'}</Title>
            <Text>{record?.id}</Text>
            <Title level={5}>{'اسم المستخدم'}</Title>
            <Text>{record?.name}</Text>

            {(record?.role === 0 || record?.role > 0) && (
              <>
                <Title level={5}>{'الدور'}</Title>
                <Text>{AdminRole[+record?.role]}</Text>
              </>
            )}

            <Title level={5}>{'البريد الالكتروني'}</Title>
            <Text>{record?.email || '-'}</Text>

            <Title level={5}>{'الحالة'}</Title>
            <Text>{AccountStatus[+record?.status]}</Text>
            {record?.country && (
              <>
                <Title level={5}>{'الدولة'}</Title>
                <Text>{country || '-'}</Text>
              </>
            )}
            <Title level={5}>{' تاريخ الولادة'}</Title>
            <Text>
              {profileCruds?.date_of_birth
                ? dayjs(profileCruds?.date_of_birth).format('YYYY-MM-DD')
                : '-'}
            </Text>

            <Title level={5}>{'نوع العضوية'}</Title>
            <Text>
              {!currentMembership?.id
                ? '-'
                : isFree
                ? MembershipType[0]
                : inFreeTrial
                ? 'تجربة مجانية'
                : inMembership
                ? MembershipType[currentMembership?.membership_type]
                : '-'}
            </Text>
            <Title level={5}>{'مدة العضوية'}</Title>
            <Text>
              {Membership_Duration[currentMembership?.membership_duration] ||
                '-'}
            </Text>
          </Break>

          <Break breakPoint={!!screens.md}>
            <Title level={5}>{' الوزن'}</Title>
            <Text>{profileCruds?.weight || '-'}</Text>
            <Title level={5}>{' الطول'}</Title>
            <Text>{profileCruds?.height || '-'}</Text>

            {/* <Title level={5}>{' الوزن المستهدف'}</Title>
            <Text>{profileCruds?.target_weight || '-'}</Text> */}
            <Title level={5}>{'تفضيل الوجبات'}</Title>
            <Text>{FoodSource[+profileCruds?.food_preference] || '-'}</Text>
            <Title level={5}>{' نمط الحياة'}</Title>
            <Text>{LifeStyle[+profileCruds?.life_style] || '-'}</Text>
            <Title level={5}>{'الجنس'}</Title>
            <Text>{Profile_Gender[+profileCruds?.gender] || '-'}</Text>

            {record?.last_login && (
              <>
                <Title level={5}>{'تاريخ آخر تسجيل دخول'}</Title>
                <Text>
                  {dayjs(record?.last_login).format('YYYY-MM-DD, HH:mm') || '-'}
                </Text>
              </>
            )}

            {record?.free_trial_expiry && (
              <>
                <Title level={5}>{'تاريخ انتهاء الفترة التجريبية'}</Title>
                <Text>
                  {dayjs(record?.free_trial_expiry).format(
                    'YYYY-MM-DD, HH:mm'
                  ) || '-'}
                </Text>
              </>
            )}

            {record?.current_subscription_start_date && (
              <>
                <Title level={5}>{'تاريخ بدء الاشتراك'}</Title>
                <Text>
                  {dayjs(record?.current_subscription_start_date).format(
                    'YYYY-MM-DD, HH:mm'
                  ) || '-'}
                </Text>
              </>
            )}

            {record?.membership_expiry_date && (
              <>
                <Title level={5}>{'تاريخ انتهاء الاشتراك'}</Title>
                <Text>
                  {dayjs(record?.membership_expiry_date).format(
                    'YYYY-MM-DD, HH:mm'
                  ) || '-'}
                </Text>
              </>
            )}

            {record?.deletion_requested_at && (
              <>
                <Title level={5}>{'تم طلب الحذف في'}</Title>
                <Text>
                  {dayjs(record?.deletion_requested_at).format(
                    'YYYY-MM-DD, HH:mm'
                  ) || '-'}
                </Text>
              </>
            )}

            {record?.requested_cancellation_at && (
              <>
                <Title level={5}>{'تم طلب إلغاء في'}</Title>
                <Text>
                  {dayjs(record?.requested_cancellation_at).format(
                    'YYYY-MM-DD, HH:mm'
                  ) || '-'}
                </Text>
              </>
            )}
            {record?.description && (
              <>
                <Title level={5}>{'الوصف'}</Title>
                <DescriptionWrapper
                  breakPoint={record?.description?.length > 50}
                >
                  <Text>{record?.description}</Text>
                </DescriptionWrapper>
              </>
            )}

            <Title level={5}>{'تاريخ التسجيل'}</Title>
            <Text>{dayjs(record?.created_at).format('YYYY-MM-DD, HH:mm')}</Text>
          </Break>
          <Break breakPoint={!!screens.md}>
            <Title level={5}>{'طلب إلغاء الاشتراك'}</Title>
            <BooleanField value={record?.requested_cancellation} />
            <Title level={5}>{' تنبيهات الافطار'}</Title>
            <BooleanField value={profileCruds?.breakfast_notification} />
            <Title level={5}>{' تنبيهات الغداء'}</Title>
            <BooleanField value={profileCruds?.lunch_notification} />
            <Title level={5}>{' تنبيهات العشاء'}</Title>
            <BooleanField value={profileCruds?.dinner_notification} />
            <Title level={5}>{' تنبيهات الوجبة السريعة'}</Title>
            <BooleanField value={profileCruds?.snack_notification} />
            <Title level={5}>{'تنبهات اخذ الماء'}</Title>
            <Text>
              {
                waterIntakenotification[
                  +profileCruds?.water_intake_notification
                ]
              }
            </Text>
            <Title level={5}>{'نقص الفيتامينات'}</Title>
            <BooleanField
              value={profileCruds?.minerals_or_vitamin_deficiency}
            />
            <Title level={5}>{'الأمراض المزمنة'}</Title>
            <BooleanField value={profileCruds?.chronic_diseases} />
            <Title level={5}>{'  تنيهات الوزن '}</Title>
            <BooleanField value={profileCruds?.weighing_notification} />

            <Title level={5}>{'بريد الكتروني مؤكد'}</Title>
            <BooleanField value={record?.is_email_confirmed} />
          </Break>
        </div>
      </Show>

      <div style={{ marginTop: 20 }} />
      <List
        title={`${' الوجبات'}`}
        pageHeaderProps={{
          extra: <></>,
        }}
      >
        <Table
          dataSource={filteredmealcruds}
          rowKey="id"
          style={{ cursor: 'pointer' }}
          onRow={(doc) => {
            return {
              onClick: () => {
                doc?.meal_id && show('Meal-cruds', doc?.meal_id);
              },
            };
          }}
        >
          <Table.Column
            dataIndex="meal_id"
            title={' معرف الوجبة'}
            render={(value) => <TextField value={value || ' لا يوجد  '} />}
          />
          <Table.Column
            dataIndex={['meal', 'name_ar']}
            title={'اسم الوجبة'}
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            dataIndex={['meal', 'meal_type']}
            title={'نوع الوجبة'}
            align="center"
            render={(value) => <TextField value={MealType[value]} />}
          />

          <Table.Column
            dataIndex={['meal', 'food_preference']}
            title={'تفضيل الوجبة'}
            align="center"
            render={(value) => <TextField value={FoodSource[value]} />}
          />

          <Table.Column<any>
            title={'الإجراءات'}
            dataIndex="actions"
            align="center"
            render={(_text, record): any => {
              return (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setMealSubstituteModal(record);
                  }}
                >
                  بديل الوجبة
                </Button>
              );
            }}
          />
        </Table>
      </List>

      <div style={{ marginTop: 20 }} />
      <List
        title={`${'حساسية الطعام'}`}
        pageHeaderProps={{
          extra: <></>,
        }}
      >
        <Table
          dataSource={filteredAllergyCruds}
          rowKey="id"
          style={{ cursor: 'pointer' }}
          onRow={(doc) => {
            return {
              onClick: () => {
                doc?.allergy_id && show('Allergy-cruds', doc?.allergy_id);
              },
            };
          }}
        >
          <Table.Column
            dataIndex="id"
            title={'معرف الحساسية'}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex={['allergy', 'name_ar']}
            title={'اسم الحساسية'}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex={['allergy', 'type']}
            title={'نوع الحساسية'}
            render={(value) => <TextField value={AllergyType[value]} />}
          />
        </Table>
      </List>
      {filteredIngredientsCruds.length > 0 && (
        <>
          <div style={{ marginTop: 20 }} />
          <List
            title={`${' تفاصيل المكون'}`}
            pageHeaderProps={{
              extra: <></>,
            }}
          >
            <Table
              dataSource={filteredIngredientsCruds}
              rowKey="id"
              style={{ cursor: 'pointer' }}
              onRow={(doc) => {
                return {
                  onClick: () => {
                    doc?.ingredient_id &&
                      show('ingredients-cruds', doc?.ingredient_id);
                  },
                };
              }}
            >
              <Table.Column
                dataIndex="ingredient_id"
                title={'معرف المكون'}
                render={(value) => <TextField value={value || ' لا يوجد  '} />}
              />
              <Table.Column
                dataIndex={['ingredient', 'name_ar']}
                title={'اسم المكون'}
                render={(value) => <TextField value={value} />}
              />
            </Table>
          </List>
        </>
      )}

      {mealSubstituteModal && (
        <MealSubstitutesModal
          visible={!!mealSubstituteModal}
          setVisible={setMealSubstituteModal}
          info={mealSubstituteModal}
          refresh={refetch}
        />
      )}
      {reassignModal && (
        <ReassignMeal visible={reassignModal} setVisible={setReassignModal} />
      )}
      {reportsModal && (
        <ReportsModal
          visible={reportsModal}
          setVisible={setReportsModal}
          info={reportsModal}
        />
      )}
    </>
  );
}

export default ShowAccount;

const Break = styled.div<{ breakPoint?: boolean }>`
  margin: ${(props) => (props.breakPoint ? '' : '20px')};
`;
const DescriptionWrapper = styled.div<{ breakPoint?: boolean }>`
  width: ${(props) => (props.breakPoint ? '400px' : '200px')};
`;
