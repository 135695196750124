import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from '@pankod/refine-antd';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;

export const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

Sentry.init({
  dsn: 'https://b76439ce4927490db8ee21f24a16bfac@sentry.wtmsrv.com/28',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  // send local storage contents to sentry  (for debugging) with each event
  beforeSend(event) {
    event.contexts = {
      ...event.contexts,
      'State (localStorage)': {
        'Configs/store': window.localStorage.getItem('Configs/store'),
        'Account/store': window.localStorage.getItem('Account/store'),
      },
    };
    return event;
  },
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
  environment: window.location.host.includes('admin.saleem.com')
    ? 'production'
    : window.location.host.includes('admin.saleem.staging.wtmsrv.com')
    ? 'staging'
    : 'development',
});
ReactDOM.render(
  <React.StrictMode>
    <React.Suspense
      fallback={
        <SpinWrapper>
          <Spin indicator={antIcon} />
        </SpinWrapper>
      }
    >
      <App />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
