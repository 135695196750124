import React, { useEffect } from 'react';
import {
  Button,
  Grid,
  List,
  ListButton,
  RefreshButton,
  Show,
  Space,
  Table,
  TextField,
  Typography,
} from '@pankod/refine-antd';
import {
  useGetIdentity,
  useNavigation,
  useShow,
  useTable,
} from '@pankod/refine-core';
import { Actions } from 'components/AdminPanel/ActionButtons';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { AccountStatus } from 'Actions/types';
import ChatModal from 'components/AdminPanel/Chat';
import { Search } from 'components/AdminPanel/Search';
import ReassignNutritionistModal from 'components/AdminPanel/ReassignNutrioniestModal';
const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

function ShowNutritionist() {
  const [searchResults, setSearchResults] = React.useState([]);
  const [mergedUsers, setMergedUsers] = React.useState<any[]>([]);
  const [visible, setVisible] = React.useState<any>(false);
  const [reassignModal, setReassignModal] = React.useState<any>();

  const screens = useBreakpoint();
  const { edit, show } = useNavigation();
  const { data: user } = useGetIdentity();
  const params = useParams();
  const nutritionist = useShow({
    resource: 'nutritionist-cruds',
  });
  const { tableQueryResult } = useTable({
    resource: `Message-cruds/threads/${params?.id}`,
  });

  const users = useTable({
    resource: `account-cruds`,
    initialPageSize: 1000000,
  });

  useEffect(() => {
    if (tableQueryResult.data?.data && users?.tableQueryResult?.data?.data) {
      const merged: React.SetStateAction<any[]> = [];
      users?.tableQueryResult?.data?.data?.filter((item) => {
        return tableQueryResult?.data?.data?.filter((user) => {
          if (item.id === user.profile_id) {
            return merged.push({ ...item, ...user });
          } else {
            return null;
          }
        });
      });
      const orderedArray = merged.sort((a, b) => {
        if (!a.last_message_at) return 1;
        if (!b.last_message_at) return -1;
        const sortByLastMessage =
          new Date(b.last_message_at).getTime() -
          new Date(a.last_message_at).getTime();
        return sortByLastMessage;
      });
      return setMergedUsers(orderedArray);
    }
  }, [tableQueryResult.data?.data, users?.tableQueryResult?.data?.data]);

  useEffect(() => {
    const interval = setInterval(() => {
      tableQueryResult?.refetch();
    }, 10000);
    return () => clearInterval(interval);
  }, [tableQueryResult]);

  const record = users?.tableQueryResult?.data?.data?.find(
    (item) => item?.id === params?.id
  );

  return (
    <>
      <Show
        isLoading={tableQueryResult?.isLoading}
        title={'تفاصيل اخصائي التغذية'}
        pageHeaderProps={{
          extra: (
            <Space>
              <ListButton>
                <Text>{' أخصائيو التغذية'}</Text>
              </ListButton>
              <Actions
                name_ar="اخصائي التغذية"
                edit
                record={record}
                onClickEdit={() =>
                  record?.id && edit('nutritionist-cruds', record?.id)
                }
              />
            </Space>
          ),
        }}
      >
        <div
          className="detailsWrapper"
          style={{ justifyContent: screens.md ? '' : 'space-between' }}
        >
          <Break breakPoint={!!screens.md}>
            <Title level={5}>{'معرف اخصائي التغذية '}</Title>
            <Text>{record?.id || '-'}</Text>
            <Title level={5}>{'الأسم '}</Title>
            <Text>{record?.name || '-'}</Text>
            <Title level={5}>{'البريد الالكتروني '}</Title>
            <Text>{record?.email || '-'}</Text>
          </Break>
          <Break breakPoint={!!screens.md}>
            <Title level={5}>{'الحالة'}</Title>
            <Text>{AccountStatus[+record?.status]}</Text>
            <Title level={5}>{'السعه المتاحة'}</Title>
            <Text>
              {nutritionist?.queryResult?.data?.data?.capacity -
                mergedUsers?.length}
            </Text>
            <Title level={5}>{'السعه'}</Title>
            <Text>{nutritionist?.queryResult?.data?.data?.capacity}</Text>
            <Title level={5}>{'أنشأ في'}</Title>
            <Text>{dayjs(record?.created_at).format('YYYY-MM-DD, HH:mm')}</Text>
          </Break>
        </div>
      </Show>

      <div style={{ marginTop: 15 }} />

      <List
        title={`${'المحادثات'}`}
        pageHeaderProps={{
          extra: (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Search
                path="account"
                setSearchResults={setSearchResults}
                searchResults={searchResults}
                data={mergedUsers || []}
              />
              <RefreshButton
                onClick={() => tableQueryResult?.refetch()}
                style={{ marginRight: 10 }}
              />
            </div>
          ),
        }}
      >
        <Table
          dataSource={searchResults?.length > 0 ? searchResults : mergedUsers}
          rowKey="id"
          style={{ cursor: 'pointer' }}
          onRow={(rec) => {
            return {
              onClick: () => {
                rec?.profile_id && show('account-cruds', rec?.profile_id);
              },
            };
          }}
        >
          <Table.Column
            dataIndex="id"
            title={'معرف الحساب'}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="name"
            title={'الاسم'}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex={'email'}
            title={'البريد الالكتروني'}
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex={'status'}
            title={'الحالة'}
            render={(value) => <TextField value={AccountStatus[+value]} />}
          />
          <Table.Column
            dataIndex={'messages'}
            title={'رسائل غير مقروءة'}
            align="center"
            render={(value) => (
              <TextField
                value={
                  value?.filter((item: { is_read: boolean }) => !item?.is_read)
                    ?.length
                }
              />
            )}
          />
          <Table.Column<any>
            title={'الإجراءات'}
            dataIndex="actions"
            align="center"
            render={(_text, record): any => {
              return (
                <Space>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setVisible(record);
                    }}
                  >
                    فتح المحادثة
                  </Button>
                  {user?.account_type !== 2 && (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setReassignModal(record);
                      }}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Text>{'إعادة تعيين أخصائي'}</Text>
                    </Button>
                  )}
                </Space>
              );
            }}
          />
        </Table>
      </List>
      {visible && (
        <ChatModal
          setVisible={setVisible}
          visible={visible}
          info={visible}
          threadId={visible?.id}
          nutritionistId={record?.id as any}
          nutritionistName={record?.name}
        />
      )}
      {reassignModal && (
        <ReassignNutritionistModal
          visible={reassignModal}
          setVisible={setReassignModal}
          thread_id={reassignModal?.id}
          refresh={tableQueryResult?.refetch}
        />
      )}
    </>
  );
}

export default ShowNutritionist;
const Break = styled.div<{ breakPoint?: boolean }>`
  margin: ${(props) => (props.breakPoint ? '' : '20px')};
`;
