import { IResourceComponentsProps } from '@pankod/refine-core';
import React, { useEffect } from 'react';
import {
  useForm,
  Form,
  Edit,
  Switch,
  ListButton,
  Typography,
  ShowButton,
  Input,
} from '@pankod/refine-antd';
import { EditAccountAction } from '../../../Actions/ConfigsActions';
import { useFormik } from 'formik';
const { Text } = Typography;

interface Inputs {
  basic_monthly_price?: number | string;
  basic_yearly_price?: number | string;
  premium_monthly_price?: number | string;
  premium_yearly_price?: number | string;
  basic_yearly_discount?: number | string;
  premium_yearly_discount?: number | string;
  free_trial_period?: number | string;
  activate_meal_location_filtering?: boolean | string;
  is_deleted?: boolean | string;
}

export const EditSystemConfig: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<any>();
  const record = queryResult?.data?.data;

  const { values, setFieldValue, handleChange, handleSubmit } = useFormik({
    initialValues: {
      basic_monthly_price: record?.basic_monthly_price,
      basic_yearly_price: record?.basic_yearly_price,
      premium_monthly_price: record?.premium_monthly_price,
      premium_yearly_price: record?.premium_yearly_price,
      basic_yearly_discount: record?.basic_yearly_discount,
      premium_yearly_discount: record?.premium_yearly_discount,
      free_trial_period: record?.free_trial_period,
      activate_meal_location_filtering:
        record?.activate_meal_location_filtering,
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {};
      if (submittedValues.basic_monthly_price !== record.basic_monthly_price) {
        inputs.basic_monthly_price = submittedValues.basic_monthly_price;
      }

      if (submittedValues.basic_yearly_price !== record.basic_yearly_price) {
        inputs.basic_yearly_price = submittedValues.basic_yearly_price;
      }

      if (submittedValues.free_trial_period !== record.free_trial_period) {
        inputs.free_trial_period = submittedValues.free_trial_period;
      }

      if (
        submittedValues.premium_monthly_price !== record.premium_monthly_price
      ) {
        inputs.premium_monthly_price = submittedValues.premium_monthly_price;
      }

      if (
        submittedValues.premium_yearly_price !== record.premium_yearly_price
      ) {
        inputs.premium_yearly_price = submittedValues.premium_yearly_price;
      }

      if (
        submittedValues.basic_yearly_discount !== record.basic_yearly_discount
      ) {
        inputs.basic_yearly_discount = submittedValues.basic_yearly_discount;
      }

      if (
        submittedValues.premium_yearly_discount !==
        record.premium_yearly_discount
      ) {
        inputs.premium_yearly_discount =
          submittedValues.premium_yearly_discount;
      }

      if (
        submittedValues.activate_meal_location_filtering !==
        record.activate_meal_location_filtering
      ) {
        inputs.activate_meal_location_filtering =
          submittedValues.activate_meal_location_filtering;
      }

      if (Object.keys(inputs)?.length > 0) {
        EditAccountAction(
          'system-config-cruds/update/by-id',
          record.id,
          inputs,
          queryResult?.refetch
        );
      }
    },
  });
  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  };

  useEffect(() => {
    if (record) {
      setFieldValue('basic_monthly_price', record.basic_monthly_price);
      setFieldValue('basic_yearly_price', record.basic_yearly_price);
      setFieldValue('premium_monthly_price', record.premium_monthly_price);
      setFieldValue('premium_yearly_price', record.premium_yearly_price);
      setFieldValue('basic_yearly_discount', record.basic_yearly_discount);
      setFieldValue('premium_yearly_discount', record.premium_yearly_discount);
      setFieldValue(
        'activate_meal_location_filtering',
        record.activate_meal_location_filtering
      );
      setFieldValue('free_trial_period', record?.free_trial_period);
    }
  }, [record, setFieldValue]);

  if (!record) {
    return null;
  }

  return (
    <Edit
      saveButtonProps={buttonProps}
      title={'تعديل الاعدادات'}
      pageHeaderProps={{
        extra: (
          <>
            <ListButton>
              <Text>{'الاعدادات'}</Text>
            </ListButton>
            <ShowButton>
              <Text>{'التفاصيل'}</Text>
            </ShowButton>
          </>
        ),
      }}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item label={'سعر الاشتراك العادي الشهري'}>
          <Input
            name="basic_monthly_price"
            type="number"
            required
            value={values?.basic_monthly_price}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item label="سعر الاشتراك المميز الشهري">
          <Input
            name="premium_monthly_price"
            type="number"
            required
            value={values?.premium_monthly_price}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item label={'سعر الاشتراك العادي السنوي قبل الخصم'}>
          <Input
            name="basic_yearly_price"
            type="number"
            required
            value={values?.basic_yearly_price}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item label="قيمة خصم الاشتراك العادي السنوي">
          <Input
            name="basic_yearly_discount"
            type="number"
            required
            value={values?.basic_yearly_discount}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item label="سعر الاشتراك المميز السنوي قبل الخصم">
          <Input
            name="premium_yearly_price"
            type="number"
            required
            value={values?.premium_yearly_price}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item label="قيمة خصم الاشتراك المميز السنوي">
          <Input
            name="premium_yearly_discount"
            type="number"
            required
            value={values?.premium_yearly_discount}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item label="فترة التجربة المجانية">
          <Input
            name="free_trial_period"
            type="number"
            required
            value={values?.free_trial_period}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item
          label={'تصفية موقع الوجبة'}
          name={'activate_meal_location_filtering'}
        >
          <Switch
            defaultChecked={values?.activate_meal_location_filtering}
            onChange={(val) =>
              setFieldValue('activate_meal_location_filtering', val)
            }
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
