import { notification } from '@pankod/refine-antd';
import axios from 'axios';
import { API_KEY } from 'Configs';
import dayjs from 'dayjs';
import { apiRequest } from 'Util/api';
import { AccountStore } from './Stores/Account';
import { ConfigsStore } from './Stores/Configs';
import { IAccount } from './types';
import * as Sentry from '@sentry/react';
import { allResources, nutritionistResources } from 'App';

export const axiosInstance = axios.create();
const setHeaders = async () => {
  const token = await AccountStore.get('account');
  if (token?.access_token) {
    axiosInstance.defaults.headers = {
      // @ts-ignore
      api_key: API_KEY,
      Authorization: `Bearer ${token?.access_token}`,
      device_id: await ConfigsStore.get('device_id'),
    };
  } else {
    axiosInstance.defaults.headers = {
      // @ts-ignore
      api_key: API_KEY,
    };
  }
};
setHeaders();

export const LoginAction = async (LoginInput: {
  email: string;
  password: string;
  account_type: number;
}) => {
  try {
    const { data: accountData } = await apiRequest<IAccount>({
      url: 'admin/login',
      method: 'POST',
      data: LoginInput,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
      },
    });
    if (!accountData) throw new Error('Account Not Found!');
    await AccountStore.set('account', accountData);
    await ConfigsStore.set(
      'adminRoles',
      accountData?.admin?.role || accountData?.account_type
    );
    await ConfigsStore.set('logged_in', true);
    await ConfigsStore.set('resetPasswordEmail', '');
    if (await ConfigsStore.get('rememberMe')) {
      const cookies = {
        value: await ConfigsStore.get('logged_in'),
        expiry: dayjs(Date()).add(15, 'd').toDate(),
      };
      await ConfigsStore.set('cookieExpiration', cookies);
    }
    axiosInstance.defaults.headers = {
      // @ts-ignore
      Authorization: `Bearer ${accountData.access_token}`,
      api_key: API_KEY,
      device_id: await ConfigsStore.get('device_id'),
    };
    Sentry.setUser({
      id: accountData?.id.toString(),
      email: accountData?.email,
      name: accountData?.name,
      device: await ConfigsStore.get('device_id'),
    });
    const currentResources = (await ConfigsStore.get('resources')) || [];
    const resources =
      accountData?.account_type !== 2
        ? // eslint-disable-next-line array-callback-return
          allResources?.filter((resource) => {
            if (accountData?.admin?.role === 1) {
              if (resource.name !== 'admin-cruds') {
                return resource;
              }
            } else {
              return resource;
            }
          })
        : nutritionistResources;
    await ConfigsStore.set('resources', resources);
    if (currentResources?.length !== resources?.length) {
      if (accountData?.admin?.role === 1) {
        window.location.href = '/user-cruds';
      } else if (accountData?.account_type === 2) {
        window.location.href = '/nutritionist-cruds';
      } else {
        window.location.href = '/admin-cruds';
      }
    }
  } catch (error) {
    throw new Error('البريد الالكتروني او كلمة السر غير صحيحة');
  }
};

export const LogoutAction = async (withRefresh?: boolean) => {
  await AccountStore.set('account', {});
  await ConfigsStore.set('logged_in', false);
  await ConfigsStore.set('rememberMe', false);
  await ConfigsStore.set('cookieExpiration', '');
  await ConfigsStore.set('adminRoles', '');
  await ConfigsStore.set('resetPasswordEmail', '');
  sessionStorage.clear();
  // set sentry user to null on logout
  Sentry.setUser(null);
  if (withRefresh) {
    window.location.reload();
  }
  return Promise.resolve();
};

export const ForgotPasswordAction = async (
  inputs: { email: string },
  navigateToVerification: (email: string) => void,
  handleDisableButton?: (value: boolean) => void
) => {
  handleDisableButton && handleDisableButton(true);
  try {
    const { data: ForgotPasswordData } = await apiRequest<boolean>({
      url: 'admin/forget-password',
      method: 'POST',
      data: inputs,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.access_token
        }`,
      },
    });
    if (!ForgotPasswordData) {
      notification.error({
        message: 'خطأ',
        description: 'لم يتم العثور على بريد الكتروني المدخل',
      });
    }
    handleDisableButton && handleDisableButton(false);
    navigateToVerification(inputs.email);
    await ConfigsStore.set('resetPasswordEmail', inputs?.email);
  } catch (error) {
    handleDisableButton && handleDisableButton(false);
    notification.error({
      message: 'خطأ',
      description: '  البريد البريد الالكتروني غير صحيح',
    });
  }
};

export const ResetPasswordAction = async (
  password: string,
  navigate?: () => void
) => {
  const inputs = { password };
  try {
    const { data: resetPasswordData } = await apiRequest<boolean>({
      url: 'admin/reset-password',
      method: 'POST',
      data: inputs,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await ConfigsStore.get('resetPasswordToken')
          )?.temporary_access_token
        }`,
      },
    });
    if (!resetPasswordData) {
      notification.error({
        message: 'خطأ',
        description: 'حدث خطأ أثناء تحديث كلمة السر',
      });
    }

    notification.success({
      message: '',
      description: 'تم تحديث كلمة السر بنجاح',
    });
    navigate && navigate();
    await ConfigsStore.set('resetPasswordEmail', '');
    await ConfigsStore.set('resetPasswordToken', '');
  } catch (error: any) {
    notification.error({
      message: 'خطأ',
      description: 'حدث خطأ أثناء تحديث كلمة السر',
    });
  }
};

export const VerificationAction = async (
  code: number,
  email?: string,
  navigate?: () => void
) => {
  const inputs = { code, email };
  try {
    const { data: verificationData } = await apiRequest<boolean>({
      url: 'admin/verify-code',
      method: 'POST',
      data: inputs,
      headers: {
        device_id: await ConfigsStore.get('device_id'),
        authorization: `Bearer ${
          (
            await AccountStore.get('account')
          )?.access_token
        }`,
      },
    });
    if (!verificationData) {
      notification.error({
        message: 'خطأ',
        description: 'رمز التحقق غير صحيح',
      });
    }
    await ConfigsStore.set('resetPasswordToken', verificationData);
    navigate && navigate();
  } catch (error: any) {
    notification.error({
      message: 'خطأ',
      description: 'رمز التحقق غير صحيح',
    });
  }
};
