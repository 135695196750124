import * as React from 'react';
import {
  Create,
  Form,
  Input,
  notification,
  ListButton,
  Typography,
} from '@pankod/refine-antd';
import { errorMessage } from 'Util/ErrorMessage';

import { useFormik } from 'formik';
import { useNavigation } from '@pankod/refine-core';
import { AccountStore } from 'Actions/Stores/Account';
import { ConfigsStore } from 'Actions/Stores/Configs';
import { apiRequest } from 'Util/api';
import * as Yup from 'yup';
const { Text } = Typography;

export const CreateNutritionist = () => {
  const { goBack } = useNavigation();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('مطلوب'),
    email: Yup.string().required('يرجى ادخال بريد الكتروني صحيح'),
    capacity: Yup.number().required('يرجى ادخال كمية الاستيعاب المسموح بها'),
    password: Yup.string().required('يرجى ادخال كلمة سر صحيحة'),
  });
  const { handleChange, handleSubmit } = useFormik({
    initialValues: {
      account_type: 2,
      name: '',
      email: '',
      password: '',
      capacity: +'',
    },
    validationSchema,

    onSubmit: async (submittedValues) => {
      const inputs: {
        account_type?: number;
        name?: string;
        email?: string;
        capacity?: number | string;
        password?: string;
      } = {
        account_type: 2,
        password: submittedValues.password,
        email: submittedValues.email,
        name: submittedValues.name,
        capacity: submittedValues.capacity,
      };

      if (Object.keys(inputs)?.length > 0) {
        try {
          await apiRequest<boolean>({
            url: `/admin/create`,
            method: 'POST',
            data: inputs,
            headers: {
              device_id: await ConfigsStore.get('device_id'),
              authorization: `Bearer ${
                (
                  await AccountStore.get('account')
                )?.access_token
              }`,
            },
          });
          goBack();
          notification.success({
            description: 'تم إنشاء المستخدم بنجاح',
            message: 'نجاح',
          });
        } catch (error: any) {
          notification.error({
            description: 'البريد الالكتروني مستخدم',
            message: 'خطأ',
          });
        }
      }
    },
  });
  const buttonProps = {
    disables: false,
    loading: false,
    onClick: () => handleSubmit(),
  };
  return (
    <Create
      saveButtonProps={buttonProps}
      title={'اضافة اخصائي '}
      pageHeaderProps={{
        extra: (
          <>
            <ListButton>
              <Text>{'اخصائي تغذية'}</Text>
            </ListButton>
          </>
        ),
      }}
    >
      <Form layout="vertical">
        <Form.Item
          label="اسم الاخصائي"
          name="name"
          rules={[{ required: true }]}
        >
          <Input
            name="name"
            required
            onChange={handleChange}
            placeholder={' اسم الاخصائي'}
          />
        </Form.Item>
        <Form.Item
          label="البريد الالكتروني"
          name="email"
          rules={[
            {
              validator: async (_, email) => {
                const regex =
                  // eslint-disable-next-line no-useless-escape
                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                if (!regex.test(email)) {
                  return Promise.reject(
                    new Error('البريد الالكتروني غير صحيح')
                  );
                }
              },
            },
          ]}
        >
          <Input
            name="email"
            onChange={handleChange}
            required
            placeholder={' ادخل البريد الالكتروني'}
          />
        </Form.Item>
        <Form.Item
          label={'كلمة السر'}
          name="password"
          required
          rules={[
            {
              validator: async (_, password) => {
                if (password.length < 8) {
                  return Promise.reject(
                    new Error('يجب أن يكون عدد الحروف أكبر من 8')
                  );
                }
              },
            },
          ]}
        >
          <Input
            type="password"
            placeholder="●●●●●●●●"
            name="password"
            required
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item
          label="السعة"
          name={'capacity'}
          rules={errorMessage()}
          required
        >
          <Input onChange={handleChange} name={'capacity'} type={'number'} />
        </Form.Item>
      </Form>
    </Create>
  );
};
