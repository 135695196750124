import React from 'react';
import {
  BooleanField,
  Grid,
  ListButton,
  Show,
  Space,
  Typography,
} from '@pankod/refine-antd';
import { useNavigation, useShow } from '@pankod/refine-core';
import { Actions } from 'components/AdminPanel/ActionButtons';
import styled from 'styled-components';
import { DeleteRecordAction } from '../../../Actions/ConfigsActions';

const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

function ShowFeedback() {
  const screens = useBreakpoint();
  const { show, edit } = useNavigation();

  const { queryResult } = useShow({});
  const { data, isLoading, refetch } = queryResult;

  const record = data?.data;

  return (
    <>
      <Show
        isLoading={isLoading}
        title={'تفاصيل التغذية الراجعة'}
        pageHeaderProps={{
          extra: (
            <Space>
              <ListButton>
                <Text>{'المراجعات'}</Text>
              </ListButton>
              <Actions
                name_ar="التغذية الراجعة"
                deleteRecord
                record={record}
                onClickDelete={() =>
                  DeleteRecordAction(
                    `feedback-cruds/update/by-id/${record?.id}`,
                    refetch,
                    record?.is_deleted
                  )
                }
                onClickEdit={() =>
                  record?.id && edit('feedback-cruds', record?.id)
                }
              />
            </Space>
          ),
        }}
      >
        <div
          className="detailsWrapper"
          style={{ justifyContent: screens.md ? '' : 'space-between' }}
        >
          <Break breakPoint={!!screens.md}>
            <Title level={5}>{'معرف التغذية الراجعة'}</Title>
            <Text>{record?.id}</Text>
            <Title level={5}>{'المستخدم'}</Title>
            <Text
              style={{ color: '#1890ff', cursor: 'pointer' }}
              className="icons"
              onClick={(e) => {
                e?.stopPropagation();
                show('account-cruds', record?.account_id);
              }}
            >
              {record?.account_id ? `المستخدم #${record?.account_id}` : '-'}
            </Text>
            <Title level={5}>{' التغذية الراجعة'}</Title>
            <Text>{record?.feedback}</Text>
          </Break>
          <Break breakPoint={!!screens.md}>
            <Title level={5}>{'محذوف'}</Title>
            <BooleanField value={record?.is_deleted} />
          </Break>
        </div>
      </Show>
    </>
  );
}

export default ShowFeedback;

const Break = styled.div<{ breakPoint?: boolean }>`
  margin: ${(props) => (props.breakPoint ? '' : '20px')};
`;
