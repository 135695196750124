import * as React from 'react';
import { useForm, Create, Form, Input, Select } from '@pankod/refine-antd';
import { MealType } from 'Actions/types';
import { useFormik } from 'formik';
import { CreateAction } from 'Actions';
import { useNavigation } from '@pankod/refine-core';
import * as Yup from 'yup';

export const CreateMeal = () => {
  const navigate = useNavigation();
  const { formProps, saveButtonProps, queryResult } = useForm();
  const mealTypes = MealType?.map((item, key) => ({ label: item, value: key }));

  const validationSchema = Yup.object().shape({
    name_ar: Yup.string().required(' يرجى ادخال اسم الوجبة'),
    description: Yup.string().required('يرجى ادخال الوصف'),
    meal_type: Yup.number().required('يرجى اختيار نوع الوجبة'),
  });
  const { values, setFieldValue, handleChange, handleSubmit } = useFormik({
    initialValues: {
      name_ar: '',
      description: '',
      meal_type: '',
    },
    validationSchema,

    onSubmit: async (submittedValues) => {
      const inputs = {
        ...submittedValues,
        name_en: submittedValues.name_ar,
      };

      if (Object.keys(inputs)?.length > 0) {
        CreateAction('Meal-cruds', inputs, undefined, navigate.goBack);
      }
    },
  });

  const buttonProps = {
    disables: saveButtonProps.disabled,
    loading: saveButtonProps.loading,
    onClick: () => handleSubmit(),
  };

  return (
    <Create
      isLoading={queryResult?.isFetching}
      saveButtonProps={buttonProps}
      title={'إنشاء وجبة'}
    >
      <Form {...formProps} layout="vertical" initialValues={{ isActive: true }}>
        <Form {...formProps} layout="vertical">
          <Form.Item
            label="اسم الوجبة"
            name="name_ar"
            required
            rules={[{ required: true }]}
          >
            <Input
              name="name_ar"
              onChange={handleChange}
              value={values.name_ar}
            />
          </Form.Item>

          <Form.Item
            label="الوصف"
            name="description"
            required
            rules={[{ required: true }]}
          >
            <Input.TextArea
              name="description"
              onChange={handleChange}
              value={values.description}
            />
          </Form.Item>
          <Form.Item
            label="نوع الوجبة"
            required
            name={'meal_type'}
            rules={[
              {
                validator: async (_, type) => {
                  if (isNaN(type)) {
                    return Promise.reject(new Error(' يرجى ادخال نوع الوجبة'));
                  }
                },
              },
            ]}
          >
            <Select
              options={mealTypes}
              onChange={(value) => setFieldValue('meal_type', value)}
              value={values.meal_type}
            />
          </Form.Item>
        </Form>
      </Form>
    </Create>
  );
};
