import {
  Grid,
  Typography,
  Menu,
  AntdLayout,
  Col,
  Space,
  Dropdown,
  Button,
} from '@pankod/refine-antd';
import { useGetIdentity, useLogout, useNavigation } from '@pankod/refine-core';
import { AccountStore } from 'Actions/Stores/Account';
import ChangePasswordModal from 'components/AdminPanel/ChangePasswordModal';
import React, { useState } from 'react';

const { useBreakpoint } = Grid;

export const CustomHeader: React.FC = () => {
  const { Text } = Typography;
  const { data: user } = useGetIdentity();
  const { mutate: logout } = useLogout();
  const { show } = useNavigation();
  const screens = useBreakpoint();
  const [currentId, setCurrentId] = useState<any>();
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  const accountActions = (
    <Menu selectedKeys={['ar']}>
      <Menu.Item onClick={() => show('admin-cruds', currentId?.id)}>
        {'الملف الشخصي'}
      </Menu.Item>
      <Menu.Item onClick={() => setChangePasswordModal(currentId?.id)}>
        {'تغيير كلمة السر'}
      </Menu.Item>
      <Menu.Item onClick={() => logout()}>{'تسجيل الخروج'}</Menu.Item>
    </Menu>
  );

  const getAccountData = async () =>
    setCurrentId(await AccountStore.get('account'));

  return (
    <>
      <AntdLayout.Header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: screens.xs ? '0px 24px 10px' : '0px 24px',
          height: screens.xs ? 'auto' : '70px',
          backgroundColor: '#FFF',
          flexDirection: screens.xs ? 'column-reverse' : 'row',
          width: screens.xs ? '85%' : 'auto',
          margin: screens.xs ? 'auto' : '',
        }}
      >
        <Col xl={8} style={{ width: screens.xs ? '100%' : 'auto' }}></Col>
        <Col style={{ width: screens.xs ? '100%' : 'auto' }}>
          {/* <Clock
            format={'dd, DD-MM,  HH:mm'}
            ticking={true}
            style={{ display: screens.md ? '' : 'none' }}
          /> */}
          <Space
            size="middle"
            style={{
              width: screens.xs ? '100%' : 'auto',
              justifyContent: 'space-between',
            }}
          >
            <Dropdown overlay={accountActions} onVisibleChange={getAccountData}>
              <Button type="link">
                <Space>
                  <Text
                    ellipsis
                    strong
                    style={{ display: screens.xs ? 'none' : '' }}
                  >
                    {user?.name}
                  </Text>
                  {/* <Avatar
                    size="large"
                    src={
                      user?.photo?.filename
                        ? `${API_URL}/upload/${user?.photo?.filename}`
                        : '../../Assets/images/noPhoto.png'
                    }
                    alt={user?.name}
                  /> */}
                </Space>
              </Button>
            </Dropdown>
          </Space>
        </Col>
      </AntdLayout.Header>
      <ChangePasswordModal
        visible={changePasswordModal}
        setVisible={setChangePasswordModal}
      />
    </>
  );
};
