import localforage from 'localforage';
import { IAccount } from '../types';

interface IAccountStore {
  readonly store: LocalForage;
  account?: IAccount;
}

class Account implements IAccountStore {
  store: LocalForage;

  constructor() {
    this.store = localforage.createInstance({
      name: 'Account',
      driver: localforage.LOCALSTORAGE,
    });
  }

  async getStore() {
    return (
      (await this.store.getItem<IAccountStore>('store')) ||
      ({} as IAccountStore)
    );
  }

  async get<T extends keyof IAccountStore>(key: T) {
    const storeData = await this.getStore();

    return storeData[key];
  }

  async set<T>(key: keyof IAccountStore, payload: T) {
    const storeData = await this.getStore();
    // @ts-ignore
    storeData[key] = payload;

    return this.store.setItem('store', storeData);
  }
}

export const AccountStore = new Account();
