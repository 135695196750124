import { Input, Select, Tooltip } from '@pankod/refine-antd';
import { SearchAction } from 'Actions/ConfigsActions';
import React, { useState } from 'react';

import { RiFilterOffLine } from 'react-icons/ri';
const { Option } = Select;
interface Props {
  path: string;
  data: any[];
  typeItems?: any[];
  searchResults: any[];
  setSearchResults: (val: any) => void;
}
export const Search = ({
  path,
  setSearchResults,
  searchResults,
  typeItems,
  data,
}: Props) => {
  const [searchType, setSearchType] = useState<string>(
    path.includes('profile')
      ? 'account_id'
      : path?.includes('promo-code')
      ? 'code'
      : 'name'
  );
  const [searchValue, setSearchValue] = useState<string>('');

  const handleSearch = (value: string) => {
    SearchAction(searchType, value, data, setSearchResults);
  };
  return (
    <>
      <Input.Group compact>
        {!path.includes('profile') && (
          <Select
            placeholder={'ابحث حسب..'}
            style={{ width: 130 }}
            onChange={(val: string) => setSearchType(val)}
          >
            {path.includes('account') && (
              <>
                <Option value="id">{'معرف الحساب'}</Option>
                <Option value="name">الاسم</Option>
                <Option value="email">البريد الإلكتروني</Option>
              </>
            )}
            {path.includes('meal') && (
              <>
                <Option value="name_ar">الاسم</Option>
                <Option value={`meal_type`}> نوعية الوجبة</Option>
              </>
            )}

            {path === 'ingredient' && (
              <>
                <Option value="name_ar">الاسم</Option>
                <Option value="type"> نوعية المكون</Option>
                <Option value="protein">بروتين</Option>
                <Option value="carbohydrates">الكربوهيدرات</Option>
                <Option value="calories">سعرات حراريه</Option>
                <Option value="fat">دهون</Option>
              </>
            )}

            {path === 'ingredient-category' && (
              <>
                <Option value="id">{'معرف التصنيف'}</Option>
                <Option value="name_ar">اسم التصنيف </Option>
              </>
            )}

            {path.includes('promo-code') && (
              <>
                <Option value="code">الرمز</Option>
              </>
            )}

            {path.includes('allergy') && (
              <>
                <Option value="name_ar">الاسم</Option>
                <Option value="type"> نوعية الحساسية</Option>
              </>
            )}

            {path.includes('feedback') && (
              <>
                <Option value="feedback">الاسم</Option>
              </>
            )}
          </Select>
        )}
        {searchType?.includes('type') ? (
          <Select
            placeholder={'ابحث حسب..'}
            style={{ width: 130 }}
            onChange={handleSearch}
            onSearch={handleSearch}
          >
            {typeItems?.map((mealType, key) => (
              <Option value={`${mealType?.value}-${searchType}`} key={key}>
                {mealType?.label}
              </Option>
            ))}
          </Select>
        ) : (
          <Input.Search
            placeholder={
              path.includes('profile') ? 'البحث حسب معرف الحساب' : 'ابحث'
            }
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={(value) =>
              value ? handleSearch(value) : setSearchResults([])
            }
            allowClear
            style={{ width: 250 }}
          />
        )}
      </Input.Group>
      {searchResults?.length > 0 && (
        <Tooltip
          placement="top"
          title={<p style={{ marginBottom: -1 }}>{'Cancel Search'}</p>}
          style={{ maxWidth: 20 }}
        >
          <RiFilterOffLine
            color="#f40c0c"
            size={30}
            style={{ cursor: 'pointer', marginRight: 10, marginLeft: 10 }}
            onClick={() => {
              setSearchResults([]);
              setSearchValue('');
            }}
            className="icons"
          />
        </Tooltip>
      )}
    </>
  );
};
