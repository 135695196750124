import React from 'react';
import {
  BooleanField,
  CreateButton,
  Grid,
  ListButton,
  Show,
  Space,
  Typography,
} from '@pankod/refine-antd';
import { useGetIdentity, useNavigation, useShow } from '@pankod/refine-core';
import { Actions } from 'components/AdminPanel/ActionButtons';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { DeleteRecordAction } from '../../../Actions/ConfigsActions';
import { IngredientsType } from 'Actions/types';
import IngredientToCategory from 'components/AdminPanel/AddToCategory/ingredientToCategory';
const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

function ShowIngredients() {
  const [addIngredientModal, setAddIngredientModal] = React.useState<any>();
  const { data: user } = useGetIdentity();
  const screens = useBreakpoint();
  const { edit } = useNavigation();

  const { queryResult } = useShow({});
  const { data, isLoading, refetch } = queryResult;

  const record = data?.data;

  return (
    <>
      <Show
        isLoading={isLoading}
        title={'تفاصيل المكون'}
        pageHeaderProps={{
          extra: (
            <Space>
              <ListButton>
                <Text>{'المكونات'}</Text>
              </ListButton>
              <CreateButton onClick={() => setAddIngredientModal(record)}>
                <Text>{'إضافة الى تصنيف'}</Text>
              </CreateButton>
              {user?.account_type !== 2 && (
                <Actions
                  name_ar="المكون"
                  deleteRecord
                  edit
                  record={record}
                  onClickDelete={() =>
                    DeleteRecordAction(
                      `ingredients-cruds/update/by-id/${record?.id}`,
                      refetch,
                      record?.is_deleted
                    )
                  }
                  onClickEdit={() =>
                    record?.id && edit('ingredients-cruds', record?.id)
                  }
                />
              )}
            </Space>
          ),
        }}
      >
        <div
          className="detailsWrapper"
          style={{ justifyContent: screens.md ? '' : 'space-between' }}
        >
          <Break breakPoint={!!screens.md}>
            <Title level={5}>{' معرف المكون'}</Title>
            <Text>{record?.id}</Text>
            <Title level={5}>{'اسم المكون'}</Title>
            <Text>{record?.name_ar}</Text>
            <Title level={5}>{' نوع المكون'}</Title>
            <Text>{IngredientsType[+record?.type]}</Text>
          </Break>
          <Break breakPoint={!!screens.md}>
            <Title level={5}>{' الكربوهيدرات'}</Title>
            <Text>{record?.carbohydrates}</Text>
            <Title level={5}>{'دهون'}</Title>
            <Text>{record?.fat}</Text>
            <Title level={5}>{'سعرات حراريه'}</Title>
            <Text>{record?.calories}</Text>
            <Title level={5}>{'البروتين'}</Title>
            <Text>{record?.protein}</Text>
          </Break>
          <Break breakPoint={!!screens.md}>
            <Title level={5}>{'أنشأ في'}</Title>
            <Text>{dayjs(record?.created_at).format('YYYY-MM-DD, HH:mm')}</Text>
            <Title level={5}>{'محذوف'}</Title>
            <BooleanField value={record?.is_deleted} />
          </Break>
        </div>
      </Show>
      {addIngredientModal && (
        <IngredientToCategory
          info={addIngredientModal}
          visible={addIngredientModal}
          setVisible={setAddIngredientModal}
          refresh={refetch}
        />
      )}
    </>
  );
}

export default ShowIngredients;

const Break = styled.div<{ breakPoint?: boolean }>`
  margin: ${(props) => (props.breakPoint ? '' : '20px')};
`;
