import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  notification,
  Modal,
  Form,
  Select,
  useTable,
} from '@pankod/refine-antd';
import { AccountStore } from 'Actions/Stores/Account';
import { ConfigsStore } from 'Actions/Stores/Configs';
import { apiRequest } from 'Util/api';
import { ProfilePossibleMealsAction } from 'Actions';

interface Props {
  visible: any;
  setVisible: (val: any) => void;
  redirect?: string;
  info?: any;
  refresh?: (val: boolean) => void;
  modalType?: string;
}

interface Inputs {
  profile_meal_id: string;
  meal_id: string;
  profile_id: string;
  assigned_day: Date;
}

const ReassignMeal = ({ visible, setVisible }: Props) => {
  const [possibleMeals, setPossibleMeals] = useState<
    { label: string; value: string }[]
  >([]);

  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      meal_id: '',
      profile_meal_id: '',
      profile_id: '',
      assigned_day: new Date(),
      meal_type: +'',
    },
    onSubmit: async (submittedValues) => {
      const inputs: Inputs = {
        profile_meal_id: submittedValues.profile_meal_id,
        meal_id: submittedValues.meal_id,
        profile_id: visible?.account_id,
        assigned_day: submittedValues.assigned_day || new Date(),
      };

      try {
        await apiRequest<boolean>({
          url: '/profile-cruds/reassign-profile-meal',
          method: 'POST',
          data: inputs,
          headers: {
            device_id: await ConfigsStore.get('device_id'),
            authorization: `Bearer ${
              (
                await AccountStore.get('account')
              )?.access_token
            }`,
          },
        });

        setVisible('');
        notification.success({
          message: 'نجاح',
          description: 'تمت الاضافة بنجاح',
        });
      } catch (error: any) {
        setVisible('');
        notification.error({
          message: 'خطأ',
          description: error?.message,
        });
      }
    },
  });

  const meals = useTable({
    resource: 'Meal-cruds',
    initialPageSize: 100000,
  });

  const mealcruds = useTable({
    resource: 'profile_meal',
    initialPageSize: 100000,
  });

  const filteredmealcruds = mealcruds?.tableQueryResult?.data?.data
    ?.filter(
      (item) =>
        item?.meal_id === values.meal_id &&
        item?.profile_id === visible?.account_id
    )
    // eslint-disable-next-line array-callback-return
    .map((meal) => {
      if (
        meals?.tableProps?.dataSource?.map((x) => x.id)?.includes(meal?.meal_id)
      ) {
        return {
          label: meals?.tableProps?.dataSource?.find(
            (i) => i?.id === meal?.meal_id
          )?.name_ar,
          value: `${meal?.id}#${meal?.assigned_day}`,
        };
      }
    });

  const handleSetPossibleMeals = (val: never[]) => {
    setPossibleMeals(val);
  };

  const handleGetPossibleMeals = (meal_type: number) => {
    ProfilePossibleMealsAction(
      visible?.account_id,
      handleSetPossibleMeals,
      meal_type
    );
  };

  useEffect(() => {
    if (visible?.account_id) {
      setFieldValue('meal_id', visible?.account_id);
    }
  }, [setFieldValue, visible]);

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible('')}
      width={500}
      okText={'إرسال'}
      cancelText={'إلغاء'}
      onOk={() => handleSubmit()}
    >
      <Form layout="vertical" style={{ marginTop: 25 }}>
        <Form.Item label="نوعية الوجبة" rules={[{ required: true }]} required>
          <Select
            placeholder="اختر النوعية"
            options={[
              { label: 'الفطور', value: 0 },
              { label: 'وجبة خفيفة', value: 1 },
              { label: 'الغداء', value: 2 },
              { label: 'العشاء', value: 3 },
            ]}
            onChange={handleGetPossibleMeals}
          />
        </Form.Item>
        <Form.Item
          label="الوجبات المقترحة"
          name={'meal_id'}
          rules={[{ required: true }]}
        >
          <Select
            placeholder="اختر الوجبة المقترحة"
            options={possibleMeals}
            onChange={(val) => setFieldValue('meal_id', val)}
          />
        </Form.Item>
        <Form.Item
          label="الوجبة"
          name={'profile_meal_id'}
          rules={[{ required: true }]}
        >
          <Select
            placeholder="اختر الوجبة"
            // @ts-ignore
            options={filteredmealcruds || []}
            onChange={(val) => {
              const [id, date] = val.split('#');
              setFieldValue('profile_meal_id', id);
              setFieldValue('assigned_day', date);
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReassignMeal;
