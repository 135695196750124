import React from 'react';
import { useFormik } from 'formik';
import { Modal, Form, Input, Select, DatePicker } from '@pankod/refine-antd';
import dayjs from 'dayjs';
import { ReportsAction } from 'Actions';
import * as Yup from 'yup';
const { RangePicker } = DatePicker;

interface Props {
  visible: any;
  setVisible: (val: any) => void;
  redirect?: string;
  info?: any;
  refresh?: () => void;
}

const ReportsModal = ({ visible, setVisible, info, refresh }: Props) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('يرجى ادخال بريد الكتروني صحيح'),
    report_type: Yup.string().required('مطلوب'),
    time_from: Yup.string().required('مطلوب'),
    time_to: Yup.string().required('مطلوب'),
  });
  const { errors, values, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        profile_id: info?.id,
        email: '',
        time_from: '',
        time_to: '',
        report_type: 'profile_meals',
      },
      validationSchema,
      onSubmit: async (submittedValues) => {
        ReportsAction(submittedValues);
        setVisible('');
      },
    });

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible('')}
      width={500}
      okText={'إرسال'}
      cancelText={'إلغاء'}
      onOk={() => handleSubmit()}
    >
      <Form layout="vertical" style={{ marginTop: 25 }}>
        <Form.Item label="نوع التقرير" rules={[{ required: true }]}>
          <Select
            options={[
              { label: 'وجبات المستخدم', value: 'profile_meals' },
              { label: 'سجل المستخدم', value: 'profile_history' },
            ]}
            placeholder="اختر نوع التقرير"
            value={values.report_type || 'profile_meals'}
            onChange={(val: string) => setFieldValue('report_type', val)}
          />
        </Form.Item>
        <Form.Item label="البريد الإلكتروني" rules={[{ required: true }]}>
          <Input
            name="email"
            onChange={handleChange}
            placeholder={'مثال: example@example.com, example@example.com'}
            value={values.email}
            style={{
              borderColor: errors.email ? 'red' : undefined,
            }}
          />
        </Form.Item>
        <Form.Item label="من تاريخ" rules={[{ required: true }]}>
          {/* @ts-ignore */}
          <RangePicker
            onChange={(val: any) => {
              setFieldValue('time_from', val ? dayjs(val[0]).format() : '');
              setFieldValue('time_to', val ? dayjs(val[1]).format() : '');
            }}
            placeholder={['من تاريخ', 'إلى تاريخ']}
            style={{
              width: '100%',
              direction: 'rtl',
              borderColor:
                errors.time_from && errors.time_to ? 'red' : undefined,
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReportsModal;
